import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsAdmin, selectUser } from "../../auth/authSlice";
import { useRefreshIndexMutation, useGetIndexMetaDataQuery } from "../../api/apiSlice";
import { useGetHangfireJobStatusQuery } from "../../api/hangfireApiSlice";
import Spinner from "../../../UI/Spinner";
import SummaryCard from "./SummaryCard";

const VideoClientAppSummaryPage = () => {
    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);
    const [ jobId, setJobId ] = useState(null);

    const [refreshIndex, {  data, isSuccess, isError }] = useRefreshIndexMutation();
    const { data: indexMetaData, isFetching, refetch: refetchIndexMetadata } = useGetIndexMetaDataQuery();

    const { data: jobStatus } = useGetHangfireJobStatusQuery(jobId, { skip: !jobId, pollingInterval: 3000});

    useEffect(() => {
        toastr.options.positionClass = "toast-bottom-right";

        if (isSuccess) { toastr.success("Refresh Index Triggered Successfully!", "Refresh Index"); }
        else if (isError) { toastr.error("Failed to trigger Refersh Index!", "Refresh Index"); }

    }, [isSuccess, isError]);

    useEffect(() => {
        if (jobStatus && jobStatus.state === 'Succeeded') {
            refetchIndexMetadata();
        }
    }, [jobStatus]);

    if (!user) return <Spinner />;
    else if (!isAdmin) return <NotAuthorizedPage />;

    return (<>
        <h1>Video Client App Summary</h1>
        {jobStatus && jobStatus.state === 'Processing' && <div className="alert alert-info" role="alert">Job Status: {jobStatus.state}</div>}
        {jobStatus && jobStatus.state === 'Failed' && <div className="alert alert-danger" role="alert">Job Status: {jobStatus.state}</div>}
        {jobStatus && jobStatus.state === 'Succeeded' && <div className="alert alert-success" role="alert">Job Status: {jobStatus.state}</div>}
        <div className="d-flex row mt-5">
            <button className="btn btn-primary mx-3" onClick={() => refetchIndexMetadata()}>Refresh Page</button>
            <button className="btn btn-primary" onClick={() => {
                refreshIndex().then((res) => {
                    setJobId(res.data);
                });
                }}>Refresh Index</button>
        </div>
        <div className="d-flex row mt-4 ml-0">
            <SummaryCard title="Available Videos" count={indexMetaData?.matchIndexCount} isLoading={!indexMetaData || isFetching} />
            <SummaryCard title="Points Count" count={indexMetaData?.matchPointServeIndexCount} isLoading={!indexMetaData || isFetching} />
        </div>
        <div className="d-flex row mt-4 ml-0">
            {indexMetaData?.matchIndexTypes.map(type => <SummaryCard title={type.matchDataType} count={type.count} isLoading={!indexMetaData || isFetching} />)}
        </div>
    </>
    );
}

export default VideoClientAppSummaryPage;