import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  videos: [],
  selectedVideo: null,
  filteredVideos: [],
};

const videosLinkingCodersSlice = createSlice({
  name: "videosLinkingCoders",
  initialState,
  reducers: {
    setVideos(state, action) {
      state.videos = action.payload;
      state.filteredVideos = action.payload;
    },
    setSelectedVideo(state, action) {
      state.selectedVideo = action.payload;
    },
    reset(state) {
      state.videos = [];
      state.selectedVideo = null;
      state.filteredVideos = [];
    },
    filterVideosByTitle(state, action) {
      const keywords = action.payload.split(" ");
      const result = state.videos.filter((video) => {
        const title = video.title.toLowerCase();
        return keywords.every((keyword) => title.includes(keyword.toLowerCase()));
      });

      state.filteredVideos = result;
      state.videos.forEach((video) => (video.isFiltered = false));
      state.videos.forEach((video) => {
        if (result.some((filteredVideo) => filteredVideo.id === video.id)) {
          video.isFiltered = true;
        }
      });
    },
    updateVideoMetadata(state, action) {
      let video = { ...state.selectedVideo };
      Object.assign(video.linked_metadata, action.payload);
      state.selectedVideo = video;
    },
    removeVideo(state, action) {
      const { id } = action.payload;
      let video = state.videos.find((video) => video.id === id);
      if (video) {
        state.videos = state.videos.filter((video) => video.id !== id);
        state.filteredVideos = state.filteredVideos.filter((video) => video.id !== id);
      }
    }
  },
});

export const {
  setVideos,
  setSelectedVideo,
  reset,
  filterVideosByTitle,
  updateVideoMetadata,
  removeVideo
} = videosLinkingCodersSlice.actions;

export const selectVideos = (state) => state.videosLinkingCoders.videos;
export const selectSelectedVideo = (state) => state.videosLinkingCoders.selectedVideo;
export const selectFilteredVideos = (state) => state.videosLinkingCoders.filteredVideos;
export default videosLinkingCodersSlice.reducer;