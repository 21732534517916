import * as constants from './constants.js';
import { emptyShot } from './shotUtils.js';

function addFirstServe(newPoint) {
    if (newPoint.shots.findIndex((s) => s.shotOrderId === constants.FIRST_SERVE_SHOT_ORDER_ID) === -1) {
        let shots = [...newPoint.shots];
        shots.push({
            ...emptyShot,
            shotOrderId: constants.FIRST_SERVE_SHOT_ORDER_ID,
            shotTypeId: constants.SERVE_SHOT_TYPE,
            strokeTypeId: constants.NORMAL_STROKE_TYPE,
            verticalPlayerPositionId: constants.BEHIND_BASE_VERTICAL_PLAYER_POSITION,
            shotMovementTypeId: constants.STATIONARY_SHOT_MOVEMENT_TYPE,
            number: 1,
        });
        shots.sort((a, b) => a.shotOrderId - b.shotOrderId);
        newPoint.shots = shots;
    }
}

function addSecondServe(newPoint) {
    if (newPoint.shots.findIndex((s) => s.shotOrderId === constants.SECOND_SERVE_SHOT_ORDER_ID) === -1) {
        let shots = [...newPoint.shots];
        shots.push({
            ...emptyShot,
            shotOrderId: constants.SECOND_SERVE_SHOT_ORDER_ID,
            shotTypeId: constants.SERVE_SHOT_TYPE,
            strokeTypeId: constants.NORMAL_STROKE_TYPE,
            verticalPlayerPositionId: constants.BEHIND_BASE_VERTICAL_PLAYER_POSITION,
            shotMovementTypeId: constants.STATIONARY_SHOT_MOVEMENT_TYPE,
            number: 1,
        });
        shots.sort((a, b) => a.shotOrderId - b.shotOrderId);
        newPoint.shots = shots;
    }
}

export {
    addFirstServe,
    addSecondServe
}