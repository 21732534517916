

const HETrackingSheet = ({ title, matches }) => {
    return (
        <div className="container-fluid mb-4">
            <br />
            <div className=" alert alert-secondary text-center font-weight-bold lead" role="alert">{`${matches.length} ${title} Matches`}</div>
            <br />
            {matches.length > 0 && <div className="row">
                <div className="col">
                    <div style={{ maxHeight: "250px" }} className="table-responsive scrollbar perfect-scrollbar">
                        <table className="table table-striped table-bordered table-hover table-sm text-center">
                            <thead>
                                <tr>
                                    <th scope="col">Index</th>
                                    <th scope="col">P1</th>
                                    <th scope="col">P2</th>
                                    <th scope="col">Tournament</th>
                                    <th scope="col">Year</th>
                                    <th scope="col">Round</th>
                                    <th scope="col">Surface</th>
                                </tr>
                            </thead>
                            <tbody>
                                {matches.map((match, index) =>
                                    <tr key={`match-${index + 1}`}>
                                        <td>{index + 1}</td>
                                        <td>{match.p1}</td>
                                        <td>{match.p2}</td>
                                        <td>{match.tournament}</td>
                                        <td>{match.year}</td>
                                        <td>{match.round}</td>
                                        <td>{match.surface}</td>
                                    </tr>)}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default HETrackingSheet;