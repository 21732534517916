import React from "react";
import { AgGridReact } from "ag-grid-react";
import {
  useDownloadVideoMutation,
  useSendVideoMutation,
  useResetCvVideoStatusMutation,
  useRerunCvVideoMutation
} from "../api/collectedVideosSlice";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import {
  ButtonRenderer,
  CopyButtonRenderer,
  defaultColDef,
  scraperDefaultTableColumns,
  downloadTableColumns,
  collectedVideosDefaultTableColumns,
  sendTableColumns,
  resetTableColumns,
  completedTableColumns
} from "./tablesDefinitions";
import { formatUtcDate, formatDetailedUtcDate } from "../../utils/app-utils";
import { useState } from "react";
import Spinner from "../../UI/Spinner";

const CollectedVideosTable = ({ matches, status, category }) => {
  const [downloadVideo, downloadResponse] = useDownloadVideoMutation();
  const [sendVideo, sendResponse] = useSendVideoMutation();
  const [resetCvVideoStatus, resetCvVideoStatusResponse] = useResetCvVideoStatusMutation();
  const [rerunCvVideo, rerunCvVideoResponse] = useRerunCvVideoMutation();
  const [isLoading, setIsLoading] = useState(false);

  let columnDefs = [];
  if (matches && matches.length > 0)
    switch (status) {
      case "download":
        columnDefs = downloadTableColumns;
        break;
      case "downloadprocessing":
        columnDefs = scraperDefaultTableColumns;
        break;
      case "new":
        columnDefs = sendTableColumns;
        break;
      case "cvprocessing":
        columnDefs = resetTableColumns;
        break;
      case "completed":
        columnDefs = completedTableColumns;
        break;
      default:
        columnDefs = collectedVideosDefaultTableColumns;
        break;
    }

  const rowData = React.useMemo(() => {
    if (!matches || matches.length === 0) {
      return [];
    }
    setIsLoading(true);
    const result = matches.map((match) => {
      let matchData = { ...match };
      if(matchData.status === "Delayed")
      {
        matchData.status = `Delayed (${formatDetailedUtcDate(match.retryAt)})`;
      }
      matchData.date = formatUtcDate(matchData.date);
      switch (status) {
        case "download":
          matchData = {
            p1: matchData.p1,
            p2: matchData.p2,
            tournament: matchData.tournament,
            round: matchData.round,
            date: matchData.date,
            status: match.status,
            action: (
              <ButtonRenderer
                title="Download"
                clickHandler={({ match, category }) =>
                  downloadVideo({ match, category })
                }
                args={{ match, category }}
              />
            ),
          };
          break;
        case "new":
          matchData = {
            ...matchData,
            url: (
              <CopyButtonRenderer title="Copy" args={match.url} />
            ),
            downloadLink: (
              <CopyButtonRenderer title="Copy" args={match.downloadURL} />
            ),
            browserURL: (
              <CopyButtonRenderer title="Copy" args={match.browserURL} />
            ),
            action1: (
              <ButtonRenderer
                title="Send To Prod"
                clickHandler={(id) =>
                  sendVideo({ videoId: id, env: "prod" })
                }
                args={match.id}
              />
            ),
            action2: (
              <ButtonRenderer
                title="Send To Dev"
                clickHandler={(id) =>
                  sendVideo({ videoId: id, env: "dev" })
                }
                args={match.id}
              />
            ),
          };
          break;
        case "cvprocessing":
          matchData = {
            ...matchData,
            url: (
              <CopyButtonRenderer title="Copy" args={match.url} />
            ),
            downloadLink: (
              <CopyButtonRenderer title="Copy" args={match.downloadURL} />
            ),
            browserURL: (
              <CopyButtonRenderer title="Copy" args={match.browserURL} />
            ),
            action: (
              <ButtonRenderer
                title="Reset"
                clickHandler={(id) =>
                  resetCvVideoStatus({ videoId: id })
                }
                args={match.id}
              />
            ),
          };
          break;
        case "completed":
          matchData = {
            ...matchData,
            finishedAt: formatDetailedUtcDate(matchData.finishedAt),
            url: (
              <CopyButtonRenderer title="Copy" args={match.url} />
            ),
            downloadLink: (
              <CopyButtonRenderer title="Copy" args={match.downloadURL} />
            ),
            browserURL: (
              <CopyButtonRenderer title="Copy" args={match.browserURL} />
            ),
            action: (
              <ButtonRenderer
                title="Rerun"
                clickHandler={(id) =>
                  rerunCvVideo({ videoId: id })
                }
                args={match.id}
              />
            )
          };
          break;
      }
      return matchData;
    });
    setIsLoading(false);
    return result;
  }, [matches, status]);

  return (
    <div>
      {isLoading && <Spinner />}
      {!isLoading && <div
        className="ag-theme-quartz-dark"
        style={{ height: 500, width: "100%", marginTop: 10 }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={20}
          suppressColumnVirtualisation={true}
          suppressRowVirtualisation={true}
        />
      </div>
      }
    </div>
  );
};

export default CollectedVideosTable;
