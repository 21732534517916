
import { useState, useEffect } from "react";
const PlayersList = ({ players, selectedPlayerLookupId: selectedPlayerLookupId, setSelectedPlayerLookupId: setSelectedPlayerLookupId }) => {

    const [filteredPlayers, setFilteredPlayers] = useState(players);
    const [search, setSearch] = useState("");

    const filterPlayers = (e) => {
        const search = e.target.value;
        if (search === "") setFilteredPlayers(players);
        else setFilteredPlayers(players.filter(player => (`${player.source} - ${player.sourceName}`).toLowerCase().includes(search.toLowerCase())));
        setSearch(search);
    }

    useEffect(() => {
        if (search === "") setFilteredPlayers(players);
        else setFilteredPlayers(players.filter(player => (`${player.source} - ${player.sourceName}`).toLowerCase().includes(search.toLowerCase())));
    }, [players]);

    return (
        <div className="col-4 mb-3">
            <div className="card mt-1 min-vh-75 max-vh-75">
                <div className="card-header d-flex flex-column align-items-center">
                    <h3>Players ({filteredPlayers.length})</h3>
                    <div className="col-12 mt-1">
                        <input type="text" className="form-control" placeholder="Search by name" onChange={filterPlayers} />
                    </div>
                </div>
                <div className="card-body p-0 scrollbar perfect-scrollbar">
                    <div className="list-group">
                        {filteredPlayers.map(player =>
                            <div key={player.playerLookupId}
                                style={{ cursor: "pointer" }}
                                className={`d-flex row flex-nowrap list-group-item list-group-item-action align-items-center ml-0 ${player.playerLookupId === selectedPlayerLookupId ? "bg-500 text-white" : ""}`}
                                onClick={() => setSelectedPlayerLookupId(player.playerLookupId)} >
                                <span className="text-nowrap">{player.sourceName}</span>
                                <div className="badge badge-warning rounded-capsule ml-2">
                                    {player.source}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PlayersList;