import React, { useEffect, useState } from "react";
import Select from "react-select";
import { formatDetailedUtcDate } from "../../utils/app-utils";
import {
  useAddPlayersMutation,
} from "../api/playerGroupsSlice";
import { useGetAllPlayersQuery } from "../api/completeMatchesSlice"
import DeletePlayerGroup from "./DeletePlayerGroup";

import Spinner from "../../UI/Spinner";
import classes from "./PlayerGroupsPage.module.css";

const PlayerGroup = ({ data, setSelectedPlayerGroup }) => {

  const [playerGroupData, setPlayerGroupData] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [deletedPlayers, setDeletedPlayers] = useState([]);
  const [isChanged, setIsChanged] = useState(false);

  const [addPlayers] = useAddPlayersMutation();
  const { data: players, isFetching, isLoading } = useGetAllPlayersQuery();

  useEffect(() => {
    if (data) setPlayerGroupData(data);
  }, [data]);

  const handleChange = (selectedOptions) => {
    const selectedPlayerObjects = selectedOptions.map((option) =>
      players.find((player) => player.playerId === option.value)
    );
    setSelectedPlayers(selectedPlayerObjects);

    // Check if there are changes compared to the initial load
    const areThereChanges =
      selectedOptions.some(
        (option) => !playerGroupData.players.some((p) => p.playerId === option.value)
      ) || playerGroupData.players.length !== selectedOptions.length;

    setIsChanged(areThereChanges);
  };

  const playerOptions = players
    ?.filter((p) => p.category == data?.category)
    .filter((player) => {
      return !playerGroupData?.players?.some((P) => P?.playerId === player.playerId);
    })
    .map((player) => ({
      label: player.name,
      value: player.playerId,
    }));

  const selectedValue = selectedPlayers?.map((player) => ({
    label: player.name,
    value: player.playerId,
  }));

  const handleRemovePlayer = (playerId) => {
    const playerToRemove = playerGroupData?.players.find(
      (p) => p.playerId === playerId
    );

    const newSelectedPlayers = playerGroupData?.players.filter(
      (p) => p.playerId !== playerId
    );

    if (
      playerToRemove ||
      !deletedPlayers.some((p) => p.playerId === playerId)
    ) {
      setDeletedPlayers([...deletedPlayers, playerToRemove]);
    }
    setIsChanged(true);

    setPlayerGroupData((prevData) => ({
      ...prevData,
      players: newSelectedPlayers.map((player) => ({
        playerName: player.playerName,
        playerId: player.playerId,
      })),
    }));
  };

  const onClickSave = async () => {
    try {
      const playersIds = playerGroupData?.players?.map((player) => ({
        name: player?.playerName,
        playerId: player?.playerId,
      }));

      const players = selectedPlayers.concat(playersIds);

      const payload = players.map((p) => p.playerId);

      await addPlayers({ id: data?.playerGroupId, payload }).unwrap();
      toastr.success("Players Updated successfully!", "");
      setDeletedPlayers([]);
      setSelectedPlayers([]);
      setIsChanged(false);
    } catch (error) {
      toastr.error("Failed to update!", "Create New Record");
    }
  };

  useEffect(() => {
    setDeletedPlayers([]);
  }, [data?.playerGroupId]);

  return (
    <div className={classes.playerGroups}>
      <div className={classes.header}>
        <h4>{data?.title}</h4>

        {/* <button type="button" className="btn btn-danger">Delete</button> */}
      </div>
      {data?.description ? <p>Description: {data?.description}</p> : null}
      <p>
        Category:{" "}
        {data?.category === "2" ? (
          "WTA"
        ) : data?.category === "1" ? (
          "ATP"
        ) : (
          <div>{data?.category}</div>
        )}
      </p>
      <p>Created At: {formatDetailedUtcDate(data?.createdAt)}</p>
      <p>Created By: {data?.createdBy}</p>
      <p style={{ display: "flex", alignItems: "start", gap: "10px" }}>
        Players:{" "}
        {playerGroupData?.players?.length ? (
          <div className={classes?.players}>
            <div className="container">
              <div className="row">
                {playerGroupData?.players?.map((player, index) => (
                  <div className="mr-1 mt-1" key={index}>
                    <div
                      className="badge badge-secondary"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                    >
                      <div className={`${classes?.name} mr-1  `}>{player?.playerName}</div>
                      <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={(event) => {
                          event.preventDefault();
                          handleRemovePlayer(player.playerId);
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div> No players in Group</div>
        )}
      </p>
      {isFetching || isLoading ? <Spinner /> : <div>
        <h5 className="mb-2">Select Players</h5>
        <Select
          isMulti
          closeMenuOnSelect={false}
          options={playerOptions}
          value={selectedValue}
          onChange={handleChange}
          components={{ MultiValueRemove: () => null }}
        />
        {deletedPlayers?.length > 0 && (
          <h6 className="mt-4"> Deleted Players :</h6>
        )}
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {deletedPlayers?.map((player, index) => (
            <p
              key={index}
              href="#"
              className="badge badge-danger"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {player?.playerName}
            </p>
          ))}
        </div>

        <div className={classes?.actions}>
          <button
            className="btn btn-primary rounded-capsule "
            type="button"
            onClick={onClickSave}
            disabled={!isChanged}
          >
            Save
          </button>
          {/* <div className={classes?.addplayer} > */}
          <DeletePlayerGroup
            playerGroupId={data?.playerGroupId}
            setSelectedPlayerGroup={setSelectedPlayerGroup}
          />
          {/* </div> */}
        </div>
      </div>}

    </div>
  );
};

export default PlayerGroup;
