import { replayShot } from "../store/editHESlice";
import { useDispatch } from "react-redux";
import TimeInput from "./TimeInput";
const EditShot = ({ shot }) => {
  const dispatch = useDispatch();
  return (
    <div className="flex-column mb-3">
      <div className="row flex-nowrap mb-2 align-items-center">
        <span className="fs-1 font-weight-bold mr-2 ml-2">Shot {shot.shotNumber}</span>
        <button

        style={{width:"2.3rem",height:"1.8rem"}}
          className="btn btn-outline-primary btn-sm mx-1 d-flex align-items-center justify-content-center"
          type="button"
          data-toggle="tooltip"
          data-placement="top"
          title="replay"
          onClick={() => {
            dispatch(replayShot(shot.shotId));
          }}
        >
          <span className="mx-2 fas fa-redo-alt"></span>
        </button>
      </div>
      <div className="row flex-nowrap">
        <div style={{width:"45%"}} className="form-inline flex-column my-2 mr-2">
          <label className="text-center" htmlFor="startTime">
            Start Time
          </label>
          <TimeInput
            shot={shot}
            field="startTime"
            initialTime={shot.startTime}
          />
        </div>
        <div style={{width:"45%"}} className="form-inline flex-column my-2 mr-2">
          <label className="text-center" htmlFor="endTime">
            End Time
          </label>
          <TimeInput shot={shot} field="endTime" initialTime={shot.endTime} />
        </div>
      </div>
    </div>
  );
};

export default EditShot;
