import { useEffect } from "react";

const useShowToastr = ({ isSuccess, isError, title, successMsg, errorMsg, modalId }) => {
    useEffect(() => {
        toastr.options.positionClass = "toast-bottom-right";
        if (isSuccess) {
            toastr.success(successMsg, title);
            if (modalId) {
                $(`#${modalId}`).modal('hide');
            }
        }
        else if (isError) {
            toastr.error(errorMsg, title);
        }
    }, [isSuccess, isError]);
}

export default useShowToastr;
