import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";


export const imgaSlice = createApi({
    reducerPath: "imgaapi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/imga`,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getVideosScraped: builder.query({
            query: () => `/videos-scraped`,
        }),
        getVideosStatus: builder.query({
            query: () => `/videos-status`,
        }),
        getVideosCvVersions: builder.query({
            query: () => `/videos-cv-versions`,
        }),
        getVideosCodingStatus: builder.query({
            query: () => `/videos-coding-status`,
        }),
        getCodersCodingStatus: builder.query({
            query: () => `/coders-coding-status`,
        }),
        getVideoAppSummary: builder.query({
            query: () => `/video-app-summary`,
        }),
    }),
});

export const {
    useGetVideosScrapedQuery,
    useGetVideosStatusQuery,
    useGetVideosCvVersionsQuery,
    useGetVideosCodingStatusQuery,
    useGetCodersCodingStatusQuery,
    useGetVideoAppSummaryQuery
} = imgaSlice;