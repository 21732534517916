import { useState, useEffect, useReducer } from "react";
import { useGetCVDashboardQuery, useTerminateCVProcessMutation, useCreateSnapshotMutation } from "../api/cvSlice";
import { useSendVideoMutation, useMoveCvToVideoAppMutation } from "../api/collectedVideosSlice";
import { customReactDataTableStyles } from "../../utils/app-utils";
import { formatShortDetailedUtcDate, formatUtcDate } from "../../utils/app-utils";
import DataTable from "react-data-table-component";
import { IconVideo, IconSend, IconSquareRoundedPlus } from '@tabler/icons-react';
import ResponsivePagination from 'react-responsive-pagination';
import useShowToastr from "../../custom-hooks/useShowToastr";
import CVLogsModal from "./CVLogsModal";
import CopyButton from "../../UI/Buttons/CopyButton";
import ViewButton from "../../UI/Buttons/ViewButton";
import Spinner from "../../UI/Spinner";
import 'react-responsive-pagination/themes/bootstrap.css';
import "./CVDashboardPage.module.css";
import classes from "./CVDashboardPage.module.css";
import EllipseDropdownButton from "../../UI/Buttons/EllipseDropdownButton";
import { selectUser, selectIsAdmin, selectIsCV } from "../auth/authSlice";
import { useSelector } from "react-redux";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import CVMetrics from "./CVMetrics";
import CustomDatePicker from "../../UI/CustomDatePicker";

const NOT_PROCESSING_STATUS = ["FINISHED", "FAILED", "FAILED TO SEND", "TERMINATED"];

const today = new Date();

const oneWeekAgo = new Date();
oneWeekAgo.setDate(today.getDate() - 7);

const dashboardInputsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SEARCH_TEXT':
            return { ...state, searchText: action.value, currentPage: 1, isLoading: true };
        case 'SET_SOURCE':
            return { ...state, source: action.value, currentPage: 1, isLoading: true };
        case 'SET_STATUS':
            return { ...state, status: action.value, currentPage: 1, isLoading: true };
        case 'SET_MOVED_TO_VIDEO_APP':
            return { ...state, movedToVideoApp: action.value, currentPage: 1, isLoading: true };
        case 'SET_ORDER_BY_DATE':
            return { ...state, orderByDate: action.value, currentPage: 1, isLoading: true };
        case 'SET_ORDER_BY_LAST_UPDATE':
            return { ...state, orderByLastUpdate: action.value, currentPage: 1, isLoading: true };
        case 'SET_CURRENT_PAGE':
            return { ...state, currentPage: action.value, isLoading: true };
        case 'SET_LOADING':
            return { ...state, isLoading: action.value };
        case 'SET_CV_DASHBOARD_ITEMS':
            return { ...state, cvDashboardItems: action.value, isLoading: false };
        case 'SET_SELECTED_VIDEO':
            return { ...state, selectedVideo: action.value };
        case 'SET_START_DATE':
            return { ...state, startDate: action.value, currentPage: 1, isLoading: true };
        case 'SET_END_DATE':
            return { ...state, endDate: action.value, currentPage: 1, isLoading: true };
        default:
            return state;
    }
}

const CVDashboardPage = () => {
    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);
    const isCV = useSelector(selectIsCV);
    const [timeoutHandle, setTimeoutHandle] = useState(null);
    const [state, dispatch] = useReducer(dashboardInputsReducer, {
        currentPage: 1,
        searchText: '',
        source: '',
        status: 0,
        movedToVideoApp: '',
        orderByDate: 0,
        orderByLastUpdate: 0,
        cvDashboardItems: [],
        isLoading: true,
        selectedVideo: null,
        startDate: oneWeekAgo,
        endDate: today
    });

    const { currentPage, searchText, source, status, movedToVideoApp, orderByDate, orderByLastUpdate, cvDashboardItems, isLoading, selectedVideo, startDate, endDate } = state;

    const getPayload = () => {
        var payload = {
            pageNumber: currentPage,
            pageSize: 10,
            searchWords: searchText.length > 0 ? searchText.split(' ').concat(',') : "",
            source: source,
            movedToVideoApp: movedToVideoApp,
            status: status,
            orderByDate: orderByDate,
            orderByLastUpdate: orderByLastUpdate,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString()
        }
        return payload;
    }

    const { data, isLoading: isDataLoading, isFetching: isDataFetching, isError: errorLoadingData, refetch: refetchDashboardItems } = useGetCVDashboardQuery(getPayload());
    const [terminateCVProcess, { isSuccess: videoTerminatedSuccessfully, isError: errorTerminateVideo }] = useTerminateCVProcessMutation();
    const [createSnapshot, { isSuccess: snapshotCreatedSuccessfully, isError: errorCreatingSnapshot }] = useCreateSnapshotMutation();

    const [sendVideo, { isSuccess: videoSentSuccessfully, isError: errorSendingVideo }] = useSendVideoMutation();
    const [moveToVideoApp, { isSuccess: videoMovedSuccessfully, isError: ErrorMovingVideo }] = useMoveCvToVideoAppMutation();

    const countPages = data?.totalCount && !isLoading ? Math.ceil(data.totalCount / 10) : 0;

    useShowToastr({ isSuccess: videoTerminatedSuccessfully, isError: errorTerminateVideo, title: "Terminate Video", successMsg: "Video terminated successfully", errorMsg: "Error terminating video" });
    useShowToastr({ isSuccess: snapshotCreatedSuccessfully, isError: errorCreatingSnapshot, title: "Create Snapshot", successMsg: "Snapshot Created successfully", errorMsg: "Error creating snapshot" });
    useShowToastr({ isSuccess: videoSentSuccessfully, isError: errorSendingVideo, title: "Send Video", successMsg: "Video sent successfully", errorMsg: "Error sending video" });
    useShowToastr({ isSuccess: videoMovedSuccessfully, isError: ErrorMovingVideo, title: "Move Video", successMsg: "Video moved successfully", errorMsg: "Error moving video" });

    useEffect(() => {
        if (!isDataLoading && !isDataFetching)
            dispatch({ type: 'SET_LOADING', value: false });
    }, [isDataLoading, isDataFetching]);

    useEffect(() => {
        if (cvDashboardItems) {
            const cvProcessing = (item) => {
                return !NOT_PROCESSING_STATUS.some(status => status == item.status);
            };
            const processingRecord = cvDashboardItems.some(cvProcessing);

            if (!processingRecord) return;

            const interval = setInterval(async () => {
                await refetchDashboardItems(getPayload());
            }, 3000);
            return () => clearInterval(interval);
        }
    }, [cvDashboardItems])

    useEffect(() => {
        if (data?.items) {
            const newItems = data.items.map((cvItem) => {
                const existingItem = cvDashboardItems.find((item) => item.id == cvItem.id);
                if (existingItem) {
                    return {
                        ...cvItem,
                        selectedSnapshot: existingItem.selectedSnapshot
                    }
                }
                return cvItem;
            });
            dispatch({ type: 'SET_CV_DASHBOARD_ITEMS', value: newItems });
        }
    }, [data?.items]);

    const searchTextChangedHandler = (event) => {
        if (timeoutHandle) {
            clearTimeout(timeoutHandle);
        }
        setTimeoutHandle(setTimeout(() => {
            dispatch({ type: 'SET_SEARCH_TEXT', value: event.target.value });
        }, 1000));
    }

    const sourceChangedHandler = (event) => {
        dispatch({ type: 'SET_SOURCE', value: event.target.value });
    }
    const statusChangedHandler = (event) => {
        dispatch({ type: 'SET_STATUS', value: event.target.value });
    }

    const movedToVideoAppChangedHandler = (event) => {
        dispatch({ type: 'SET_MOVED_TO_VIDEO_APP', value: event.target.value });
    }

    const orderByMatchDateChangedHandler = (event) => {
        dispatch({ type: 'SET_ORDER_BY_DATE', value: event.target.value });
    }

    const orderByLastUpdateChangedHandler = (event) => {
        dispatch({ type: 'SET_ORDER_BY_LAST_UPDATE', value: event.target.value });
    }

    const pageChangedHandler = (page) => {
        dispatch({ type: 'SET_CURRENT_PAGE', value: page });
    }

    const startDateChangedHandler = (date) => {
        dispatch({ type: 'SET_START_DATE', value: date });
    }

    const endDateChangedHandler = (date) => {
        dispatch({ type: 'SET_END_DATE', value: date });
    }

    const snapshotChangedHandler = (event, item) => {
        const newItems = cvDashboardItems.map((cvItem) => {
            if (cvItem.id == item.id) {
                return {
                    ...cvItem,
                    selectedSnapshot: event.target.value
                };
            }
            return cvItem;
        });
        dispatch({ type: 'SET_CV_DASHBOARD_ITEMS', value: newItems });
    }

    const columns = [
        {
            name: 'ID',
            selector: item => item.id,
            omit: true,
        },
        {
            name: 'Title',
            selector: item => <strong className="fs-0 mr-2">{item.title}</strong>,
            wrap: true,
            grow: 2,
        },
        {
            name: '',
            selector: item => getTitleActions(item),
            wrap: true,
            center: "true",
            width: '7%'
        },
        {
            name: 'Match Date',
            selector: item => formatUtcDate(item.matchDate),
            center: "true",
            width: '10%'
        },
        {
            name: 'Status',
            selector: item => getStatus(item),
            center: "true",
        },
        {
            name: 'Last Update',
            selector: item => <span>{formatShortDetailedUtcDate(item.lastUpdatedAt)}</span>,
            center: "true",
        },
        {
            name: 'Video App',
            selector: item => item.movedToVideoClient ? 'Yes' : 'No',
            center: "true",
            width: '10%'
        },
        {
            name: 'Snapshots',
            selector: item => getSnapshots(item),
            center: "true",
        },
        {
            name: 'Actions',
            selector: item => getActions(item),
            center: "true",
            width: '10%'
        },
    ];

    const getTitleActions = (item) => {
        return (
            <div className="d-flex row flex-nowrap align-items-center mx-0">
                <CopyButton text={item.id} />
                <ViewButton onClick={() => navigateToVideosPage(item.source, item.id)} />
            </div >
        );
    }

    const getStatus = (item) => {
        let content;
        if (!NOT_PROCESSING_STATUS.some(status => status == item.status)) {
            content = <div
                className="spinner-border spinner-border-sm text-secondary mr-5"
                role="status"
                data-toggle="tooltip"
                title="In Progress"
            />
        }
        else if (item.status.includes("FAILED") || item.status === "TERMINATED") {
            content = <span
                className="d-inline-block mr-5"
                tabIndex="1"
                data-toggle="tooltip"
                title="Failed"
            >
                <i className="fas fa-times-circle text-danger"></i>
            </span>
        } else if (item.status === "FINISHED") {
            content = <span
                className="d-inline-block mr-5"
                tabIndex="1"
                data-toggle="tooltip"
                title="Succeeded"
            >
                <i className="fas fa-check-circle text-success"></i>
            </span>
        }
        return <div className="d-flex align-items-center">
            {content}
            <button type="button" className="btn btn-sm btn-primary" onClick={() => {
                dispatch({ type: 'SET_SELECTED_VIDEO', value: item });
                $("#logsModal").modal('show');
            }} >View Logs</button>
        </div>
    }

    const getSnapshots = (item) => {
        return (
            <select
                style={{ width: "11rem" }}
                className="form-control form-control-sm"
                value={item.selectedSnapshot}
                onChange={(e) => snapshotChangedHandler(e, item)}

            >
                <option value={''}>Select Snapshot</option>
                {item.snapshots.map((snapshot) => (
                    <option key={`${item.id}-${snapshot}`} value={snapshot}>
                        {`${formatShortDetailedUtcDate(snapshot)}`}
                    </option>
                ))}
            </select>);
    }

    const getActions = (item) => {
        return (
            <EllipseDropdownButton extraStyles={{ width: "auto", height: "auto" }}>
                {NOT_PROCESSING_STATUS.some(status => status == item.status) && <span className="dropdown-item ">
                    <button className="btn btn-sm btn-light ml-2" onClick={async () => {
                        await sendVideo({ videoId: item.id, env: "prod" });
                        await refetchDashboardItems(getPayload());
                    }
                    }>
                        <IconSend stroke={2} />
                        <span className="ml-2">Send To CV</span>
                    </button>
                </span>}

                {item.selectedSnapshot != '' && <span className="dropdown-item">
                    <button className="btn btn-sm btn-light ml-2" onClick={async () => {
                        await moveToVideoApp({ videoId: item.id, snapshot: item.selectedSnapshot });
                        await refetchDashboardItems(getPayload());
                    }
                    }>
                        <IconVideo stroke={2} />
                        <span className="ml-2">Move To Video App</span>

                    </button>
                </span>}

                {item.status.includes("FINISHED") && <span className="dropdown-item">
                    <button className="btn btn-sm btn-light ml-2" onClick={async () => {
                        await createSnapshot(item.id);
                        await refetchDashboardItems(getPayload());
                    }
                    }>
                        <IconSquareRoundedPlus stroke={2} />
                        <span className="ml-2">Create Snapshot</span>

                    </button>
                </span>}

                {!NOT_PROCESSING_STATUS.some(status => status == item.status) && <span className="dropdown-item">
                    <button className="btn btn-sm btn-light ml-2" onClick={async () => {
                        await terminateCVProcess(item.id);
                        await refetchDashboardItems(getPayload());
                    }
                    }>
                        <i className="fas fa-trash"></i>
                        <span className="ml-2">Terminate</span>
                    </button>
                </span>}
            </EllipseDropdownButton>
        );
    }

    const navigateToVideosPage = (source, id) => {
        const newWindow = window.open(`${process.env.REACT_APP_PUBLIC_URL}/videoslinking/${source}?videoProcessId=${id}`);
        if (newWindow) newWindow.opener = null;
    }

    if (!user) return <Spinner />;
    else if (!isAdmin && !isCV) return <NotAuthorizedPage />;

    return (
        <>
            {<CVLogsModal video={selectedVideo} />}
            <CVMetrics />
            <div className="d-flex flex-column align-items-center">
                <div className="d-flex row mb-3 ml-1 align-items-center justify-content-center w-100">
                    <div className="d-flex flex-column align-items-center mr-5">
                        <h5>Title</h5>
                        <input type="text" className="form-control" placeholder="Search" onChange={searchTextChangedHandler} />
                    </div>

                    <div className="d-flex flex-column align-items-center mr-5">
                        <h5>Status</h5>
                        <select value={status} className="form-control" onChange={statusChangedHandler}>
                            <option value={0}>All</option>
                            <option value={1}>In Progress</option>
                            <option value={2}>Finished</option>
                            <option value={3}>Failed</option>
                            <option value={4}>Terminated</option>
                        </select>
                    </div>

                    <div className="d-flex flex-column align-items-center mr-5" >
                        <h5>Video App</h5>
                        <select style={{ width: "10rem" }} value={movedToVideoApp} className="form-control" onChange={movedToVideoAppChangedHandler}>
                            <option value={''}>Select</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                    </div>

                    <div className="d-flex flex-column align-items-center mr-5" >
                        <h5>Order By Match Date</h5>
                        <select value={orderByDate} className="form-control" onChange={orderByMatchDateChangedHandler}>
                            <option value={0}>Select</option>
                            <option value={1}>Ascending</option>
                            <option value={2}>Descending</option>
                        </select>
                    </div>
                    <div className="d-flex flex-column align-items-center mr-5">
                        <h5>Order By Last Update</h5>
                        <select value={orderByLastUpdate} className="form-control" onChange={orderByLastUpdateChangedHandler}>
                            <option value={0}>Select</option>
                            <option value={1}>Ascending</option>
                            <option value={2}>Descending</option>
                        </select>
                    </div>
                    <div className="d-flex flex-column align-items-center mr-5" >
                        <h5>Source</h5>
                        <select value={source} className="form-control" onChange={sourceChangedHandler}>
                            <option value=''>All</option>
                            <option value='ttv'>Tennis TV</option>
                            <option value='wtv'>WTA TV</option>
                            <option value='ctv'>Challenger TV</option>
                            <option value='recording-team'>Recording Team</option>
                            <option value='wtt'>ITF</option>
                            <option value='tc'>Tennis Channel</option>
                            <option value='espn'>ESPN</option>
                            <option value='dropbox'>Dropbox</option>
                            <option value='img'>IMG</option>
                        </select>
                    </div>
                </div>
                <div className="row align-items-center justify-content-around mb-3">
                    <CustomDatePicker date={startDate} setDate={startDateChangedHandler} label="Start Date" />
                    <CustomDatePicker date={endDate} setDate={endDateChangedHandler} label="End Date" />
                </div>
                {isLoading && <Spinner />}
                {!isLoading && <>
                    <DataTable
                        columns={columns}
                        data={errorLoadingData ? [] : cvDashboardItems}
                        responsive
                        highlightOnHover
                        theme="dark"
                        customStyles={customReactDataTableStyles}
                        noDataComponent={<strong className={`${classes["center-element"]} fs-1`}>{errorLoadingData ? "Error loading data" : "No Data Is Available"}</strong>}
                    />
                    <div style={{ width: "30%" }} className=" mt-4 mb-3">
                        <h4 className="text-center"> Total Count: ({data && !isLoading ? data.totalCount : 0})</h4>
                        <ResponsivePagination
                            current={currentPage}
                            total={countPages}
                            onPageChange={pageChangedHandler}
                            previousLabel="Previous"
                            nextLabel="Next"
                        />
                    </div>
                </>
                }
            </div >
        </>
    );
}

export default CVDashboardPage;