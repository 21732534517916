const ErrorModal = ({ report }) => {
    return (
        <div>
            <button
                type="button"
                className="btn btn-sm btn-danger mr-3"
                data-toggle="modal"
                data-target={`#errorModal${report.reportId}`}
            >
                <i className="fas fa-eye"></i>
            </button>
            <div

                className="modal fade"
                id={`errorModal${report.reportId}`}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="errorModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="errorModalLabel">
                                Error
                            </h5>
                        </div>
                        <div className="modal-body">
                            {report.errorMessage}
                        </div>
                        <div className="modal-footer">
                            <button

                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorModal;