import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const reportsSlice = createApi({
    reducerPath: "reportapi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getStdReportMatchesCount: builder.mutation({
            query: (payload) => ({
                url: `/report/std/matches`,
                method: "POST",
                body: payload,
            }),
        }),
        getHEReportMatchesCount: builder.mutation({
            query: (payload) => ({
                url: `/he/trackingsheet`,
                method: "POST",
                body: payload,
            }),
        }),
        getClientsRecords: builder.query({
            query: (isActive) => `report/internalclient?isActive=${isActive}`,
            extraOptions: { maxRetries: 3 },
        }),
        getClientOpponents: builder.query({
            query: (clientId) => `report/internalclient/${clientId}`,
        }),
        getExecutionRecord: builder.query({
            query: (internalClientId) => `report/internalclient/execution/${internalClientId}`,
        }),
        deactivateInternalClient: builder.mutation({
            query: (internalClientId) => ({
                url: `report/internalclient/deactivate/${internalClientId}`,
                method: "PUT",
            }),
        }),
        createInternalClient: builder.mutation({
            query: (payload) => ({
                url: `report/internal`,
                method: "POST",
                body: payload,
            }),
        }),
        restartAutogeneration: builder.mutation({
            query: ({ id }) => ({
                url: `report/autogeneration/${id}`,
                method: "POST",
            }),
        }),
    }),

});

export const {
    useGetStdReportMatchesCountMutation,
    useGetHEReportMatchesCountMutation,
    useGetClientsRecordsQuery,
    useLazyGetClientOpponentsQuery,
    useGetExecutionRecordQuery,
    useDeactivateInternalClientMutation,
    useCreateInternalClientMutation,
    useRestartAutogenerationMutation
} = reportsSlice;