import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { secondsToTime } from "../../../utils/app-utils.js";
import {
  selectCurrentlyPlayingPoint,
  setActivePointWithStep,
  replayPoint,
  editActivePointEntity
} from "../store/editHESlice";
import EditShot from "./EditShot";
import Switch from "../../../components/Switch.js";
import ScoreInput from "../../../components/ScoreInput.js";

export default function EditPoint() {
  const activePoint = useSelector(selectCurrentlyPlayingPoint);
  const dispatch = useDispatch();

  const optionChangedHandler = (field, value) => {
    const updatedPoint = { ...activePoint, [field]: value };
    dispatch(editActivePointEntity(updatedPoint));
  }

  return (
    <div style={{ height: "fit-content" }} className="card mt-1 max-vh-75">
      <div className="card-header">
        <div className="row d-flex align-items-center">
          <div className="col-md-5 d-flex justify-content-start">
            <h5>Edit Point</h5>
          </div>
          <div className="col-md-7 d-flex justify-content-end">
            <button
              className="btn btn-outline-primary btn-sm mx-1"
              type="button"
              data-toggle="tooltip"
              data-placement="top"
              title="previous"
              onClick={() => {
                dispatch(setActivePointWithStep(-1));
              }}
            >
              <span className="mx-2 fas fa-step-backward"></span>
            </button>
            <button
              className="btn btn-outline-primary btn-sm mx-1"
              type="button"
              data-toggle="tooltip"
              data-placement="top"
              title="replay"
              onClick={() => {
                dispatch(replayPoint());
              }}
            >
              <span className="mx-2 fas fa-redo-alt"></span>
            </button>
            <button
              className="btn btn-outline-primary btn-sm mx-1"
              type="button"
              data-toggle="tooltip"
              data-placement="top"
              title="next"
              onClick={() => {
                dispatch(setActivePointWithStep(1));
              }}
            >
              <span className="mx-2 fas fa-step-forward"></span>
            </button>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column my-2 mr-2 align-items-center justify-content-center">
        <label className="text-center" htmlFor="startTime">
          Point Start Time
        </label>
        <input
          style={{ width: "45%" }}
          id="startTime"
          type="text"
          className="form-control text-center"
          value={secondsToTime(activePoint?.startTime)}
          readOnly={true}
        />
      </div>

      <div
        style={{ height: "80%" }}
        className="card-body scrollbar perfect-scrollbar"
      >
        <div className="form-inline row my-1 ml-1">
          <label className="text-center mx-2">
            Is Hidden
          </label>
          <Switch field="isHidden" value={activePoint?.isHidden} onChanged={optionChangedHandler} />
        </div>
        <div className="form-inline row my-2">
          <label className="text-center col-4" htmlFor="matchScore">
            Match Score
          </label>
          <ScoreInput field="matchScore" value={activePoint.matchScore} onScoreChanged={optionChangedHandler} />
        </div>
        <div className="form-inline row my-2">
          <label className="text-center col-4" htmlFor="setScore">
            Set Score
          </label>
          <ScoreInput field="setScore" value={activePoint.setScore} onScoreChanged={optionChangedHandler} />
        </div>
        <div className="form-inline row mt-2 mb-3">
          <label className="text-center col-4" htmlFor="gameScore">
            Game Score
          </label>
          <ScoreInput field="gameScore" value={activePoint.gameScore} onScoreChanged={optionChangedHandler} />
        </div>
        {activePoint &&
          activePoint.shots.map((s) => <EditShot shot={s} key={s.shotId} />)}
      </div>

      <div className="d-flex flex-column my-2 mr-2 align-items-center justify-content-center">
        <label className="text-center" htmlFor="endTime">
          Point End Time
        </label>
        <input
          style={{ width: "45%" }}
          id="endTime"
          type="text"
          className="form-control text-center"
          value={secondsToTime(activePoint?.endTime)}
          readOnly={true}
        />
      </div>
    </div>
  );
}
