export function secondsToTime(secs) {
  let hours = Math.floor(secs / (60 * 60));
  let minutes = Math.floor((secs % (60 * 60)) / 60);
  let seconds = Math.floor(secs % 60);

  //show numbers as 00:00:00
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${hours}:${minutes}:${seconds}`;
}

export function convertTimeStringToSeconds(timeString, roundDown = true) {
  if (!timeString) {
    return timeString;
  }
  const timeParts = timeString.split(':');
  const hours = parseInt(timeParts[0], 10) || 0;
  const minutes = parseInt(timeParts[1], 10) || 0;
  const secondsWithMilliseconds = parseFloat(timeParts[2]) || 0;

  let totalSeconds = hours * 3600 + minutes * 60 + secondsWithMilliseconds;

  totalSeconds = roundDown ? Math.floor(totalSeconds) : Math.ceil(totalSeconds);

  return totalSeconds;
}

export function secondsToTimeSpan(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedTimeSpan = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;

  return formattedTimeSpan;
}

export function formatUtcDate(utcDateTimeString) {
  const utcDate = new Date(utcDateTimeString);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const localDateString = utcDate.toLocaleDateString(undefined, options);
  return localDateString;
}

export function formatDetailedUtcDate(utcDatetimeString) {
  try {
    const utcDate = new Date(utcDatetimeString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const localDateString = utcDate.toLocaleDateString(undefined, options);
    return localDateString;
  } catch (ex) {
    return utcDatetimeString;
  }
}

export function formatShortDetailedUtcDate(utcDatetimeString) {
  const utcDate = new Date(utcDatetimeString);

  const day = utcDate.getDate().toString().padStart(2, '0');
  const month = (utcDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = utcDate.getFullYear();

  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };
  const timeString = utcDate.toLocaleTimeString(undefined, options);

  const formattedDate = `${day}/${month}/${year} ${timeString}`;

  return formattedDate;
}

export function convertMPHtoKMH(mph) {
  return mph * 1.60934;
}

export function convertKMHtoMPH(kmh) {
  return kmh / 1.60934;
}

export function getRandomString() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export const customReactDataTableStyles = {
  table: {
    style: {
      maxHeight: '65vh',
      overflowY: 'auto',
      overflowX: 'auto',
    },
  },
  headCells: {
    style: {
      fontSize: '1.1rem',
      fontWeight: 'bold',
      color: '#FFFFFF',
      backgroundColor: '#343a40',
      flex: '1',
      justifyContent: 'center',
      minWidth: '10%',
    },
  },
  cells: {
    style: {
      color: '#FFFFFF',
      backgroundColor: '#182230',
      flex: '1',
      minWidth: 'fit-content',
      justifyContent: 'center',
      margin: '0px',
      padding: '0px',
    },
  }
};

export const dropboxOptions = {

  // Required. Called when a user selects an item in the Chooser.
  success: function (files) {
    alert("Here's the file link: " + files[0].link)
  },

  // Optional. Called when the user closes the dialog without selecting a file
  // and does not include any parameters.
  cancel: function () {

  },

  // Optional. "preview" (default) is a preview link to the document for sharing,
  // "direct" is an expiring link to download the contents of the file. For more
  // information about link types, see Link types below.
  linkType: "preview", // or "direct"

  // Optional. A value of false (default) limits selection to a single file, while
  // true enables multiple file selection.
  multiselect: false, // or true

  // Optional. This is a list of file extensions. If specified, the user will
  // only be able to select files with these extensions. You may also specify
  // file types, such as "video" or "images" in the list. For more information,
  // see File types below. By default, all extensions are allowed.
  extensions: ['.pdf', '.doc', '.docx'],

  // Optional. A value of false (default) limits selection to files,
  // while true allows the user to select both folders and files.
  // You cannot specify `linkType: "direct"` when using `folderselect: true`.
  folderselect: true, // or true

  // Optional. A limit on the size of each file that may be selected, in bytes.
  // If specified, the user will only be able to select files with size
  // less than or equal to this limit.
  // For the purposes of this option, folders have size zero.
  sizeLimit: 1024, // or any positive number
};

export const reactSelectOptions = {
  control: styles => ({ ...styles, backgroundColor: '#0e1c2f', borderColor: '#344050', color: '#d8e2ef' }),
  input: styles => ({ ...styles, color: '#d8e2ef' }),
  option: (styles) => {
    return {
      ...styles,
      backgroundColor: '#0e1c2f',
      color: '#d8e2ef',
    };
  },
  placeholder: (styles) => {
    return {
      ...styles,
      color: '#d8e2ef',
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#d8e2ef',
      color: '#0e1c2f',
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      color: '#d8e2ef',
    };
  },
};
