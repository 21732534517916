import React, { useEffect } from 'react';
import { selectIsAdmin, selectUser } from "../auth/authSlice";
import { useGetActiveAccountsQuery } from '../api/accountSlice';
import { useSelector } from 'react-redux';
import Spinner from '../../UI/Spinner';
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import { selectFilteredClients, filterClientsByName, setClients, selectSelectedClient, setSelectedClient } from './clientsSlice';
import { useDispatch } from 'react-redux';
import ClientDetails from './clientDetails';


const ClientsPage = () => {
    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);
    const dispatch = useDispatch();

    const filteredClients = useSelector(selectFilteredClients);
    const selectedClient = useSelector(selectSelectedClient);

    const count = filteredClients ? filteredClients.length : 0;

    const { data: clients, error, isLoading } = useGetActiveAccountsQuery();

    useEffect(() => {
        if (clients) {
            dispatch(setClients(clients));
        }
    }, [clients, dispatch]);


    if (!user) return <Spinner />;
    else if (!isAdmin) return <NotAuthorizedPage />;

    if (isLoading) return <Spinner />;
    return (
        <div className='row'>
            <div className="col-4 mb-3">
                <div className="card mt-1 min-vh-75 max-vh-75">
                    <div className="card-header">
                        <div className="row justify-content-between">
                            <h5>Active Clients ({count})</h5>
                            <div className="col-12 mt-1">
                                <input type="text" className="form-control" placeholder="Search by name" onChange={(x) => {
                                    dispatch(filterClientsByName(x.target.value));
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0 scrollbar perfect-scrollbar">
                        <div className="list-group">
                            {filteredClients.map(client => {
                                let classes = "list-group-item list-group-item-action";
                                if (client.name === selectedClient?.name) {
                                    classes += " bg-500 text-white";
                                }
                                return (
                                    <a
                                        style={{ cursor: "pointer" }}
                                        key={client.accountId}
                                        className={classes}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            e.nativeEvent.stopImmediatePropagation();
                                            dispatch(setSelectedClient(client));
                                        }}
                                    >
                                        <strong>{client.name}</strong>
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-8'>
                {selectedClient && <ClientDetails client={selectedClient} />}
            </div>
        </div>
    );
};


export default ClientsPage;