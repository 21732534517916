import { useEffect, useState } from "react";
import Select from "react-select";
import { useUpdateTournamentLookupMutation } from "../api/lookupsSlice";
import { useGetEventInstancesQuery } from "../api/completeMatchesSlice";
import { formatUtcDate } from "../../utils/app-utils";
const TournamentDetails = ({
  tournamentDetails,
  setSelectedTournamentLookupId,
}) => {
  const { data: eventInstances } = useGetEventInstancesQuery(true);
  const [selectedeventInstanceId, setSelectedeventInstanceId] = useState(null);

  const [
    updateTournamentLookup,
    { isSuccess: updatedSuccessfully, isError: failedToUpdate },
  ] = useUpdateTournamentLookupMutation();

  useEffect(() => {
    toastr.options.positionClass = "toast-bottom-right";
    if (updatedSuccessfully) {
      toastr.success("Saved Successfully!", "Update Tournament Lookup");
      setSelectedTournamentLookupId(null);
    } else if (failedToUpdate) {
      toastr.error("Failed to update!", "Update Tournament Lookup");
    }
  }, [updatedSuccessfully, failedToUpdate]);

  const eventInstancesOptions = eventInstances?.filter(e => !e.year || e.year >= 2023).map((eventInstance) => ({
    value: eventInstance.eventInstanceId,
    label: `${eventInstance.name} - ${eventInstance.category} - ${formatUtcDate(eventInstance.startDate)}`,
  }));

  return (
    <div className="card mb-2 p-2">
      <h3 className="card-title my-2">{tournamentDetails.sourceName} </h3>
      <div className="card-body d-flex flex-column">
        <span>Source: {tournamentDetails.source}</span>
        <span className="mt-2">Year: {tournamentDetails.year}</span>
        <div className="row flex-nowrap align-items-center ml-0 mt-2">
          <span className="mr-2 text-nowrap">Suggested Tournament: </span>
          <div className="col-8">
            <Select
              options={eventInstancesOptions}
              value={eventInstancesOptions?.find(
                (eventInstance) =>
                  eventInstance.value === selectedeventInstanceId
              )}
              onChange={(selectedOption) =>
                setSelectedeventInstanceId(selectedOption.value)
              }
            />
          </div>
        </div>
        <div className="d-flex justify-content-center  mt-4">
          <button
            className="btn btn-warning btn-md col-3"
            data-toggle="modal"
            data-target="#updateTournamentLookupConfirmationModal"
            type="button"
          >
            Save
          </button>
          <div
            className="modal fade"
            id="updateTournamentLookupConfirmationModal"
            role="dialog"
            aria-labelledby="updateTournamentLookupConfirmationModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h3
                    className="modal-title"
                    id="updateTournamentLookupConfirmationModalLabel"
                  >
                    Update Tournament Linking
                  </h3>
                </div>
                <div className="modal-body">
                  <p className="mb-2">
                    Are you sure you want to link <br />
                    Source: <strong>{tournamentDetails.sourceName}</strong>{" "}
                    <br />
                    {selectedeventInstanceId ? (
                      <div>
                        Event:{" "}
                        <strong>
                          {
                            eventInstancesOptions?.find(
                              (event) => event.value === selectedeventInstanceId
                            )?.label
                          }
                        </strong>
                      </div>
                    ) : null}
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-secondary btn-md"
                    type="button"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-warning btn-md"
                    type="button"
                    data-dismiss="modal"
                    onClick={() =>
                      updateTournamentLookup({
                        tournamentLookupId:
                          tournamentDetails.tournamentLookupId,
                        eventInstanceId: selectedeventInstanceId,
                      })
                    }
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TournamentDetails;
