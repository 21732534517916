import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const userSlice = createApi({
    reducerPath: "userapi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["reportExecutionRecord", "optionsList"],
    endpoints: (builder) => ({
        getUserTargets: builder.query({
            query: (isActive) => `user/target?isActive=${isActive}`,
            providesTags: (result, error, args) => [
                "optionsList",
            ],
        }),

        getUserTargetReportExecutionRecord: builder.query({
            query: (targetId) => `user/target/${targetId}`,
            providesTags: (result, error, args) => [
                "reportExecutionRecord",
            ],
        }),
        createUserTargetRecord: builder.mutation({
            query: (payload) => ({
                url: `user/target`,
                method: "POST",
                body: payload,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            invalidatesTags: (result, error, args) => ["optionsList"]
        }),
        deactivateUserTargetReport: builder.mutation({
            query: (targetPlayerId) => ({
                url: `user/deactivate/target/${targetPlayerId}`,
                method: "PUT",
            }),
            invalidatesTags: (result, error, args) => ["optionsList"],
        }),
        getUserSettings: builder.query({
            query: () => `/user/settings`
        }),
        updateUserSettings: builder.mutation({
            query: (settings) => ({
                url: `/user/settings`,
                method: 'PUT',
                body: settings
            })
        }),
    }),
});

export const {
    useGetUserTargetsQuery,
    useGetUserTargetReportExecutionRecordQuery,
    useCreateUserTargetRecordMutation,
    useDeactivateUserTargetReportMutation,
    useGetUserSettingsQuery,
    useUpdateUserSettingsMutation,
} = userSlice;
