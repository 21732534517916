import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const lookupsSlice = createApi({
  reducerPath: "lookupsapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/Lookups`,
    mode: "cors",
    prepareHeaders: (headers) => {
      const token = getAuthToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["playerlookup", "tournamentslookup"],
  endpoints: (builder) => ({
    getNeedsReviewPlayers: builder.query({
      query: () => `/all/needs-review`,
      providesTags: ["playerlookup"],
    }),
    getPlayerLookupById: builder.query({
      query: (id) => `/${id}`,
    }),
    updatePlayerLookup: builder.mutation({
      query: ({ playerLookupId, playerId }) => ({
        url: `/${playerLookupId}`,
        method: "PUT",
        body: playerId,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: (result, error, args) => ["playerlookup"],
    }),
    getNeedsReviewTournamnets: builder.query({
      query: () => `/all/tournament/needs-review`,
      providesTags: ["tournamentslookup"],
    }),
    getTournamnetLookupById: builder.query({
      query: (id) => `/tournament/${id}`,
    }),
    updateTournamentLookup: builder.mutation({
      query: ({ tournamentLookupId, eventInstanceId }) => ({
        url: `/tournament/${tournamentLookupId}`,
        method: "PUT",
        body: eventInstanceId,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: (result, error, args) => ["tournamentslookup"],
    }),
  }),
});

export const {
  useGetNeedsReviewPlayersQuery,
  useGetPlayerLookupByIdQuery,
  useUpdatePlayerLookupMutation,
  useGetNeedsReviewTournamnetsQuery,
  useGetTournamnetLookupByIdQuery,
  useUpdateTournamentLookupMutation,
} = lookupsSlice;
