import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const oktaSlice = createApi({
    reducerPath: "oktaapi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getCodersList: builder.query({
            query: (isActive) => `okta/group/coders`,
        }),
        getAdminsList: builder.query({
            query: (isActive) => `okta/group/admins`,
        }),
    }),
});

export const {
    useGetCodersListQuery,
    useGetAdminsListQuery,
} = oktaSlice;
