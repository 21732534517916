const getAuthToken = () => {
  const oktaTokenStorage = localStorage.getItem('okta-token-storage');

  if (oktaTokenStorage) {
    try {
      const tokenObject = JSON.parse(oktaTokenStorage);

      if (tokenObject && tokenObject.accessToken) {
        return tokenObject.accessToken.accessToken;
      } else {
        console.error('Invalid token object or missing idToken property.');
      }
    } catch (error) {
      console.error('Error parsing the JSON string:', error);
    }
  } else {
    console.error('Token not found in local storage.');
  }
  return null;
}

export default getAuthToken;