import { formatDetailedUtcDate } from "../../../utils/app-utils";
import ReportsActions from "../ReportsActions";
import ReportEdits from "../ReportEdits";
import ReportStatus from "../ReportStatus";
import ErrorModal from "../ErrorModal";

const HEReportRowItem = ({ reportExecutionId, report, setSelectedReport }) => {

    return (
        <div className="card" key={report.reportId}>
            <div style={{ marginLeft: 25 }} className="card-body">
                <div className="row">
                    <div className="col-11">
                        <h4 className="card-title fs-0 d-flex row text-nowrap flex-nowrap">
                            {report.resources?.length > 0
                                ? report.resources[0].name
                                : "-"}
                            <ReportEdits reportExecutionId={reportExecutionId} report={report} setSelectedReport={setSelectedReport} reportType="he-report" />
                            {report.resources.length > 0 && <ReportsActions
                                reportBlobPath={report.resources[0].path}
                            />}
                        </h4>
                    </div>

                    <div>
                        <div className="card-text d-flex row align-items-center">
                            {report.jobStatus == "Failed" && <ErrorModal report={report} />}
                            <ReportStatus status={report.jobStatus} />
                        </div>
                    </div>
                </div>
                {report.jobStatus != "Succeeded" && <div className="row mb-1">
                    <div className="badge badge-warning rounded-capsule mr-1 mb-1">
                        {`Category : ${report.reportInputs.category === 1 ? "ATP" : "WTA"}`}
                    </div>
                    <div className="badge badge-warning rounded-capsule mr-1 mb-1">
                        {`Target : ${report.reportInputs.targetPlayer}`}
                    </div>
                    <div className="badge badge-warning rounded-capsule mr-1 mb-1">
                        {`Opponent : ${report.reportInputs.opponentPlayer == "" ? "All" : report.reportInputs.opponentPlayer}`}
                    </div>
                    <div className="badge badge-warning rounded-capsule mr-1 mb-1">
                        {`From : ${report.reportInputs.fromYear}`}
                    </div>
                    <div className="badge badge-warning rounded-capsule mr-1 mb-1">
                        {`To : ${report.reportInputs.toYear}`}
                    </div>
                    {report.reportInputs.selectedTournaments.length > 0 && <div className="badge badge-warning rounded-capsule mr-1 mb-1">
                        Tournament: {report.reportInputs.selectedTournaments.join(', ')}
                    </div>
                    }
                    <div className="badge badge-warning rounded-capsule mr-1 mb-1">
                        {`Surfaces : ${report.reportInputs.targetSurfaces}`}
                    </div>
                    <div className="badge badge-warning rounded-capsule mr-1 mb-1">
                        {`Speed : ${report.reportInputs.targetSpeedUnit}`}
                    </div>
                </div>}

                <div className="row">
                    <div className="col-6">
                        <p className="card-text">Created By : {report.createdBy}</p>
                    </div>
                    <div className="col-6">
                        <p className="card-text">
                            {formatDetailedUtcDate(report.createdAt)}
                        </p>
                    </div>
                </div>
                <div
                    className="row"
                    style={{
                        justifyContent: "center",
                        marginTop: "15px",
                        fontWeight: "bold",
                        gap: "25px",
                        alignItems: "center"
                    }}
                >
                </div>
            </div>
        </div>
    );
};

export default HEReportRowItem;
