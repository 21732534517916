import { serviceLocationTypeOptions, shotTypeOptions, strokeTypeOptions, shotLocationTypeOptions, verticalPlayerPositionOptions, horizontalPlayerPositionOptions, shotMovementTypeOptions, rallyEndingShotTypeOptions } from './options';
import * as constants from './constants.js';
const emptyShot = {
    shotId: 0,
    matchPointId: 0,
    playerId: 0,
    isAce: false,
    out: false,
    net: false
}

function addReturn(newPoint) {
    addShot(newPoint, constants.RETURN_SHOT_ORDER_ID, 2)
}
function addServePlus(newPoint) {
    addShot(newPoint, constants.SERVE_PLUS_ONE_SHOT_ORDER_ID, 3)
}
function addSecondToLast(newPoint) {
    addShot(newPoint, constants.SECOND_TO_LAST_SHOT_ORDER_ID, newPoint.rallyLength - 1);
}
function addLast(newPoint) {
    addShot(newPoint, constants.LAST_SHOT_ORDER_ID, newPoint.rallyLength);
}

function addShot(newPoint, shotOrderId, number) {
    if (newPoint.shots.findIndex((s) => s.shotOrderId === shotOrderId) === -1) {
        let shots = [...newPoint.shots];
        shots.push({
            ...emptyShot,
            shotOrderId: shotOrderId,
            number: number,
        });
        shots.sort((a, b) => a.shotOrderId - b.shotOrderId);
        newPoint.shots = shots;
    }
}

function getServeMissingFields(firstServeShot, secondServeShot) {
    let missingFields = [];

    //first serve Location is required
    if (firstServeShot && isEmptyField(firstServeShot.serviceLocationId, serviceLocationTypeOptions)) {
        missingFields.push("First Serve Location is required.");
    }
    //second serve Location is required
    if (secondServeShot && isEmptyField(secondServeShot.serviceLocationId, serviceLocationTypeOptions)) {
        missingFields.push("Second Serve Location is required.");
    }
    return missingFields;
}

function getReturnMissingFields(point, returnShot, isGS) {
    if (!isGS) {
        return getMissingShotLocationField(returnShot, 'Return');
    }

    if (returnShot && !point.shots.some(s => s.shotOrderId == constants.LAST_SHOT_ORDER_ID)) {
        return getLastShotMissingFields(point, returnShot, 'Return');
    } else if (returnShot && !point.shots.some(s => s.shotOrderId == constants.SECOND_TO_LAST_SHOT_ORDER_ID)) {
        return getSecondToLastMissingFields(returnShot, 'Return');
    } else {
        return getMissingShotLocationField(returnShot, 'Return');
    }
}

function getServePlusMissingFields(point, servePlusShot) {
    if (servePlusShot && !point.shots.some(s => s.shotOrderId == constants.LAST_SHOT_ORDER_ID)) {
        return getLastShotMissingFields(point, servePlusShot, 'Serve Plus');
    } else if (servePlusShot && !point.shots.some(s => s.shotOrderId == constants.SECOND_TO_LAST_SHOT_ORDER_ID)) {
        return getSecondToLastMissingFields(servePlusShot, 'Serve Plus');
    } else {
        return getMissingShotLocationField(servePlusShot, 'Serve Plus');
    }
}

function getSecondToLastMissingFields(secondToLastShot, shotTitle) {
    return getCommonGSMissingFields(secondToLastShot, shotTitle);
}

function getLastShotMissingFields(point, lastShot, shotTitle) {
    let missingFields = [];
    if (lastShot && isEmptyField(point.rallyEndingShotTypeId, rallyEndingShotTypeOptions)) {
        missingFields.push('Rally Ending Shot Type is required.');
    }
    return missingFields.concat(getCommonGSMissingFields(lastShot, shotTitle));
}

function getMissingShotLocationField(shot, shotTitle) {
    let missingFields = [];

    //gs shot location is required
    if (shot && isEmptyField(shot.shotLocationId, shotLocationTypeOptions)) {
        missingFields.push(`${shotTitle} Location is required.`);
    }
    return missingFields;
}

function getCommonGSMissingFields(shot, shotTitle) {

    let missingFields = [];

    //gs shot type is required
    if (shot && isEmptyField(shot.shotTypeId, shotTypeOptions)) {
        missingFields.push(`${shotTitle} Type is required.`);
    }
    //gs stroke type is required
    if (shot && isEmptyField(shot.strokeTypeId, strokeTypeOptions)) {
        missingFields.push(`${shotTitle} Stroke Type is required.`);
    }
    //gs shot location is required
    if (shot && isEmptyField(shot.shotLocationId, shotLocationTypeOptions)) {
        missingFields.push(`${shotTitle} Location is required.`);
    }
    //gs vertical position is required
    if (shot && isEmptyField(shot.verticalPlayerPositionId, verticalPlayerPositionOptions)) {
        missingFields.push(`${shotTitle} Vertical Position is required.`);
    }
    //gs horizontal position is required
    if (shot && isEmptyField(shot.horizontalPlayerPositionId, horizontalPlayerPositionOptions)) {
        missingFields.push(`${shotTitle} Horizontal Position is required.`);
    }
    //gs shot movement is required
    if (shot && isEmptyField(shot.shotMovementTypeId, shotMovementTypeOptions)) {
        missingFields.push(`${shotTitle} Movement Type is required.`);
    }
    return missingFields;
}

function isEmptyField(value, options) {
    return value == null || value == undefined || value === '' || (options && options.length > 0 && options.findIndex((o) => o.value === value) === -1);
}

export {
    emptyShot,
    isEmptyField,
    addReturn,
    addServePlus,
    addSecondToLast,
    addLast,
    getServeMissingFields,
    getReturnMissingFields,
    getServePlusMissingFields,
    getSecondToLastMissingFields,
    getLastShotMissingFields
}