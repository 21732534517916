import React, { useEffect } from "react";
import MatchesList from "./MatchesList/MatchesList";
import PointsList from "./PointSelection/PointSelection";
import EditPoint from "./EditPoint/EditPoint";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import Spinner from "../../UI/Spinner";
import VideoPlayer from "./VideoPlayer/VideoPlayer";
import { useDispatch, useSelector } from "react-redux";
import {selectEditMode, setMatches } from "./store/editHESlice";
import { useGetAllCompleteMatchesQuery } from "../api/completeMatchesSlice";
import { selectIsAdmin, selectUser } from "../auth/authSlice";

export default function EditHEPage() {
    const dispatch = useDispatch();
    const isAdmin = useSelector(selectIsAdmin);
    const user = useSelector(selectUser);
    const editMode = useSelector(selectEditMode);
    const { data: matches, isLoading, error, isSuccess, status } = useGetAllCompleteMatchesQuery();

    
    if(!user) return <Spinner />;
    else if (!isAdmin) return <NotAuthorizedPage />;

  if (!isAdmin) return <NotAuthorizedPage />;

  if (isLoading) return <Spinner/>;

  if (error)
    return (
      <div>
        {status} {error.status} {JSON.stringify(error.error)}
      </div>
    );

  return (
    <div className="row">
      {!editMode && (
        <div className="col-lg-3 mb-3">
          <MatchesList matches={matches}/>
        </div>
      )}

      <div className="col-lg-6 mb-3">
        <VideoPlayer />
      </div>

      {editMode && (
        <div className="col-lg-3 mb-3">
          <EditPoint />
        </div>
      )}

      <div className="col-lg-3">
        <PointsList />
      </div>
    </div>
  );
}
