import { useEffect, useState } from "react";
import Spinner from "../../UI/Spinner";
import Select from "react-select";
import { useCreateUserTargetRecordMutation } from "../api/userSlice";

const NewTarget = ({ targetOptions }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [createRecord, { isLoading: loadingRecordCreation, isSuccess: createdSuccessfully, isError: creationError }] = useCreateUserTargetRecordMutation();

    useEffect(() => {
        toastr.options.positionClass = "toast-bottom-right";
        if (createdSuccessfully) {
            toastr.success("Created Successfully!", "Create New Record");
            $('#AddNewTargetModal').modal('hide');
        }
        else if (creationError)
            toastr.error("Failed to create!", "Create New Record");
    }, [createdSuccessfully, creationError]);

    useEffect(() => {
        setSelectedOption(null);
    }, [targetOptions]);

    return (
        <div
            className="modal fade"
            id="AddNewTargetModal"
            role="dialog"
            aria-labelledby="AddNewTargetModalLabel"
            aria-hidden="true"
        >
            <div style={{ width: "fit-content", minWidth: "60%" }} className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="AddNewTargetModalLabel">
                            Add New Target
                        </h3>
                        <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span className="font-weight-light" aria-hidden="true">
                                &times;
                            </span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container d-flex flex-wrap mb-4 justify-content-center">
                            {targetOptions ?
                                <div className="col-xl-3 col-md-6 mb-3">
                                    <h6 className="text-nowrap fs-1 text-center"> Target Players</h6>
                                    <Select
                                        closeMenuOnSelect={true}
                                        options={targetOptions}
                                        value={selectedOption ? targetOptions.find(option => option.value === selectedOption) : null}
                                        onChange={(selectedOption) => setSelectedOption(selectedOption.value)}
                                    />
                                </div>
                                : <Spinner />}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary btn-md" type="button" data-dismiss="modal">
                            Cancel
                        </button>
                        <button className="btn btn-warning btn-md" type="button"
                            onClick={() => createRecord(selectedOption)}
                            disabled={!selectedOption || (loadingRecordCreation)}>
                            {loadingRecordCreation ? "Creating..." : "Add Target"}
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
};

export default NewTarget;
