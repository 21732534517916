
import { useSelector } from "react-redux";
import { selectActivePoint } from "../store/editorSlice.js";

import EditServe from "../EditShot/EditServe.js";
import EditReturn from "../EditShot/EditReturn.js";
import * as constants from "../Utils/constants.js";

const EditServeAndReturn = () => {
    const activePoint = useSelector(selectActivePoint);

    const firstServe = activePoint.shots.find((s) => s.shotOrderId === constants.FIRST_SERVE_SHOT_ORDER_ID);
    const secondServe = activePoint.shots.find((s) => s.shotOrderId === constants.SECOND_SERVE_SHOT_ORDER_ID);
    const returnShot = activePoint.shots.find((s) => s.shotOrderId === constants.RETURN_SHOT_ORDER_ID);

    return (
        <>
            <div className="d-flex mb-1">
                {firstServe && <EditServe shot={firstServe} />}
                {secondServe && <EditServe shot={secondServe} />}
            </div>
            {
                returnShot &&
                <>
                    <hr className="w-75"></hr>
                    <EditReturn shot={returnShot} />
                </>
            }
        </>
    );
}

export default EditServeAndReturn;