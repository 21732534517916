import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useLoadCompletedMatchPointsQuery } from "../../api/completeMatchesSlice";
import {
  setCurrentlyPlayingMatch,
  setAutoPlay,
  setEditMode,
  setIsGlobalEditMode,
  selectSelectedMatch,
  selectCurrentlyPlayingMatch,
  selectEditMode,
  selectAutoPlay,
  selectIsGlobalEditMode,
} from "../store/editHESlice";
import { useUpdateHEPointMutation, useSetMatchInstanceStatusToReadyMutation } from "../../api/completeMatchesSlice";

import PointsList from "./PointsList";
import Spinner from "../../../UI/Spinner";

const PointSelection = (props) => {
  const selectedMatch = useSelector(selectSelectedMatch);
  const currentlyPlayingMatch = useSelector(selectCurrentlyPlayingMatch);
  const editMode = useSelector(selectEditMode);
  const autoPlay = useSelector(selectAutoPlay);
  const isGlobalEditMode = useSelector(selectIsGlobalEditMode);
  const dispatch = useDispatch();

  const { data, isLoading, error, status, isFetching } =
    useLoadCompletedMatchPointsQuery(
      selectedMatch ? [selectedMatch.matchInstanceId] : [123]
    );

  const [updateHEPoint, { isSuccess, isError }] = useUpdateHEPointMutation();
  const [setMatchInstanceStatusToReady, { isSuccess: matchReadySuccess, isError: matchReadyError }] = useSetMatchInstanceStatusToReadyMutation();

  useEffect(() => {
    if (data && selectedMatch) {
      let matchList = {
        ...selectedMatch,
        pointList: data[selectedMatch.matchInstanceId] ?? [],
      };
      dispatch(setCurrentlyPlayingMatch(matchList));
    }
  }, [data]);

  useEffect(() => {
    toastr.options.positionClass = "toast-bottom-right";
    if (isSuccess) toastr.success("Updated Successfully!", "Edit HE Match");
    else if (isError) toastr.error("Failed to update!", "Edit HE Match");
  }, [isSuccess, isError]);

  useEffect(() => {
    if (matchReadySuccess) {
      toastr.success("Match is ready!", "Edit HE Match");
    } else if (matchReadyError) {
      toastr.error("Failed to set match ready!", "Edit HE Match");
    }
  }, [matchReadySuccess, matchReadyError]);

  let content = null;
  if (isLoading || isFetching) {
    content = (
      <div
        style={{ height: "75vh" }}
        className="card mt-1 mb-2  align-items-center justify-content-center"
      >
        <Spinner />
      </div>
    );
  } else if (error) {
    content = (
      <div
        style={{ height: "75vh" }}
        className="card mt-1 mb-2  align-items-center justify-content-center"
      >
        <h4>
          {" "}
          {status} {error.status} {JSON.stringify(error.error)}
        </h4>
      </div>
    );
  } else if (!currentlyPlayingMatch) {
    content = (
      <div
        style={{ height: "75vh" }}
        className="card mt-1 mb-2  align-items-center justify-content-center"
      >
        <h4>No Match Is Selected</h4>
      </div>
    );
  } else {
    content = (
      <div className="card-body p-0 scrollbar perfect-scrollbar">
        <PointsList match={currentlyPlayingMatch} />
      </div>
    );
  }

  return (
    <div style={{ height: "75vh" }} className="card mt-1 mb-2 ">
      <div className="card-header pl-1">
        <h5 className="mb-3 ml-1">Points ({currentlyPlayingMatch ? currentlyPlayingMatch.pointList.length : 0})</h5>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="d-flex row flex-nowrap justify-content-between align-items-center mb-2 mx-1">
            <div className="col-auto custom-control custom-switch ">
              <input
                className="custom-control-input"
                id="edit-mode"
                type="checkbox"
                disabled={!currentlyPlayingMatch}
                checked={editMode}
                onChange={(e) => {
                  dispatch(setEditMode(e.target.checked));
                }}
              ></input>
              <label className="custom-control-label" htmlFor="edit-mode">
                Edit Mode
              </label>
            </div>
            {editMode && (
              <div className="col-auto custom-control custom-switch">
                <input
                  className="custom-control-input"
                  id="global-mode"
                  type="checkbox"
                  disabled={!currentlyPlayingMatch}
                  checked={isGlobalEditMode}
                  onChange={(e) => {
                    dispatch(setIsGlobalEditMode(e.target.checked));
                  }}
                ></input>
                <label className="custom-control-label" htmlFor="global-mode">
                  Global Edit
                </label>
              </div>
            )}
            {editMode && (
              <div className="col-auto custom-control custom-switch">
                <input
                  className="custom-control-input"
                  id="auto-play"
                  type="checkbox"
                  disabled={!currentlyPlayingMatch}
                  checked={autoPlay}
                  onChange={(e) => {
                    dispatch(setAutoPlay(e.target.checked));
                  }}
                ></input>
                <label className="custom-control-label" htmlFor="auto-play">
                  Auto Play
                </label>
              </div>
            )}
          </div>
          <div>
            {editMode && (
              <button
                className="btn btn-primary my-2 mr-2 fs--1"
                type="button"
                onClick={() => setMatchInstanceStatusToReady(currentlyPlayingMatch.matchInstanceId)}
              >
                Set Match Ready
              </button>
            )}
            {editMode && (
              <button
                className="btn btn-primary my-2 fs--1"
                type="button"
                onClick={() => updateHEPoint({ matchInstanceId: selectedMatch.matchInstanceId, points: currentlyPlayingMatch.pointList })}
              >
                Commit Match<i className="ml-1 fas fa-database"></i>
              </button>
            )}
          </div>
        </div>
      </div>
      {content}
    </div>
  );
};

export default PointSelection;
