

import { useEffect } from "react";
import { useRestartReportMutation } from "../../features/api/reportExecutionSlice";
import EllipseDropdownButton from "../../UI/Buttons/EllipseDropdownButton";
import DeleteButton from "../../UI/Buttons/DeleteButton";
import EditButton from "../../UI/Buttons/EditButton";
import RestartButton from "../../UI/Buttons/RestartButton";

const ReportEdits = ({ reportExecutionId, report, setSelectedReport, reportType }) => {

    const [restartReport, { isLoading, isSuccess, isError }] = useRestartReportMutation();

    const deleteHandler = () => {
        $(`#${reportType}-DeleteModal`).modal("show");
        setSelectedReport(report);
    };

    const restartHandler = () => {
        restartReport({ reportExecutionId, reportId: report.reportId, reportType });
    };

    const editHandler = () => {
        const target = reportType == "standard-report" ? `#AddNewStandardReportModal` : reportType == "he-report" ? `#AddNewHEReportModal` : `#AddNewAnalystCardReportModal`;
        $(target).modal("show");
        setSelectedReport(report);
    };

    useEffect(() => {
        if (isSuccess) {
            toastr.success("Report Restarted Successfully!", "Restart Report");
        }
        else if (isError)
            toastr.error("Failed to Restart!", "Restart Report");
    }, [isSuccess, isError]);

    return (
        <EllipseDropdownButton extraClasses="ml-3" extraStyles={{ width: "auto", height: "auto" }}>
            <span className="dropdown-item ">
                <DeleteButton
                    extraStyles={{ width: "100%", height: "100%" }}
                    extraClasses="btn-danger btn-sm ml-2"
                    onClick={deleteHandler}
                >
                    <span className="ml-2">Delete</span>
                </DeleteButton>
            </span>

            <span className="dropdown-item">
                <EditButton
                    extraStyles={{ width: "100%", height: "100%" }}
                    extraClasses="btn-sm ml-2"
                    onClick={editHandler}
                >
                    <span className="ml-2">Edit</span>
                </EditButton>
            </span>

            <span className="dropdown-item">
                <RestartButton
                    extraStyles={{ width: "100%", height: "100%" }}
                    extraClasses="btn-sm ml-2"
                    onClick={restartHandler}
                >
                    <span className="ml-2">Restart</span>
                </RestartButton>
            </span>
        </EllipseDropdownButton >
    );

}

export default ReportEdits;