import { useEffect, useState } from "react";
import Select from "react-select";
import Spinner from "../../UI/Spinner";

const SurfaceOptions = [
    { value: 0, label: "Clay" },
    { value: 1, label: "Grass" },
    { value: 2, label: "Carpet" },
    { value: 3, label: "Hard Court" },
]

const NewRecord = ({ title, onCreateRecord, playerOptions, isCreating }) => {
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [selectedSurface, setSelectedSurface] = useState(null);

    useEffect(() => {
        setSelectedPlayer(null);
    }, [playerOptions]);

    return (
        <div
            className="modal fade"
            id={`AddNewRecordModal${title}`}
            role="dialog"
            aria-labelledby={`AddNewRecordModal${title}Label`}
            aria-hidden="true"
        >
            <div style={{ width: "fit-content", minWidth: "60%" }} className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id={`AddNewRecordModal${title}Label`}>
                            Add New Record
                        </h3>
                        <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span className="font-weight-light" aria-hidden="true">
                                &times;
                            </span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container d-flex flex-wrap mb-4 justify-content-center">
                            {playerOptions ?
                                <div style={{ width: "100%" }} className="d-flex row flex-nowrap justify-content-center align-items-center m-0 p-0">
                                    <div className="col-xl-3 col-md-6">
                                        <h6 className="text-nowrap fs-1 text-center"> {title}</h6>
                                        <Select
                                            closeMenuOnSelect={true}
                                            options={playerOptions}
                                            value={selectedPlayer ? playerOptions.find(option => option.value === selectedPlayer) : null}
                                            onChange={(selectedOption) => setSelectedPlayer(selectedOption.value)}
                                        />
                                    </div>
                                    {title == "Opponents" &&
                                        <div className="d-flex flex-column ml-5">
                                            <h6 className="text-nowrap fs-1 text-center"> Surface</h6>
                                            <select className="form-control" onChange={(e) => setSelectedSurface(e.target.value)}>
                                                <option value="">Select Surface</option>
                                                {SurfaceOptions.map((surface) => (
                                                    <option key={surface.value} value={surface.value}>
                                                        {surface.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    }
                                </div>
                                : <Spinner />}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary btn-md" type="button" data-dismiss="modal">
                            Cancel
                        </button>
                        <button className="btn btn-warning btn-md" type="button"
                            onClick={() => onCreateRecord({ playerId: selectedPlayer, surfaceId: selectedSurface })}
                            disabled={!selectedPlayer || (isCreating) || (title == "Opponents" && !selectedSurface)}>
                            {isCreating ? "Creating..." : "Add Record"}
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
};

export default NewRecord;
