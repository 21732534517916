import { useState, useEffect } from "react";
import { useLazyGetDropboxPathQuery } from "../features/api/apiSlice";
import { useGetUserSettingsQuery } from "../features/api/userSlice";
import { dropboxOptions } from "../utils/app-utils";

const initialUserSettings = {
    defaultDropboxPath: '',
    uploadStdReportByDefault: false,
}

const useDropboxPath = ({isUserReport, container, setFormInputs }) => {
    const [getDropboxPath] = useLazyGetDropboxPathQuery();

    const { data: settings } = useGetUserSettingsQuery({ skip: !isUserReport });

    const [userSettings, setUserSettings] = useState(initialUserSettings);

    useEffect(() => {
        if (isUserReport && userSettings.uploadStdReportByDefault && !document.getElementById(container).hasChildNodes()) {
            setFormInputs((prevInputs) => ({ ...prevInputs, dropboxOutputDir: userSettings.defaultDropboxPath }));
            const button = Dropbox.createChooseButton({
                ...dropboxOptions,
                success: async function (files) {
                    const response = await getDropboxPath(files[0].id);
                    if (response.data) {
                        setFormInputs((prevInputs) => ({ ...prevInputs, dropboxOutputDir: response.data.path }));
                    }
                }
            });
            document.getElementById(container).append(button);
        } else if (!isUserReport || !userSettings.uploadStdReportByDefault) {
            setFormInputs((prevInputs) => ({ ...prevInputs, dropboxOutputDir: null }));
        }
    }, [userSettings]);

    useEffect(() => {
        if (settings) {
            setUserSettings(settings);
        }
    }, [settings])

    return { userSettings, setUserSettings };

}

export default useDropboxPath;