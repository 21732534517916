import { formatDetailedUtcDate } from "../../../utils/app-utils";
import ReportsActions from "../ReportsActions";
import ReportEdits from "../ReportEdits";
import ReportStatus from "../ReportStatus";
import ErrorModal from "../ErrorModal";
const AnalystCardReportRowItem = ({ reportExecutionId, report, setSelectedReport }) => {
    return (
        <div className="card" key={report.reportId}>
            <div style={{ marginLeft: 25 }} className="card-body">
                <div className="row">
                    <div className="col-11">
                        <h4 className="card-title fs-0 d-flex row text-nowrap flex-nowrap">
                            {report.resources?.length > 0
                                ? report.resources[0].name.split('.')[0]
                                : "-"}
                            <ReportEdits reportExecutionId={reportExecutionId} report={report} setSelectedReport={setSelectedReport} reportType="analyst-report" />
                            {report.resources.length > 0 && <ReportsActions
                                reportBlobPath={report.resources[0].path}
                            />}
                        </h4>
                    </div>

                    <div>
                        <div className="card-text d-flex row align-items-center">
                            {report.jobStatus == "Failed" && <ErrorModal report={report} />}
                            <ReportStatus status={report.jobStatus} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <p className="card-text">Created By : {report.createdBy}</p>
                    </div>
                    <div className="col-6">
                        <p className="card-text">
                            {formatDetailedUtcDate(report.createdAt)}
                        </p>
                    </div>
                </div>
                <div
                    className="row"
                    style={{
                        justifyContent: "center",
                        marginTop: "15px",
                        fontWeight: "bold",
                        gap: "25px",
                        alignItems: "center"
                    }}
                >
                </div>
            </div>
        </div>
    );
};

export default AnalystCardReportRowItem;
