
import { useEffect, useState } from "react";
const TournamentsList = ({ tournaments, selectedTournamentLookupId, setSelectedTournamentLookupId: setSelectedTournamentLookupId }) => {

    const [filteredTournaments, setFilteredTournaments] = useState(tournaments);
    const [search, setSearch] = useState("");

    const filterTournaments = (e) => {
        const search = e.target.value;
        if (search === "") setFilteredTournaments(tournaments);
        else setFilteredTournaments(tournaments.filter(tournament => (`${tournament.source} - ${tournament.sourceName}`).toLowerCase().includes(search.toLowerCase())));
        setSearch(search);
    }

    useEffect(() => {
        if (search === "") setFilteredTournaments(tournaments);
        else setFilteredTournaments(tournaments.filter(tournament => (`${tournament.source} - ${tournament.sourceName}`).toLowerCase().includes(search.toLowerCase())));
    }, [tournaments]);

    return (
        <div className="col-4 mb-3">
            <div className="card mt-1 min-vh-75 max-vh-75">
                <div className="card-header d-flex flex-column align-items-center">
                    <h3>Tournaments ({filteredTournaments.length})</h3>
                    <div className="col-12 mt-1">
                        <input type="text" className="form-control" placeholder="Search by name" onChange={filterTournaments} />
                    </div>
                </div>
                <div className="card-body p-0 scrollbar perfect-scrollbar">
                    <div className="list-group">
                        {filteredTournaments.map(tournament =>
                            <div key={tournament.tournamentLookupId}
                                style={{ cursor: "pointer" }}
                                className={`d-flex row flex-nowrap list-group-item list-group-item-action align-items-center ml-0 ${tournament.tournamentLookupId === selectedTournamentLookupId ? "bg-500 text-white" : ""}`}
                                onClick={() => setSelectedTournamentLookupId(tournament.tournamentLookupId)} >
                                <span className="text-nowrap">{tournament.sourceName}</span>
                                <div className="badge badge-warning rounded-capsule ml-2">
                                    {tournament.source}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TournamentsList;