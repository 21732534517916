function Switch({ field, value, onChanged }) {

  return (
    <div className="d-inline custom-control custom-switch">
      <input className="custom-control-input" id={field} type="checkbox" checked={value} onChange={(e) => onChanged(field, e.target.checked)}></input>
      <label className="custom-control-label" htmlFor={field}>

      </label>
    </div>
  );
}

export default Switch;
