import classes from "./buttons.module.css";

const EllipseDropdownButton = ({ children, extraClasses = '', extraStyles = {} }) => {

    return (
        <div
            style={{ width: "0.8rem", height: "1.7rem", ...extraStyles }}
            className={`mr-1 ${extraClasses}`}>
            <button className={`btn btm-sm dropdown-toggle ${classes["action-button"]} ${classes["action-button-enabled"]}`} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                <i className="fas fa-ellipsis-v"></i>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {children}
            </div>
        </div>);
}

export default EllipseDropdownButton;