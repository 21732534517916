import React from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedEvent } from '../DrawsReportsSlice';
import { formatDetailedUtcDate } from "../../../utils/app-utils";

const EventDetailsCard = ({ selectedEvent }) => {
  return (
    <div
      className="collapse show text-nowrap"
      id="collapse-event-details"
      aria-labelledby="collapse-event-details"
    >
      <div
        className="card"
        style={{ width: "100vh", marginBottom: "20px", overflow: 'hidden' }}
      >
        <div className="card-body">
          <div className="row">
            <div className="col-10">
              <h4 className="card-title ">
                {selectedEvent.eventName ? selectedEvent.eventName : "-"}
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              Category : {selectedEvent.category}
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              Start Date :{" "}
              {formatDetailedUtcDate(selectedEvent.startDate)}
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              End Date : {formatDetailedUtcDate(selectedEvent.endDate)}
            </div>
          </div>
          <div className="row">
            <div className="col-2">Surface : {selectedEvent.surface}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetailsCard;