import React from 'react';

import DownloadButton from '../../UI/Buttons/DownloadButton';
import CopyButton from '../../UI/Buttons/CopyButton';

const ReportsActions = ({ reportBlobPath }) => {

    const downloadLink = `${process.env.REACT_APP_API_URL}/reportExecutionRecord/download?blobPath=${reportBlobPath}`;

    const handleDownload = () => {
        window.open(downloadLink, "_blank", "noopener,noreferrer");
    };

    return (
        <div className='d-flex'>
            <DownloadButton onClick={handleDownload} extraClasses='mb-1' />
            <CopyButton text={downloadLink} extraClasses='mb-1' />
        </div>
    );
};

export default ReportsActions;