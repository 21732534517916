import React, { useEffect, useState } from "react";

const MatchCard = ({ matchData, parent, isClientWinner,isBothClients }) => {
  const [matchScore, setMatchScore] = useState({
    player1: null,
    player2: null,
  });
  useEffect(() => {
    let score =
      matchData.score &&
      matchData.score.split(" ").map((part) => parseInt(part));

    let newScore = matchScore;
    if (!score) {
      newScore = { ...newScore, player1: "-", player2: "-" };
    } else {
      score.map((set) => {
        var digits = set.toString().split("");
        newScore = {
          ...newScore,
          player1: newScore.player1
            ? `${newScore.player1} / ${digits[0]}`
            : digits[0],
          player2: newScore.player2
            ? `${newScore.player2} / ${digits[1]}`
            : digits[1],
        };
      });
    }
    setMatchScore(newScore);
  }, []);

  const formatName = (fullName) => {
    if (!fullName) {
      return "";
    }
    const names = fullName.trim().split(/\s+/);
    if (names.length < 3) return fullName;

    const firstInitial = names[0].charAt(0);
    names.pop(names[0]);
    return (firstInitial + ". " + names.join(" ")).toUpperCase();
  };

  //add text-black bg-warning if parent is client
  let className = parent === "client" ? "card col-3 text-nowrap text-black bg-warning" : "card col-3 text-nowrap";
  if (isClientWinner !== undefined && matchData.winner !== null && matchData.winner !== '') {
    className = isClientWinner ? "card col-3 text-nowrap text-black bg-success" : "card col-3 text-nowrap text-black bg-danger";
  }

  if(isBothClients){
    className = "card col-3 text-nowrap text-black bg-info";
  }

  return (
    <div className={className} 
    style={{ margin: "10px", minWidth: "fit-content" }}>
      <div className="card-body">
        <div className="row">
          <div className="col-12 mr-2">
            {parent === "client" && (
              <div
                className="d-flex justify-content-between"
                style={{ gap: "20px" }}
              >
                <div>Round : </div>
                <div>{matchData.round}</div>
              </div>
            )}
            <div
              className="d-flex justify-content-between"
              style={{ gap: "20px" }}
            >
              <div>{formatName(matchData.player1)} : </div>
              <div>
                {matchData.winner === matchData.player1 ? (
                  <div>
                    <i
                      className="fas fa-baseball-ball"
                      style={{ color: "yellow" }}
                    ></i>{" "}
                    {matchScore.player1}{" "}
                  </div>
                ) : (
                  matchScore.player1
                )}{" "}
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div>{formatName(matchData.player2)} : </div>
              <div>
                {matchData.winner === matchData.player2 ? (
                  <div>
                    <i
                      className="fas fa-baseball-ball"
                      style={{ color: "yellow" }}
                    ></i>{" "}
                    {matchScore.player2}{" "}
                  </div>
                ) : (
                  matchScore.player2
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchCard;
