import React, { useRef, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCurrentlyPlayingMatch,
  selectCurrentlyPlayingShot,
  selectIsPlaying,
  setIsPlaying,
  playNextShot,
  selectReplay,
  setReplay,
} from "../store/editHESlice";

const VideoPlayer = () => {
  const dispatch = useDispatch();
  const currentlyPlayingMatch = useSelector(selectCurrentlyPlayingMatch);
  const currentlyPlayingShot = useSelector(selectCurrentlyPlayingShot);
  const isPlaying = useSelector(selectIsPlaying);
  const videoPlayerRef = useRef();
  const replay = useSelector(selectReplay);
  const [playingShot, setPlayingShot] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (replay) {
      videoPlayerRef.current.seekTo(currentlyPlayingShot.startTime);
      dispatch(setReplay(false));
    }
  }, [replay]);

  useEffect(() => {
    if (isReady && currentlyPlayingShot) {
      videoPlayerRef.current.seekTo(currentlyPlayingShot.startTime);
    }
  }, [isReady]);

  useEffect(() => {
    if (!currentlyPlayingShot) {
      setPlayingShot(currentlyPlayingShot);
      return;
    }
    const isNextShot =
      playingShot &&
      playingShot.pointId === currentlyPlayingShot.pointId &&
      (currentlyPlayingShot.shotNumber === playingShot.shotNumber ||
        currentlyPlayingShot.shotNumber === playingShot.shotNumber + 1);
    if (!isNextShot) {
      videoPlayerRef.current.seekTo(currentlyPlayingShot.startTime);
    }
    setPlayingShot(currentlyPlayingShot);
  }, [currentlyPlayingShot]);

  const HandlePlay = () => {
    dispatch(setIsPlaying(true));
  };

  const HandlePause = () => {
    dispatch(setIsPlaying(false));
  };

  const HandleProgress = (currentTime) => {
    if (!isPlaying || !currentlyPlayingShot) {
      return;
    }
    if (currentTime > currentlyPlayingShot.endTime) {
      dispatch(playNextShot());
    }
  };
  let config = { file: { attributes: { controlsList: "nodownload" } } };

  if (!currentlyPlayingMatch) {
    if (isReady) setIsReady(false);
    return (
      <div className="card vh-50 mb-3 align-items-center justify-content-center">
        <h4>No Video Is Selected</h4>
      </div>
    );
  }
  return (
    <div className="card vh-50 mb-3">
      <div className="card-header">
        <div>
          <h5 className="mb-0 mt-2 d-flex align-items-center justify-content-center">
            {`${currentlyPlayingMatch.firstPlayerName} vs ${currentlyPlayingMatch.secondPlayerName} - ${currentlyPlayingMatch.eventName}`}
          </h5>
        </div>
      </div>
      <div style={{ padding: 0 }} className="card-body">
        <div style={wrapperStyle}>
          <ReactPlayer
            ref={videoPlayerRef}
            config={config}
            playing={isPlaying}
            onPlay={() => HandlePlay()}
            onPause={() => HandlePause()}
            onProgress={(e) => HandleProgress(e.playedSeconds)}
            onReady={() => setIsReady(true)}
            controls={true}
            url={currentlyPlayingMatch.fullMatchVideoUrl}
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;

const wrapperStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
};
