import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";


export const matchInstanceApiSlice = createApi({
    reducerPath: "matchinstancesApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/match`,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        updateMatchInstanceStatus: builder.mutation({
            query: ({ matchId, matchInstanceId, statusId }) => ({
                url: `${matchId}/matchinstance/${matchInstanceId}/${statusId}`,
                method: "PUT",
            }),
        })
    })
});

export const { useUpdateMatchInstanceStatusMutation } = matchInstanceApiSlice;
