import { convertTimeStringToSeconds } from '../../../utils/app-utils';
import { serviceCourtOptions } from './options';
import { addServePlus, addSecondToLast, addLast, isEmptyField, getServeMissingFields, getReturnMissingFields, getServePlusMissingFields, getSecondToLastMissingFields, getLastShotMissingFields } from './shotUtils';
import * as constants from './constants';

const deuceCourtScores = ["0000", "1515", "3000", "0030", "3030", "1540", "4015", "4040"];
const pressureScoresAd = ["0030", "0040", "1530", "1540", "3030", "3040", "4040", "40AD"];
const pressureScoresNoAd = ["0030", "0040", "1530", "1540", "3030", "3040", "4030", "4040"];
const breakScoresAd = ["0040", "1540", "3040", "40AD"];
const breakScoresNoAd = ["0040", "1540", "3040", "4040"];

function transformMatchPoint(point) {
    point.startTime = convertTimeStringToSeconds(point.startTime, true);
    point.endTime = convertTimeStringToSeconds(point.endTime, false);
    return point;
}

function getPointMissingFields(point, match) {

    const firstServeShot = point.shots.find((s) => s.shotOrderId === constants.FIRST_SERVE_SHOT_ORDER_ID);
    const secondServeShot = point.shots.find((s) => s.shotOrderId === constants.SECOND_SERVE_SHOT_ORDER_ID);
    const returnShot = point.shots.find((s) => s.shotOrderId === constants.RETURN_SHOT_ORDER_ID);
    const servePlusShot = point.shots.find((s) => s.shotOrderId === constants.SERVE_PLUS_ONE_SHOT_ORDER_ID);
    const secondToLastShot = point.shots.find((s) => s.shotOrderId === constants.SECOND_TO_LAST_SHOT_ORDER_ID);
    const lastShot = point.shots.find((s) => s.shotOrderId === constants.LAST_SHOT_ORDER_ID);

    let missingFields = [];

    //start time is required
    if (isEmptyField(point.startTime)) {
        missingFields.push('Start Time is required.');
    }
    //end time is required
    if (isEmptyField(point.endTime)) {
        missingFields.push('End Time is required.');
    }
    //score is required
    if (isEmptyField(point.score)) {
        missingFields.push('Score is required.');
    }
    //server is required
    if (isEmptyField(point.server)) {
        missingFields.push('Server is required.');
    }
    //service court is required
    if (isEmptyField(point.serviceCourt, serviceCourtOptions)) {
        missingFields.push('Service Court is required.');
    }
    //winner is required
    if (isEmptyField(point.winner)) {
        missingFields.push('Winner is required.');
    }
    missingFields = missingFields.concat(getServeMissingFields(firstServeShot, secondServeShot));
    missingFields = missingFields.concat(getReturnMissingFields(point, returnShot));
    if (match.typeOfCoding == "GS") {
        missingFields = missingFields.concat(getServePlusMissingFields(point, servePlusShot));
        missingFields = missingFields.concat(getSecondToLastMissingFields(secondToLastShot, 'Second To Last'));
        missingFields = missingFields.concat(getLastShotMissingFields(point, lastShot, 'Last'));
    }

    return missingFields;
};

function getPointErrors(point) {
    let errors = [];

    //point start time less than endTime
    if (point.startTime >= point.endTime) {
        errors.push('Start Time should be less than End Time.');
    }
    //Rally length equal to 0 and not double fault
    if (point.serveTypeId !== constants.DOUBLE_FAULT && point.rallyLength == 0) {
        errors.push('Rally Length is 0 and point is not double fault.');
    }
    //rally length is 1 and not ace
    if (!point.isAce && point.rallyLength === 1) {
        errors.push('Rally Length is 1 and point is not ace.');
    }
    //ace and rally length not equal to 1
    if (point.isAce && point.rallyLength != 1) {
        errors.push('Ace Point. Rally Length should be 1.');
    }
    //double fault and rally length not equal to 0
    if (point.serveTypeId === constants.DOUBLE_FAULT && point.rallyLength != 0) {
        errors.push('Double Fault Point. Rally Length should be 0.');
    }
    //ace and winner is returner
    if (point.isAce && point.winner === point.returner) {
        errors.push('Ace Point. Winner cannot be Returner.');
    }
    //double fault and winner is server
    if (point.serveTypeId === constants.DOUBLE_FAULT && point.winner === point.server) {
        errors.push('Double Fault Point. Winner cannot be Server.');
    }
    if (point.needsReview && point.reviewReason && point.reviewReason.length > 0) {
        errors.push(point.reviewReason);
    }
    return errors;
}

const getPointProgress = (point, match) => {
    const pointErrorsRulesCount = 8;
    const pointMissingFieldsRulesCount = 6;
    const serveMissingFieldsRulesCount = 2;
    const returnMissingFieldsRulesCount = 1;
    const servePlusMissingFieldsRulesCount = 1;
    const secondToLastMissingFieldsRulesCount = 6;
    const lastMissingFieldsRulesCount = 7;

    const rulesCount = pointErrorsRulesCount + pointMissingFieldsRulesCount + serveMissingFieldsRulesCount + returnMissingFieldsRulesCount + servePlusMissingFieldsRulesCount + secondToLastMissingFieldsRulesCount + lastMissingFieldsRulesCount;
    const pointErrors = getPointErrors(point);
    const missingFields = getPointMissingFields(point, match);
    const progress = Math.round((rulesCount - pointErrors.length - missingFields.length) / rulesCount * 100);
    return progress;
}

const getMatchProgress = (points, match) => {
    var totalProgress = points.reduce((acc, point) => acc + getPointProgress(point, match), 0);
    return totalProgress / points.length;
}
/**
 * 0	Winner
 * 1	Forcing error
 * 2	Unforced Error
 */
const rallyEndingChanged = (newPoint) => {
    const updatedPoint = { ...newPoint };
    if (updatedPoint.rallyLength === 2 && updatedPoint.rallyEndingShotTypeId === 1) {
        updatedPoint.shots = updatedPoint.shots.filter((s) => s.shotOrderId <= constants.RETURN_SHOT_ORDER_ID);
        // updatedPoint.rallyLength = 3;
    }
    else if (updatedPoint.rallyLength === 3 && updatedPoint.rallyEndingShotTypeId === 1) {
        updatedPoint.shots = updatedPoint.shots.filter((s) => s.shotOrderId <= constants.RETURN_SHOT_ORDER_ID);
    } else if (updatedPoint.rallyLength === 3 && updatedPoint.rallyEndingShotTypeId !== 1) {
        updatedPoint.shots = updatedPoint.shots.filter((s) => s.shotOrderId <= constants.SERVE_PLUS_ONE_SHOT_ORDER_ID);
        if (!updatedPoint.shots.find((s) => s.shotOrderId === constants.SERVE_PLUS_ONE_SHOT_ORDER_ID)) {
            addServePlus(updatedPoint);
        }
    }
    else if (updatedPoint.rallyLength === 4 && updatedPoint.rallyEndingShotTypeId === 1) {
        updatedPoint.shots = updatedPoint.shots.filter((s) => s.shotOrderId <= constants.SERVE_PLUS_ONE_SHOT_ORDER_ID);
    }
    else if (updatedPoint.rallyLength === 4 && updatedPoint.rallyEndingShotTypeId !== 1) {
        // if last shot not present add it by checking shotOrderId
        if (!updatedPoint.shots.find((s) => s.shotOrderId === constants.LAST_SHOT_ORDER_ID)) {
            addLast(updatedPoint);
        }
    }
    else if (updatedPoint.rallyLength === 5 && updatedPoint.rallyEndingShotTypeId === 1) {
        //remove 2nd to last shot
        updatedPoint.shots = updatedPoint.shots.filter((s) => s.shotOrderId !== constants.SECOND_TO_LAST_SHOT_ORDER_ID);
        updatedPoint.shots = updatedPoint.shots.filter((s) => s.shotOrderId <= constants.LAST_SHOT_ORDER_ID);
    }
    else if (updatedPoint.rallyLength === 5 && updatedPoint.rallyEndingShotTypeId != 1) {
        // if 2nd to last shot not present add it by checking shotOrderId
        if (!updatedPoint.shots.find((s) => s.shotOrderId === constants.SECOND_TO_LAST_SHOT_ORDER_ID)) {
            addSecondToLast(updatedPoint);
        }
        // if last shot not present add it by checking shotOrderId
        if (!updatedPoint.shots.find((s) => s.shotOrderId === constants.LAST_SHOT_ORDER_ID)) {
            addLast(updatedPoint);
        }
    }
    return updatedPoint;
}

const rallyLengthChanged = (newPoint) => {
    const updatedPoint = { ...newPoint };
    if (updatedPoint.rallyLength > 2) {
        addServePlus(updatedPoint);
    } else {
        updatedPoint.shots = updatedPoint.shots.filter((s) => s.shotOrderId <= constants.RETURN_SHOT_ORDER_ID);
        return rallyEndingChanged(updatedPoint);
    }

    if (updatedPoint.rallyLength == 4) {
        addLast(updatedPoint);
        updatedPoint.shots = updatedPoint.shots.filter((s) => s.shotOrderId != constants.SECOND_TO_LAST_SHOT_ORDER_ID);
    }
    else if (updatedPoint.rallyLength > 4) {
        addSecondToLast(updatedPoint);
        addLast(updatedPoint);
    } else {
        updatedPoint.shots = updatedPoint.shots.filter((s) => s.shotOrderId <= constants.SERVE_PLUS_ONE_SHOT_ORDER_ID);
    }

    return rallyEndingChanged(updatedPoint);
}

const updatePointScore = (point, updatedScore, match) => {
    const updatedPoint = { ...point };
    const [matchScore, setScore, gameScore] = updatedScore.split(' ');

    // Update match, set, and game scores
    const [matchScore1, matchScore2] = matchScore.split('-');
    const [setScore1, setScore2] = setScore.split('-');
    const playersScores = gameScore.split('-');

    updatedPoint.matchScore1 = Number(matchScore1);
    updatedPoint.matchScore2 = Number(matchScore2);
    updatedPoint.matchScore = matchScore;
    updatedPoint.setScore1 = Number(setScore1);
    updatedPoint.setScore2 = Number(setScore2);
    updatedPoint.setScore = setScore;
    updatedPoint.gameScore = gameScore.replace('-', '');
    updatedPoint.score = updatedScore;

    // Determine the server's score
    const isTiebreakGame = isTiebreak(matchScore, setScore, match.matchFormat);
    const serverFirstScore = point.server === match.player1 ? `${playersScores[0]}${playersScores[1]}` : `${playersScores[1]}${playersScores[0]}`;;
    const isAdvantage = match.typeOfScoring === constants.AD_SCORING;

    // Update service court (Ad or Deuce)
    const isTiebreakDeuce = isTiebreakGame && (Number(playersScores[0]) + Number(playersScores[1])) % 2 === 0;
    updatedPoint.serviceCourt = deuceCourtScores.includes(serverFirstScore) || isTiebreakDeuce ? constants.DEUCE_SERVICE_COURT : constants.AD_SERVICE_COURT;

    // Update pressure point flag
    const pressureScores = isAdvantage ? pressureScoresAd : pressureScoresNoAd;
    updatedPoint.isPressurePoint = pressureScores.includes(serverFirstScore.toUpperCase()) || isTiebreakGame;

    // Update break point flag
    const breakScores = isAdvantage ? breakScoresAd : breakScoresNoAd;
    updatedPoint.isBreakPoint = !isTiebreakGame && breakScores.includes(serverFirstScore.toUpperCase());

    return updatedPoint;
}

const isTiebreak = (matchScore, setScore, matchFormat) => {
    switch (matchFormat) {
        case constants.SINGLE_SET:
        case constants.BEST_OF_THREE_TIEBREAK_SETS:
        case constants.BEST_OF_FIVE_TIEBREAK_SETS:
            return setScore === "6-6";
        case constants.BEST_OF_THREE_TEN_POINT_FINAL_SET_TIEBREAK:
            return setScore === "6-6" || matchScore === "1-1"
        case constants.BEST_OF_FIVE_TEN_POINT_FINAL_SET_TIEBREAK:
            return setScore === "6-6" || matchScore === "2-2"
        case constants.BEST_OF_THREE_FAST_FOUR_SETS:
            return setScore === "3-3" || matchScore === "1-1"
        case constants.EIGHT_GAME_PRO_SET:
            return setScore === "7-7"
    }
}

export {
    transformMatchPoint,
    updatePointScore,
    getPointErrors,
    getPointMissingFields,
    getPointProgress,
    getMatchProgress,
    rallyLengthChanged,
    rallyEndingChanged
}