const parseMatchStatistics = (statistics) => {

    const player1ServeStats = statistics?.player1Stats.serviceStats;
    const player2ServeStats = statistics?.player2Stats.serviceStats;

    const player1ReturnStats = statistics?.player1Stats.returnStats;
    const player2ReturnStats = statistics?.player2Stats.returnStats;

    const player1PointStats = statistics?.player1Stats.pointStats;
    const player2PointStats = statistics?.player2Stats.pointStats;

    const serviceStats = [
        {
            p1Val1: player1ServeStats?.aces,
            p2Val1: player2ServeStats?.aces,
            title: "Aces",
        },
        {
            p1Val1: player1ServeStats?.doubleFaults,
            p2Val1: player2ServeStats?.doubleFaults,
            title: "Double Faults",
        },
        {
            p1Val1: `${player1ServeStats?.firstServe.percentage}%`,
            p1Val2: `(${player1ServeStats?.firstServe.numerator}/${player1ServeStats?.firstServe.denominator})`,
            p2Val1: `${player2ServeStats?.firstServe.percentage}%`,
            p2Val2: `(${player2ServeStats?.firstServe.numerator}/${player2ServeStats?.firstServe.denominator})`,
            title: "1st Serve",
        },
        {
            p1Val1: `${player1ServeStats?.firstServePointsWon.percentage}%`,
            p1Val2: `(${player1ServeStats?.firstServePointsWon.numerator}/${player1ServeStats?.firstServePointsWon.denominator})`,
            p2Val1: `${player2ServeStats?.firstServePointsWon.percentage}%`,
            p2Val2: `(${player2ServeStats?.firstServePointsWon.numerator}/${player2ServeStats?.firstServePointsWon.denominator})`,
            title: "1st Serve Points Won",
        },
        {
            p1Val1: `${player1ServeStats?.secondServePointsWon.percentage}%`,
            p1Val2: `(${player1ServeStats?.secondServePointsWon.numerator}/${player1ServeStats?.secondServePointsWon.denominator})`,
            p2Val1: `${player2ServeStats?.secondServePointsWon.percentage}%`,
            p2Val2: `(${player2ServeStats?.secondServePointsWon.numerator}/${player2ServeStats?.secondServePointsWon.denominator})`,
            title: "2nd Serve Points Won",
        },
        {
            p1Val1: `${player1ServeStats?.breakPointsSaved.percentage}%`,
            p1Val2: `(${player1ServeStats?.breakPointsSaved.numerator}/${player1ServeStats?.breakPointsSaved.denominator})`,
            p2Val1: `${player2ServeStats?.breakPointsSaved.percentage}%`,
            p2Val2: `(${player2ServeStats?.breakPointsSaved.numerator}/${player2ServeStats?.breakPointsSaved.denominator})`,
            title: "Break Points Saved",
        },
    ];

    const returnStats = [
        {
            p1Val1: `${player1ReturnStats?.firstServeReturnPointsWon.percentage}%`,
            p1Val2: `(${player1ReturnStats?.firstServeReturnPointsWon.numerator}/${player1ReturnStats?.firstServeReturnPointsWon.denominator})`,
            p2Val1: `${player2ReturnStats?.firstServeReturnPointsWon.percentage}%`,
            p2Val2: `(${player2ReturnStats?.firstServeReturnPointsWon.numerator}/${player2ReturnStats?.firstServeReturnPointsWon.denominator})`,
            title: "1st Serve Return Points Won",
        },
        {
            p1Val1: `${player1ReturnStats?.secondServeReturnPointsWon.percentage}%`,
            p1Val2: `(${player1ReturnStats?.secondServeReturnPointsWon.numerator}/${player1ReturnStats?.secondServeReturnPointsWon.denominator})`,
            p2Val1: `${player2ReturnStats?.secondServeReturnPointsWon.percentage}%`,
            p2Val2: `(${player2ReturnStats?.secondServeReturnPointsWon.numerator}/${player2ReturnStats?.secondServeReturnPointsWon.denominator})`,
            title: "2nd Serve Return Points Won",
        },
        {
            p1Val1: `${player1ReturnStats?.breakPointsConverted.percentage}%`,
            p1Val2: `(${player1ReturnStats?.breakPointsConverted.numerator}/${player1ReturnStats?.breakPointsConverted.denominator})`,
            p2Val1: `${player2ReturnStats?.breakPointsConverted.percentage}%`,
            p2Val2: `(${player2ReturnStats?.breakPointsConverted.numerator}/${player2ReturnStats?.breakPointsConverted.denominator})`,
            title: "Break Points Converted",
        },
    ];

    const pointStats = [
        {
            p1Val1: `${player1PointStats?.servicePointsWon.percentage}%`,
            p1Val2: `(${player1PointStats?.servicePointsWon.numerator}/${player1PointStats?.servicePointsWon.denominator})`,
            p2Val1: `${player2PointStats?.servicePointsWon.percentage}%`,
            p2Val2: `(${player2PointStats?.servicePointsWon.numerator}/${player2PointStats?.servicePointsWon.denominator})`,
            title: "Service Points Won",
        },
        {
            p1Val1: `${player1PointStats?.returnPointsWon.percentage}%`,
            p1Val2: `(${player1PointStats?.returnPointsWon.numerator}/${player1PointStats?.returnPointsWon.denominator})`,
            p2Val1: `${player2PointStats?.returnPointsWon.percentage}%`,
            p2Val2: `(${player2PointStats?.returnPointsWon.numerator}/${player2PointStats?.returnPointsWon.denominator})`,
            title: "Return Points Won",
        },
        {
            p1Val1: `${player1PointStats?.totalPointsWon.percentage}%`,
            p1Val2: `(${player1PointStats?.totalPointsWon.numerator}/${player1PointStats?.totalPointsWon.denominator})`,
            p2Val1: `${player2PointStats?.totalPointsWon.percentage}%`,
            p2Val2: `(${player2PointStats?.totalPointsWon.numerator}/${player2PointStats?.totalPointsWon.denominator})`,
            title: "Total Points Won",
        },
    ];

    return {
        serviceStats,
        returnStats,
        pointStats
    };
}

export default parseMatchStatistics;