import React from "react";
import { useDeletePlayerMutation } from "../api/playerGroupsSlice";

const DeletePlayerGroup = ({ playerGroupId,setSelectedPlayerGroup }) => {
  const [deletePlayerGroup, { isLoading: deleting }] = useDeletePlayerMutation();

  const handleDeleteConfirmation = async () => {
    try {
      await deletePlayerGroup({ playerGroupId }).unwrap();
      $("#deleteConfirmationModal").modal("hide");

      toastr.success("Deleted Successfully!", "");
      setSelectedPlayerGroup(null);
    } catch (error) {
      toastr.error("Failed to delete!", "Delete Record");
    }
  };

  return (
    <div>
      <button
        data-toggle="modal"
        data-target="#deleteConfirmationModal"
        className="btn btn-danger rounded-capsule ml-auto"
        type="button"
      >
        Delete Player Group
      </button>

      <div
        className="modal fade"
        id="deleteConfirmationModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteConfirmationModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteConfirmationModalLabel">Confirm Deletion</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this player group?
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                type="button"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                className="btn btn-danger"
                type="button"
                onClick={handleDeleteConfirmation}
                disabled={deleting}
              >

                {deleting ? "Deleting..." : "Delete "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePlayerGroup;
