import { useDispatch, useSelector } from "react-redux";
import { updateMatch, selectMatch } from "./store/editorSlice";

const SwitchServer = () => {
    const dispatch = useDispatch();
    const match = useSelector(selectMatch);

    const switchServerClickHandler = () => {
        const updatedMatch = { ...match };
        updatedMatch.points = updatedMatch.points.map((point) => {
            const server = point.server == updatedMatch.player1 ? updatedMatch.player2 : updatedMatch.player1;
            const returner = server == updatedMatch.player1 ? updatedMatch.player2 : updatedMatch.player1;
            return { ...point, server: server, returner: returner };
        });
        dispatch(updateMatch(updatedMatch));
    };

    return (
        <button className="btn btn-sm btn-warning mr-2" onClick={switchServerClickHandler} >Switch Server</button>
    );
}

export default SwitchServer;