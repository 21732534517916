import { useState } from "react";
import { useSelector } from "react-redux";
import { selectUser, selectIsAdmin, selectIsCV } from "../auth/authSlice";
import Spinner from "../../UI/Spinner";
import VideoListItem from "./VideoListItem";
import { useLazyGetSearchVideosQuery } from "../api/collectedVideosSlice";

const VideosSearchPage = () => {
    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);
    const isCV = useSelector(selectIsCV);

    const [getSearchVideos, { isLoading, isFetching }] = useLazyGetSearchVideosQuery();
    const [timeoutHandler, setTimeoutHandler] = useState(null);
    const [videos, setVideos] = useState([]);

    const searchTextChangedHandler = async (searchText) => {
        clearTimeout(timeoutHandler);
        setTimeoutHandler(setTimeout(async () => {
            if (searchText.length < 3) {
                setVideos([]);
                return;
            }
            const response = await getSearchVideos(searchText);
            if (response.data) {
                setVideos(response.data);
            }
        }, 500));
    }

    if (!user) return <Spinner />;
    else if (!isAdmin && !isCV) return <NotAuthorizedPage />;

    return (
        <div className="col-8 m-auto">
            <div className="card mt-1 min-vh-75 max-vh-75">
                <div className="card-header">
                    <div className="row justify-content-center">
                        <h4>Videos ({videos ? videos.length : 0})</h4>
                        <div className="col-12 mt-1">
                            <input type="text" className="form-control" placeholder="Search by name" onChange={(x) => searchTextChangedHandler(x.target.value)} />
                        </div>
                    </div>
                </div>
                {(isLoading || isFetching) && <Spinner />}
                {!isLoading && !isFetching && <div className="card-body p-0 scrollbar perfect-scrollbar">
                    <div className="list-group">
                        {videos.map(video => <VideoListItem key={video.id} video={video} />
                        )}
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default VideosSearchPage;