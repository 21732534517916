import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    clients: [],
    selectedClient: null,
    filteredClients: []
};


const clientsSlice = createSlice({
    name: "clients",
    initialState,
    reducers: {
        setClients(state, action) {
            state.clients = action.payload;
            state.filteredClients = action.payload;
        },
        setSelectedClient(state, action) {
            state.selectedClient = action.payload;
        },
        reset(state, action) {
            state.clients = [];
            state.filteredClients = [];
            state.selectedClient = null;
        },
        filterClientsByName(state, action) {
            let keywords = action.payload.split(" ");
            //search for each keyword in the title
            let result = state.clients.filter(client => {
                let name = client.name.toLowerCase();
                return keywords.every(keyword => name.includes(keyword.toLowerCase()));
            });
            state.filteredClients = result;
        }
    },
});


export const { setClients, setSelectedClient, reset, filterClientsByName } = clientsSlice.actions;
export const selectClients = (state) => state.clients.clients;
export const selectSelectedClient = (state) => state.clients.selectedClient;
export const selectFilteredClients = (state) => state.clients.filteredClients;

export default clientsSlice.reducer;