import { useRef } from "react";
import { useAddCommentMutation } from "../api/cvSlice";
import useShowToastr from "../../custom-hooks/useShowToastr";
import DeleteButton from "../../UI/Buttons/DeleteButton";
import { formatShortDetailedUtcDate } from "../../utils/app-utils";

const EditorComments = ({ videoProcessId, comments, setComments, onApplyClicked }) => {
    const commentTextRef = useRef();
    const [addComment, { isSuccess, isError, isLoading }] = useAddCommentMutation();

    useShowToastr({ isSuccess, isError, title: "Add Comment", successMsg: "Comment added successfully", errorMsg: "Failed to add comment" });

    const removeCommentHandler = (index) => {
        const newComments = comments.filter((comment, i) => i !== index);
        setComments(newComments);
    }

    const addCommentHandler = async () => {
        const response = await addComment({ videoProcessId: videoProcessId, comment: commentTextRef.current.value });
        if (response.data) {
            setComments([...comments, response.data]);
            commentTextRef.current.value = '';
        }
    }

    const setCommentIsResolvedHandler = (index) => {
        const newComments = comments.map((comment, i) => {
            if (i === index) {
                return { ...comment, isResolved: !comment.isResolved };
            }
            return comment;
        });
        setComments(newComments);
    }


    return (
        <div
            className="modal fade"
            id="editorCommentsModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="editorCommentsModalLabel"
            aria-hidden="true"
        >
            <div style={{ minWidth: "40%" }} className="modal-dialog" role="document">
                <div className="modal-content ">
                    <div className="modal-header">
                        <h5 className="modal-title" id="editorCommentsModalLabel">
                            Summary
                        </h5>
                    </div>
                    <div style={{ maxHeight: "70vh" }} className="modal-body scrollbar perfect-scrollbar">
                        {comments.map((comment, index) => (
                            <div key={index} className="card mb-2">
                                <div>
                                    <p>{comment.comment}</p>
                                    <div className="row align-items-center">
                                        <p className="text-dark card-text col-4 my-0 fs--1">{comment.commentedBy.split('@')[0]}</p>
                                        <p className="text-dark card-text col-4 my-0 fs--1">{formatShortDetailedUtcDate(comment.commentedAt)}</p>
                                        <button className="btn btn-sm btn-warning mr-2" disabled={comment.isResolved} onClick={() => setCommentIsResolvedHandler(index)}>{comment.isResolved ? "Is Resolved" : "Resolve"}</button>
                                        <DeleteButton onClick={() => removeCommentHandler(index)} />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="d-flex flex-column form-inline mt-2">
                            <textarea
                                className="form-control w-100"
                                ref={commentTextRef}
                                placeholder="Enter your comment here..."
                            />
                            <button className="btn btn-sm btn-primary mt-2" onClick={addCommentHandler} disabled={isLoading}>Add Comment</button>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={onApplyClicked}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default EditorComments;

