import { useSelector } from "react-redux";
import { selectActivePoint, selectMatch } from "../store/editorSlice.js";
import { getPointErrors, getPointMissingFields } from "../Utils/pointUtils";

const ErrorsList = () => {
    const activePoint = useSelector(selectActivePoint);
    const match = useSelector(selectMatch);
    const pointErrors = getPointErrors(activePoint);
    const pointMissingFields = getPointMissingFields(activePoint, match);
    return (
        <div className="card mt-2 vh-25 scrollbar perfect-scrollbar">
            <div className="card-header pb-1">
                <h5 className="card-title">Items To Complete ({pointErrors.length + pointMissingFields.length})</h5>
            </div>
            <div className="card-body pt-0 px-0">
                <ul className="list-group">
                    {pointMissingFields.map((field, index) => (
                        <li key={`$missing-${index}`} className="list-group-item list-group-item-warning p-1 pl-3">
                            {field}
                        </li>
                    ))}
                    {pointErrors.map((error, index) => (
                        <li key={`error-${index}`} className="list-group-item list-group-item-danger p-1 pl-3">
                            {error}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default ErrorsList;