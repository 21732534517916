import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUser, selectIsAdmin } from "../auth/authSlice";
import { useGetUserTargetsQuery, useGetUserTargetReportExecutionRecordQuery, useDeactivateUserTargetReportMutation } from "../api/userSlice";
import { useGetAllPlayersQuery } from "../api/completeMatchesSlice";
import Spinner from "../../UI/Spinner";
import NewTarget from "./NewTarget";
import Reports from "../../components/reports/Reports";
import OptionsList from "../../components/OptionsList";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";

const UserReportsPage = () => {

    const [selectedTarget, setSelectedTarget] = useState(null);
    const [targetOptions, setTargetOptions] = useState(null);
    const { data: userTargets, isLoading } = useGetUserTargetsQuery(true);
    const { data: reportExecutionRecord, isLoading: userTargetReportExecutionRecordIsLoading, isUninitialized, refetch: refetchReportExecutionRecord } = useGetUserTargetReportExecutionRecordQuery(selectedTarget?.targetId, { skip: !selectedTarget });
    const { data: players } = useGetAllPlayersQuery();

    const [deactivateCurrentReport] = useDeactivateUserTargetReportMutation();

    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);

    const selectTargetChangedHandler = (tragetPlayerId) => {
        const target = userTargets.find(target => target.targetId === tragetPlayerId);
        setSelectedTarget(target);
    }

    useEffect(() => {
        if (players) {
            const options = players
                .map((player) => ({
                    value: player.playerId,
                    label: `${player.name}`,
                }))
            const targetOptions = options.filter(player => !userTargets?.some(target => target.targetId === player.value));
            setTargetOptions(targetOptions);
        }
    }, [players, userTargets]);

    useEffect(() => {
        let interval;
        if (!isUninitialized && reportExecutionRecord && refetchReportExecutionRecord) {
            interval = setInterval(async () => {
                await refetchReportExecutionRecord();
            }, 3000);
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isUninitialized, reportExecutionRecord, refetchReportExecutionRecord]);

    if (!user || isLoading) return <Spinner />;
    else if (!isAdmin) return <NotAuthorizedPage />;

    return (
        <div className="d-flex flex-column">
            <div className="ml-auto mr-5" style={{ display: "flex", gap: "10px" }}>
                <NewTarget targetOptions={targetOptions} />
                <button
                    className="btn btn-danger rounded-capsule ml-auto"
                    type="button"
                    disabled={!(reportExecutionRecord || selectedTarget)}
                    onClick={() =>
                        deactivateCurrentReport(selectedTarget).then(() =>
                            setSelectedTarget(null)
                        )
                    }
                >
                    Deactivate
                    <i className="fas fa-minus-circle ml-2"></i>
                </button>
            </div>
            <div className="row mt-2 flex-nowrap" style={{ overflowX: "hidden", overflowY: "hidden" }}>

                <OptionsList
                    title="Targets"
                    options={userTargets?.map((target) => ({
                        label: target.targetName,
                        value: target.targetId,
                    }))
                    }
                    isLoading={isLoading}
                    selectedOption={selectedTarget?.targetId}
                    setSelectedOption={selectTargetChangedHandler}
                    modalId="AddNewTargetModal"
                />
                {userTargetReportExecutionRecordIsLoading && <Spinner />}
                {reportExecutionRecord && selectedTarget &&
                    <div className="d-flex flex-column col-9 mt-1">
                        <Reports reportExecutionRecord={reportExecutionRecord} target={{ localName: selectedTarget.targetLocalName, localPlayerId: selectedTarget.targetLocalId }} isUserReport={true} />
                    </div>}
            </div>
        </div >
    );
}

export default UserReportsPage;