

const TrackingSheet = ({ trackingSheet }) => {
    return (
        <div style={{ maxHeight: "200px" }} className="container-fluid">
            <br />
            <div className=" alert alert-secondary text-center font-weight-bold lead" role="alert">{trackingSheet.summary}</div>
            <br />
            <div className="row">
                <div className="col">
                    <div className="table-responsive scrollbar perfect-scrollbar">
                        <table className="table table-striped table-bordered table-hover table-sm text-center">
                            <thead>
                                <tr>
                                    <th scope="col">Index</th>
                                    <th scope="col">P1</th>
                                    <th scope="col">D1</th>
                                    <th scope="col">B1</th>
                                    <th scope="col">P2</th>
                                    <th scope="col">D2</th>
                                    <th scope="col">B2</th>
                                    <th scope="col">Tournament</th>
                                    <th scope="col">Year</th>
                                    <th scope="col">Format</th>
                                    <th scope="col">Result</th>
                                    <th scope="col">Source</th>
                                </tr>
                            </thead>
                            <tbody>
                                {trackingSheet.matches.map((match, index) =>
                                    <tr key={`match-${index + 1}`}>
                                        <td>{index + 1}</td>
                                        <td>{match.localNamePlayer1}</td>
                                        <td>{match.handednessPlayer1}</td>
                                        <td>{match.backhandPlayer1}</td>
                                        <td>{match.localNamePlayer2}</td>
                                        <td>{match.handednessPlayer2}</td>
                                        <td>{match.backhandPlayer2}</td>
                                        <td>{match.eventName}</td>
                                        <td>{match.year}</td>
                                        <td>{match.format}</td>
                                        <td>{match.result}</td>
                                        <td>{match.resultSource}</td>
                                    </tr>)}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrackingSheet;