import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";


export const hangfireApiSlice = createApi({
    reducerPath: "hangfireapi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/hfjob`,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getHangfireJobStatus: builder.query({
            query: (jobId) => `/status/${jobId}`,
        })
    })
});

export const { useGetHangfireJobStatusQuery } = hangfireApiSlice;
