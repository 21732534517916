import React from "react";
const ScoreInput = ({ field, value, onScoreChanged }) => {
    return (
        <div>
            <input
                className="form-control w-75"
                type="text"
                placeholder={field}
                value={value}
                onChange={(e) => onScoreChanged(field, e.target.value)}
                autoComplete="off"
            />
        </div>
    );
};

export default ScoreInput;
