import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const drawsSlice = createApi({
    reducerPath: "drawsapi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ["reportExecutionRecord", "optionsList"],
    endpoints: (builder) => ({
        getExecutionEvents: builder.query({
            query: (isActive) => `draws/eventinstance?isActive=${isActive}`,
            providesTags: (result, error, args) => ["optionsList"],
            extraOptions: { maxRetries: 3 },
        }),
        getEventInstanceClients: builder.query({
            query: (eventInstanceId) => `draws/eventinstance/${eventInstanceId}`,
        }),
        getEventInstanceClientOpponents: builder.query({
            query: ({ eventInstanceId, clientId }) =>
                `draws/eventinstance/${eventInstanceId}/${clientId}`,
        }),
        getEventInstanceReportExecutionRecord: builder.query({
            query: ({ eventInstanceId, clientId, opponentId }) => `draws/eventinstance/${eventInstanceId}/${clientId}/${opponentId}`,
            providesTags: (result, error, args) => [
                "reportExecutionRecord",
            ],
        }),
        getDrawMatches: builder.query({
            query: (drawId) => ({
                url: `draws/matches/${drawId}`,
            }),
        }),
        getPlayerMatches: builder.query({
            query: ({ drawId, drawPlayerName }) => ({
                url: `draws/matches/${drawId}/${drawPlayerName}`,
            }),
        }),
        createEventInstanceRecord: builder.mutation({
            query: (payload) => ({
                url: `draws/eventinstance`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: (result, error, args) => ["optionsList"]
        }),
        restartAutogeneration: builder.mutation({
            query: ({ id }) => ({
                url: `draws/autogeneration/${id}`,
                method: "POST",
            }),
        }),
    }),
});

export const {
    useGetExecutionEventsQuery,
    useLazyGetEventInstanceClientsQuery,
    useLazyGetEventInstanceClientOpponentsQuery,
    useGetEventInstanceReportExecutionRecordQuery,
    useGetDrawMatchesQuery,
    useGetPlayerMatchesQuery,
    useCreateEventInstanceRecordMutation,
    useRestartAutogenerationMutation
} = drawsSlice;
