import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useCreatePlayerGroupMutation } from "../api/playerGroupsSlice";

const categories = [
  { value: "1", label: "ATP" },
  { value: "2", label: "WTA" },
];

const NewPlayerGroup = () => {
  const [newPlayerGroup, setNewPlayerGroup] = useState({
    title: null,
    description: null,
    category: null,
  });

  const [
    createRecord,
    {
      isLoading: loadingRecordCreation,
      isSuccess: createdSuccessfully,
      isError: creationError,
    },
  ] = useCreatePlayerGroupMutation();

  useEffect(() => {
    toastr.options.positionClass = "toast-bottom-right";
    if (createdSuccessfully) {
      toastr.success("Created Successfully!", "Create New Record");
      $("#AddNewGroupModal").modal("hide");
      setNewPlayerGroup({ title: "", description: "", category: "" }); // Reset fields
    } else if (creationError) {
      toastr.error("Failed to create!", "Create New Record");
    }
    setNewPlayerGroup({ title: "", description: "", category: "" });
  }, [createdSuccessfully, creationError]);

  const createRecordHandler = async () => {
    if (!newPlayerGroup.title || !newPlayerGroup.category) {
      toastr.error("Title and Category are required.", "Validation Error");
      return; // Prevent the form submission if validation fails
    }
    try {
      await createRecord(newPlayerGroup).unwrap();
    } catch (error) {
      toastr.error("Failed to create!", "Create New Record");
    }
  };

  return (
    <div>
      <div
        className="modal fade"
        id="AddNewGroupModal"
        role="dialog"
        aria-labelledby="AddNewGroupModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="AddNewGroupModalLabel">
                Add New Player Group
              </h3>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="font-weight-light" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container d-flex flex-wrap mb-4 justify-content-center align-items-start flex-column">
                <div className="col-xl-12 col-md-6 mb-3">
                  <h6 className="text-nowrap fs-1 text-center"> Title <span style={{ color: 'red' }}>*</span></h6>
                  <input
                    className="form-control"
                    id="title"
                    type="text"
                    placeholder="Group Title"
                    value={newPlayerGroup.title}
                    onChange={(e) =>
                      setNewPlayerGroup({
                        ...newPlayerGroup,
                        title: e.target.value,
                      })
                    }
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="col-xl-12 col-md-6 mb-3">
                  <h6 className="text-nowrap fs-1 text-center">Description</h6>
                  <input
                    className="form-control"
                    id="description"
                    type="text"
                    placeholder="Group Description"
                    value={newPlayerGroup.description}
                    onChange={(e) =>
                      setNewPlayerGroup({
                        ...newPlayerGroup,
                        description: e.target.value,
                      })
                    }
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="col-xl-12 col-md-6 mb-3">
                  <h6 className="text-nowrap fs-1 text-center">Category <span style={{ color: 'red' }}>*</span></h6>
                  <Select
                    closeMenuOnSelect={true}
                    options={categories}
                    value={
                      newPlayerGroup.category
                        ? categories.find(
                          (option) => option.value === newPlayerGroup.category
                        )
                        : null
                    }
                    onChange={(selectedOption) =>
                      setNewPlayerGroup({
                        ...newPlayerGroup,
                        category: selectedOption.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                type="button"
                data-dismiss="modal"
                onClick={() => setNewPlayerGroup({ title: "", description: "", category: "" })}
              >
                Cancel
              </button>
              <button
                className="btn btn-warning"
                type="button"
                onClick={createRecordHandler}
                disabled={
                  newPlayerGroup == null || !newPlayerGroup.category
                }
              >
                {loadingRecordCreation ? "Creating..." : "Add Player Group"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPlayerGroup;
