import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetConnectToDropboxMutation, useUpdateDropboxTokenMutation, useLazyGetDropboxPathQuery } from "../api/apiSlice";
import { useGetUserSettingsQuery, useUpdateUserSettingsMutation } from "../api/userSlice";
import useShowToastr from "../../custom-hooks/useShowToastr";
import { dropboxOptions } from "../../utils/app-utils";
import Spinner from "../../UI/Spinner";

const initialState = {
    email: '',
    name: '',
    dropboxRefreshToken: '',
    defaultDropboxPath: '',
    modifiedAt: null,
    analystPlayerCardSuffix: '',
    uploadStdReportByDefault: false,
    uploadHEReportByDefault: false,
    uploadPlayerCardByDefault: false
}

function SettingsPage() {
    const [userSettings, setUserSettings] = useState(initialState);

    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    const { data: settings } = useGetUserSettingsQuery();

    const [getConnectToDropbox, { data, error, isLoading }] = useGetConnectToDropboxMutation();
    const [updateDropboxToken] = useUpdateDropboxTokenMutation();
    const [updateUserSettings, { isSuccess, isError }] = useUpdateUserSettingsMutation();
    const [getDropboxPath] = useLazyGetDropboxPathQuery();

    useShowToastr({ isSuccess: isSuccess, isError: isError, title: "Settings", successMsg: "Settings updated successfully", errorMsg: "Failed to update settings" });

    useEffect(() => {
        if (settings) {
            setUserSettings(settings);
            if (!document.getElementById("settings-dropbox-container").hasChildNodes()) {
                const button = Dropbox.createChooseButton({
                    ...dropboxOptions,
                    success: async function (files) {
                        const response = await getDropboxPath(files[0].id);
                        if (response.data) {
                            setUserSettings(prev => ({ ...prev, defaultDropboxPath: response.data.path }));
                        }
                    }
                });
                document.getElementById("settings-dropbox-container").append(button);
            }
        }
    }, [settings]);

    useEffect(() => {
        if (data) {
            window.location.href = data;
        }
    }, [data, error, isLoading]);

    useEffect(() => {
        if (token) {
            updateDropboxToken({ token: token });
        }
    }, [token]);

    if (!settings) return <Spinner />

    return (
        <div className="m-4">
            <h4>Settings</h4>
            <hr />
            <div className="container-fluid pl-0">
                <div className="row ml-0 mb-3 align-items-center">
                    <label className="fs-0 mb-0 mr-2">Dropbox Status: </label>
                    {userSettings && userSettings.dropboxRefreshToken && userSettings.dropboxRefreshToken != "" && <div className="text-success">Dropbox is connected</div>}
                    {userSettings && (!userSettings.dropboxRefreshToken || userSettings.dropboxRefreshToken == "") && <button className="btn btn-light" onClick={() => getConnectToDropbox()}>Connect to Dropbox</button>}
                </div>
                <div className="d-flex row flex-nowrap align-items-center ml-0 mb-3">
                    <label className="fs-0 mb-0 mr-2">Default Dropbox Path: </label>
                    <input style={{ width: "30%" }} readOnly value={userSettings.defaultDropboxPath} className="form-control mr-2 p-0 pl-2" />
                    <div id="settings-dropbox-container" />
                </div>
                <ul className="nav nav-pills" id="settings-myTab" role="tablist">
                    <li className="nav-item"><a className="nav-link active" id="settings-std-tab" data-toggle="tab" href="#settings-tab-std" role="tab" aria-controls="settings-tab-std" aria-selected="true">STD</a></li>
                    <li className="nav-item"><a className="nav-link" id="settings-he-tab" data-toggle="tab" href="#settings-tab-he" role="tab" aria-controls="settings-tab-he" aria-selected="false">HE</a></li>
                    <li className="nav-item"><a className="nav-link" id="settings-analyst-tab" data-toggle="tab" href="#settings-tab-analyst" role="tab" aria-controls="settings-tab-analyst" aria-selected="false">Player Card</a></li>
                </ul>
                <div className="tab-content border p-3 mt-3" id="settings-myTabContent">
                    <div className="tab-pane fade show active" id="settings-tab-std" role="tabpanel" aria-labelledby="std-tab">
                        <label className="fs-0 mb-0 mr-2">Upload to dropbox by default: </label>
                        <div className="d-inline custom-control custom-switch" onClick={() => setUserSettings((prev) => ({ ...prev, uploadStdReportByDefault: !prev.uploadStdReportByDefault }))}>
                            <input className="custom-control-input" type="checkbox" readOnly checked={userSettings.uploadStdReportByDefault}></input>
                            <label className="custom-control-label"></label>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="settings-tab-he" role="tabpanel" aria-labelledby="he-tab">
                        <label className="fs-0 mb-0 mr-2">Upload to dropbox by default: </label>
                        <div className="d-inline custom-control custom-switch" onClick={() => setUserSettings((prev) => ({ ...prev, uploadHEReportByDefault: !prev.uploadHEReportByDefault }))}>
                            <input className="custom-control-input" type="checkbox" readOnly checked={userSettings.uploadHEReportByDefault}></input>
                            <label className="custom-control-label"></label>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="settings-tab-analyst" role="tabpanel" aria-labelledby="analyst-tab">
                        <label className="fs-0 mb-0 mr-2">Upload to dropbox by default: </label>
                        <div className="d-inline custom-control custom-switch" onClick={() => setUserSettings((prev) => ({ ...prev, uploadPlayerCardByDefault: !prev.uploadPlayerCardByDefault }))}>
                            <input className="custom-control-input" type="checkbox" readOnly checked={userSettings.uploadPlayerCardByDefault}></input>
                            <label className="custom-control-label"></label>
                        </div>
                    </div>
                </div>
            </div>
            <button className="btn btn-primary mt-3" onClick={() => updateUserSettings(userSettings)}>Save Settings</button>
        </div >
    );
}

export default SettingsPage;