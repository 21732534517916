import { useSelector } from "react-redux";
import {
  selectMatch,
} from "../store/editorSlice";
import PointRowItem from "./PointRowItem";

export default function MatchPointList() {
  const match = useSelector(selectMatch);

  let pointsCount = match.points.length;
  let needsReviewPointsCount = match.points.filter(p => p.needsReview).length;

  return (
    <div className="ml-2">
      <label className="text-white" htmlFor={match.name}>{needsReviewPointsCount}/{pointsCount} {match.name}</label>
      <div className="list-group">
        {match.points.map((point, index) => (
          <PointRowItem key={point.id} match={match} point={point} currentIndex={index} />
        ))}
      </div>
    </div>
  );
}
