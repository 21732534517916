import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsAdmin, selectUser } from "../auth/authSlice";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import Spinner from "../../UI/Spinner";
import { useSearchVideosDropboxMutation, useSearchDataDropboxMutation, useMoveDropboxVideoMutation } from "../api/apiSlice";


export default function DropboxPage() {
    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);
    const [searchText, setSearchText] = useState("");
    const [searchVideosDropbox, { data: videos, error: errorVideos, isLoading: isLoadingVideos, isFetching: isFetchingVideos }] = useSearchVideosDropboxMutation();
    const [searchDataDropbox, { data: files, error: errorData, isLoading: isLoadingData, isFetching: isFetchingData }] = useSearchDataDropboxMutation();
    const [moveDropboxVideo, { data: moveData, error: moveError, isLoading: moveIsLoading, isFetching: moveIsFetching }] = useMoveDropboxVideoMutation();

    if (!user) return <Spinner />;
    else if (!isAdmin) return <NotAuthorizedPage />;
    return (
        <div className="">
            <div className="row">
                <div className="col-md-4 mb-3">
                    <input className="form-control rounded-capsule" type="text" placeholder="Search.."
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                searchVideosDropbox({ keywords: searchText });
                                searchDataDropbox({ keywords: searchText });
                            }
                        }}
                        value={searchText}
                    ></input>
                </div>
                <div className="col-md-2 mb-3">
                    <button className="btn btn-primary rounded-capsule" onClick={
                        () => {
                            searchVideosDropbox({ keywords: searchText });
                            searchDataDropbox({ keywords: searchText });
                        }
                    }>Search</button>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {(isLoadingVideos || isFetchingVideos) && <Spinner />}
                    {!isLoadingVideos && videos && videos.length === 0 && <div>No videos found...</div>}
                    {!isLoadingVideos && errorVideos && <div>Something went wrong...</div>}
                    {!isLoadingVideos && !isFetchingVideos && videos && videos.length > 0 &&
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Path</th>
                                    <th>Is Name Valid</th>
                                    <th>Is Loaded</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {videos?.map((file) => (
                                    <tr key={file.path}>
                                        <td>{file.category}</td>
                                        <td>
                                            {file.path}
                                            {file.jobs.length > 0 && <br />}
                                            {file.jobs.length > 0 &&
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Job Id</th>
                                                            <th>Status</th>
                                                            <th>Result</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {file.jobs?.map((job) => (
                                                            <tr key={job.id}>
                                                                <td>{job.videoUploadJobId}</td>
                                                                <td>{job.status}</td>
                                                                <td>{job.result}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            }
                                        </td>
                                        <td>{file.isNameValid.toString()}</td>
                                        <td>{file.isLoaded.toString()}</td>
                                        <td>
                                            <button className="btn btn-primary rounded-capsule"
                                                onClick={(e) => {
                                                    moveDropboxVideo({ path: file.path });
                                                    searchVideosDropbox({ keywords: searchText });
                                                }}>
                                                Move
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-12">
                    {(isLoadingData || isFetchingData) && <Spinner />}
                    {!isLoadingData && files && files.length === 0 && <div>No data found...</div>}
                    {!isLoadingData && errorData && <div>Something went wrong...</div>}
                    {!isLoadingData && !isFetchingData && files && files.length > 0 &&
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Path</th>
                                    <th>Is Name Valid</th>
                                </tr>
                            </thead>
                            <tbody>
                                {files?.map((file) => (
                                    <tr key={file.path}>
                                        <td>{file.category}</td>
                                        <td>{file.path}</td>
                                        <td>{file.isNameValid.toString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        </div>
    );
}