import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { setUser } from '../features/auth/authSlice';
import {useDispatch} from 'react-redux';

function IndexPage() {
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!authState) {
            setUserInfo(null);
            dispatch(setUser(null));
            return;
        }

        if (authState && !authState.isAuthenticated) {
            setUserInfo(null);
            dispatch(setUser(null));
        } else {
            setTimeout(() => {
                oktaAuth.getUser().then((info) => {
                    setUserInfo(info);
                    dispatch(setUser(info));
                });
            }, 400);
        }
    }, [authState, oktaAuth]);

    // add new lines 
    return (
        <div>
            Index Page
            {authState && (authState.isAuthenticated && userInfo)
                && (
                    <div>
                        <p>
                            Welcome ,&nbsp;
                            {userInfo.name}
                            !
                        </p>
                    </div>
                )}

        </div>

    );
}

export default IndexPage;
