import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";
import { formatUtcDate } from "../../utils/app-utils";

export const oncSlice = createApi({
    reducerPath: "oncapi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/onc`,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAllONCPlayers: builder.query({
            query: () => `/players`,
            transformResponse: (response) => {
                return response.map((player) => {
                    return {
                        ...player,
                        dob: formatUtcDate(player.dob),
                    };
                });
            }
        }),
        getPlayerStatus: builder.mutation({
            query: (players) => ({
                url: `/players/status`,
                method: "POST",
                body: players,
            }),
        }),
        movePlayerToDB: builder.mutation({
            query: (oncPlayerId) => ({
                url: `/players/${oncPlayerId}/move`,
                method: "POST",
            }),
        }),
        getAllONCEvents: builder.query({
            query: () => `/events`,
            transformResponse: (response) => {
                return response.map((event) => {
                    return {
                        ...event,
                        startDate: formatUtcDate(event.startDate),
                    };
                });
            }
        }),
        getEventStatus: builder.mutation({
            query: (events) => ({
                url: `/events/status`,
                method: "POST",
                body: events,
            }),
        }),
        moveEventToDB: builder.mutation({
            query: (oncEventId) => ({
                url: `/events/${oncEventId}/move`,
                method: "POST",
            }),
        }),
    }),
});

export const {
    useGetAllONCPlayersQuery,
    useGetPlayerStatusMutation,
    useMovePlayerToDBMutation,
    useGetAllONCEventsQuery,
    useGetEventStatusMutation,
    useMoveEventToDBMutation,
} = oncSlice;
