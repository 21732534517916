import React from "react";

const NumberInput = ({ field, value, onNumberChanged }) => {

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp") onNumberChanged(field, Number(value) + 1);
    if (e.key === "ArrowDown") Number(value) > 0 && onNumberChanged(field, Number(value) - 1);
  };

  const numberChangedHandler = (value) => {
    if (isNaN(value)) return;
    onNumberChanged(field, Number(value));
  };
  return (
    <input
      className="form-control w-50"
      id={field}
      type="text"
      placeholder={field}
      value={value ?? ''}
      onChange={(e) => numberChangedHandler(e.target.value)}
      onKeyDown={handleKeyDown}
      autoComplete="off"
    />
  );
};

export default NumberInput;
