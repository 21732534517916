import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedEvent: null,
    selectedClient: null,
    selectedOpponent: null,
    clients: null,
    opponents: null,
    clientSettings: null,
    reportEmailContent: ""
};

const drawsReportsSlice = createSlice({
    name: "drawsReports",
    initialState,
    reducers: {

        selectedEventChanged: (state, action) => {
            state.selectedEvent = action.payload;
            state.selectedClient = null;
            state.selectedOpponent = null;
            state.opponents = null;
        },
        selectedClientChanged: (state, action) => {
            state.selectedClient = action.payload;
            state.selectedOpponent = null;
        },
        selectedOpponentChanged: (state, action) => {
            state.selectedOpponent = action.payload;
        },
        setClients: (state, action) => {
            state.clients = action.payload;
        },
        setOpponents: (state, action) => {
            state.opponents = action.payload;
        },
        setSelectedEvent: (state, action) => {
            state.selectedEvent = action.payload;
        },
        setSelectedClient: (state, action) => {
            state.selectedClient = action.payload;
            state.clients = state.clients.map((client) => {
                return client.clientId === action.payload.clientId ? action.payload : client;
            });
        },
        setClientSettings:(state,action) =>{
            state.clientSettings = action.payload;
        },
        setReportEmailContent: (state, action) => {
            state.reportEmailContent = action.payload;
        },
        reset: (state) => {
            state.selectedEvent = null;
            state.selectedClient = null;
            state.selectedOpponent = null;
            state.clients = null;
            state.opponents = null;
        }
    },
});

export const {
      setClients
    , setOpponents
    , setSelectedEvent
    , setSelectedClient
    , setClientSettings
    , selectedEventChanged
    , selectedClientChanged
    , selectedOpponentChanged
    , reset
    , setReportEmailContent
} = drawsReportsSlice.actions;

export const selectSelectedEvent = (state) => state.drawsReports.selectedEvent;
export const selectSelectedClient = (state) => state.drawsReports.selectedClient;
export const selectSelectedOpponent = (state) => state.drawsReports.selectedOpponent;
export const selectOpponents = (state) => state.drawsReports.opponents;
export const selectClients = (state) => state.drawsReports.clients;
export const selectClientSettings = (state) => state.drawsReports.clientSettings;
export const selectReportEmailContent = (state) => state.drawsReports.reportEmailContent;

export default drawsReportsSlice.reducer;