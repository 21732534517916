import React, { useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../UI/Spinner";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import {
  useGetPlayerGroupByIdQuery,
  useGetPlayerGroupsQuery,
} from "../api/playerGroupsSlice";
import { selectIsAdmin, selectUser } from "../auth/authSlice";
import NewPlayerGroup from "./NewPlayerGroup";
import PlayerGroup from "./PlayerGroup";
import classes from "./PlayerGroupsPage.module.css";

import OptionsList from "../../components/OptionsList";
const PlayerGroupsPage = () => {

  const user = useSelector(selectUser);
  const isAdmin = useSelector(selectIsAdmin);
  const [selectedPlayerGroup, setSelectedPlayerGroup] = useState(null);

  const {
    data: playerGroups,
    isLoading: loadingPlayerGroups,
  } = useGetPlayerGroupsQuery();

  const { data: selectedPlayerGroupDetails, isLoading: loadingPlayerGroupDetails } =
    useGetPlayerGroupByIdQuery(selectedPlayerGroup?.playerGroupId, {
      skip: !selectedPlayerGroup?.playerGroupId,
    });

  const eventChangedHandler = (playerGroupId) => {
    const playerGroup = playerGroups.find(
      (group) => group.playerGroupId === playerGroupId
    );
    setSelectedPlayerGroup(playerGroup);
  };

  if (!user) return <Spinner />;
  else if (!isAdmin) return <NotAuthorizedPage />;

  return (
    <div className="d-flex flex-column">
      <div className={classes?.addplayer}>
        <NewPlayerGroup />
      </div>

      <div
        className="row mt-2 flex-nowrap  "
        style={{ overflowX: "hidden", overflowY: "hidden", gap: "40px" }}
      >
        {loadingPlayerGroups && <Spinner />}
        {playerGroups && (
          <OptionsList
            title="Player Groups"
            options={playerGroups.map((playerGroup) => ({
              label: playerGroup.title,
              value: playerGroup.playerGroupId,
            }))}
            selectedOption={selectedPlayerGroup?.playerGroupId}
            setSelectedOption={eventChangedHandler}
            modalId="AddNewGroupModal"
          />
        )}
        <div style={{width:"100%"}} d-flex flex-column col-9 mt-1>
          {loadingPlayerGroupDetails && <Spinner />}
          {!loadingPlayerGroupDetails && selectedPlayerGroup && selectedPlayerGroupDetails && (
            <PlayerGroup data={selectedPlayerGroupDetails} setSelectedPlayerGroup={setSelectedPlayerGroup} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerGroupsPage;
