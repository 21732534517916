import { createSlice, current } from "@reduxjs/toolkit";

const initialEditHEState = {
  selectedMatch: null,
  currentlyPlayingMatch: null,
  currentlyPlayingPoint: null,
  currentlyPlayingShot: null,
  isPlaying: false,
  navigateToPoint: false,
  editMode: false,
  replay: false,
  replayShot: false,
  autoPlay: false,
  isGlobalEditMode: false,
};

const editHESlice = createSlice({
  name: "editHE",
  initialState: initialEditHEState,
  reducers: {
    setAutoPlay(state, action) {
      state.autoPlay = action.payload;
    },
    setIsGlobalEditMode(state, action) {
      state.isGlobalEditMode = action.payload;
    },
    setReplay(state, action) {
      state.replay = action.payload;
    },
    replayPoint(state, action) {
      state.currentlyPlayingShot = state.currentlyPlayingPoint.shots[0];
      state.isPlaying = true;
      state.replay = true;
      state.replayShot = false;
    },
    replayShot(state, action) {
      state.currentlyPlayingShot = state.currentlyPlayingPoint.shots.find(
        (s) => s.shotId === action.payload
      );
      state.isPlaying = true;
      state.replayShot = true;
      state.replay = true;
    },
    setSelectedMatch(state, action) {
      state.selectedMatch = action.payload;
    },
    setIsPlaying(state, action) {
      state.isPlaying = action.payload;
    },
    setNavigateToPoint(state, action) {
      state.navigateToPoint = action.payload;
    },
    setCurrentlyPlayingMatch(state, action) {
      state.currentlyPlayingMatch = action.payload;
      state.currentlyPlayingPoint = action.payload.pointList[0];
      state.currentlyPlayingShot = action.payload.pointList[0].shots[0];
      state.isPlaying = true;
    },
    setCurrentlyPlayingPoint(state, action) {
      const pointId = action.payload;
      const point = state.currentlyPlayingMatch.pointList.find(
        (p) => p.pointId === pointId
      );
      state.currentlyPlayingPoint = point;
      state.currentlyPlayingShot = point.shots[0];
      state.isPlaying = true;
    },
    setCurrentlyPlayingShot(state, action) {
      const shotId = action.payload;
      const shot = state.currentlyPlayingPoint.shots.find(
        (s) => s.shotId === shotId
      );
      state.currentlyPlayingShot = shot;
      state.isPlaying = true;
    },
    playNextShot(state, action) {
      if (state.replayShot) {
        state.replayShot = false;
        state.isPlaying = false;
        return;
      }
      const currentlyPlayingPoint = state.currentlyPlayingPoint;
      const currentlyPlayingShotIndex = currentlyPlayingPoint.shots.findIndex(
        (s) => s.shotId === state.currentlyPlayingShot.shotId
      );
      const nextShotIndex = currentlyPlayingShotIndex + 1;
      if (nextShotIndex < currentlyPlayingPoint.shots.length) {
        // there is a next shot, play it
        state.currentlyPlayingShot = currentlyPlayingPoint.shots[nextShotIndex];
      } else {
        // there is not a next shot so try playing the next point
        const currentlyPlayingMatch = state.currentlyPlayingMatch;

        const currentlyPlayingPointIndex =
          currentlyPlayingMatch.pointList.findIndex(
            (p) => p.pointId === currentlyPlayingPoint.pointId
          );
        const nextPointIndex = currentlyPlayingPointIndex + 1;
        if (
          nextPointIndex < currentlyPlayingMatch.pointList.length &&
          (!state.editMode || state.autoPlay)
        ) {
          // there is a next point so play it and not in edit mode
          const nextPoint = currentlyPlayingMatch.pointList[nextPointIndex];
          state.currentlyPlayingPoint = nextPoint;
          state.currentlyPlayingShot = nextPoint.shots[0];
        } else {
          return {
            ...state,
            isPlaying: false,
          };
        }
      }
    },
    setEditMode(state, action) {
      state.editMode = action.payload;
    },
    setActivePointWithStep(state, action) {
      const currentlyPlayingPoint = state.currentlyPlayingPoint;
      const currentlyPlayingMatch = state.currentlyPlayingMatch;

      const currentlyPlayingPointIndex =
        currentlyPlayingMatch.pointList.findIndex(
          (p) => p.pointId === currentlyPlayingPoint.pointId
        );
      const nextPointIndex = currentlyPlayingPointIndex + action.payload;

      if (
        nextPointIndex < currentlyPlayingMatch.pointList.length &&
        nextPointIndex >= 0
      ) {
        const nextPoint = currentlyPlayingMatch.pointList[nextPointIndex];
        state.currentlyPlayingPoint = nextPoint;
        state.currentlyPlayingShot = nextPoint.shots[0];
      } else {
        state.currentlyPlayingShot = currentlyPlayingPoint.shots[0];
      }
    },
    editActivePointEntity(state, action) {
      state.currentlyPlayingPoint = action.payload;
      let currentlyPlayingPointIndex =
        state.currentlyPlayingMatch.pointList.findIndex(
          (p) => p.pointId === state.currentlyPlayingPoint.pointId
        );

      state.currentlyPlayingMatch.pointList[currentlyPlayingPointIndex] = action.payload;
    },
    editActivePoint(state, action) {
      const { shot, field } = action.payload;

      const indexOfEditedShot = state.currentlyPlayingPoint.shots.findIndex(
        (s) => s.shotId === shot.shotId
      );

      const amountChanged = shot[field] - state.currentlyPlayingPoint.shots[indexOfEditedShot][field];

      const updateTime = (x) => {
        x.startTime += amountChanged;
        x.endTime += amountChanged;
        return x;
      };

      const updateCurrentPoint = () => {
        state.currentlyPlayingPoint.startTime =
          state.currentlyPlayingPoint.shots[0].startTime;

        state.currentlyPlayingPoint.endTime =
          state.currentlyPlayingPoint.shots[
            state.currentlyPlayingPoint.shots.length - 1
          ].endTime + 2;

        state.currentlyPlayingMatch.pointList =
          state.currentlyPlayingMatch.pointList.map((p) => {
            if (p.pointId === state.currentlyPlayingPoint.pointId) {
              return state.currentlyPlayingPoint;
            }
            return p;
          });
      };

      if (!state.isGlobalEditMode || field === "endTime") {
        state.currentlyPlayingShot = shot;
      } else {
        shot.endTime = shot.endTime + amountChanged;
        state.currentlyPlayingShot = shot;
      }
      state.currentlyPlayingPoint.shots[indexOfEditedShot] = shot;

      if (state.isGlobalEditMode) {
        state.currentlyPlayingPoint.shots =
          state.currentlyPlayingPoint.shots.map((s, index) =>
            index > indexOfEditedShot ? updateTime(s) : s
          );

        updateCurrentPoint();

        const indexOfCurrentPoint =
          state.currentlyPlayingMatch.pointList.findIndex(
            (p) => p.pointId === state.currentlyPlayingPoint.pointId
          );

        const updatePointAndShots = (point) => {
          point = updateTime(point);
          point.shots = point.shots.map(updateTime);
          return point;
        };

        state.currentlyPlayingMatch.pointList =
          state.currentlyPlayingMatch.pointList.map((point, i) =>
            i > indexOfCurrentPoint ? updatePointAndShots(point) : point
          );
      } else {
        updateCurrentPoint();
      }
    },

    removePoint(state, action) {
      state.currentlyPlayingMatch.pointList = state.currentlyPlayingMatch.pointList.filter(p => p.pointId != action.payload)

      if (state.currentlyPlayingPoint.pointId === action.payload) {
        const activePointIndex = state.currentlyPlayingMatch.pointList.findIndex(
          (point) => point.pointId === state.currentlyPlayingPoint.pointId
        );
        const nextIndex = activePointIndex - 1 >= 0 ? activePointIndex - 1 : 0;
        state.currentlyPlayingPoint = state.currentlyPlayingMatch.pointList[nextIndex];
      }
    },

    addPoint(state, action) {
      let index = action.payload;
      const currentPoints = state.currentlyPlayingMatch.pointList;
      if (index === currentPoints.length)
        index = index - 1;
      const newPoints = [
        ...currentPoints.slice(0, index),
        {
          ...currentPoints[index], pointId: Math.floor(Math.random() * -10000000), isManuallyAdded: true, shots: currentPoints[index].shots.map(s => ({ ...s, shotId: Math.floor(Math.random() * -100000000) }))
        },
        ...currentPoints.slice(index)
      ];
      state.currentlyPlayingMatch.pointList = newPoints;
    }
  },
});

export const {
  setSelectedMatch,
  setCurrentlyPlayingMatch,
  setCurrentlyPlayingPoint,
  setCurrentlyPlayingShot,
  setIsPlaying,
  setNavigateToPoint,
  setEditMode,
  playNextShot,
  setActivePointWithStep,
  replayPoint,
  replayShot,
  setReplay,
  editActivePoint,
  setAutoPlay,
  setIsGlobalEditMode,
  editActivePointEntity,
  removePoint,
  addPoint
} = editHESlice.actions;

export const selectIsPlaying = (state) => state.editHE.isPlaying;

export const selectCurrentlyPlayingMatch = (state) =>
  state.editHE.currentlyPlayingMatch;

export const selectCurrentlyPlayingPoint = (state) =>
  state.editHE.currentlyPlayingPoint;

export const selectCurrentlyPlayingShot = (state) =>
  state.editHE.currentlyPlayingShot;

export const selectNavigateToPoint = (state) => state.editHE.navigateToPoint;

export const selectEditMode = (state) => state.editHE.editMode;

export const selectSelectedMatch = (state) => state.editHE.selectedMatch;
export const selectReplay = (state) => state.editHE.replay;
export const selectReplayShot = (state) => state.editHE.replayShot;
export const selectAutoPlay = (state) => state.editHE.autoPlay;
export const selectIsGlobalEditMode = (state) => state.editHE.isGlobalEditMode;

export default editHESlice.reducer;
