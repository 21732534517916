import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentlyPlayingPoint,
  selectIsPlaying,
  selectNavigateToPoint,
  selectEditMode,
  setNavigateToPoint,
  setCurrentlyPlayingPoint,
  setIsPlaying,
  addPoint,
  removePoint
} from "../store/editHESlice";
import { secondsToTime } from "../../../utils/app-utils";
import ShotItem from "./ShotItem";
import classes from "../styles.module.css";
import pointSelectionClasses from "./PointSelection.module.css";
import DeleteButton from "../../../UI/Buttons/DeleteButton";

const PointItem = ({ point, currentIndex }) => {
  const dispatch = useDispatch();
  const [showBtns, setShowBtns] = useState(false);

  const currentlyPlayingPoint = useSelector(selectCurrentlyPlayingPoint);
  const isPlaying = useSelector(selectIsPlaying);
  const editMode = useSelector(selectEditMode);

  const pointRef = useRef(null);
  const navigateToPoint = useSelector(selectNavigateToPoint);
  useEffect(() => {
    if (
      navigateToPoint &&
      currentlyPlayingPoint?.pointId === point.pointId &&
      !pointRef.current.isIntersecting
    ) {
      pointRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      dispatch(setNavigateToPoint(false));
    }
  }, [navigateToPoint]);

  let actionButton = null;
  if (isPlaying && currentlyPlayingPoint?.pointId === point.pointId) {
    actionButton = (
      <div
        key={`pause-${point.pointId}`}
        style={{ cursor: "pointer", marginRight: "1rem" }}
        onClick={(e) => {
          dispatch(setIsPlaying(false));
        }}
      >
        <span className="fas fa-pause"></span>
      </div>
    );
  } else {
    actionButton = (
      <div
        key={`play-${point.pointId}`}
        style={{ cursor: "pointer", marginRight: "1rem" }}
        onClick={(e) => {
          dispatch(setCurrentlyPlayingPoint(point.pointId));
        }}
      >
        <span className="fas fa-play"></span>
      </div>
    );
  }

  let pointserve = point.serveTypeId === 0 ? "1st" : point.serveTypeId === 1 ? "2nd" : "Double Fault";
  let pointserveColor = point.serveTypeId === 0 ? "badge-soft-info" : point.serveTypeId === 1 ? "badge-soft-primary" : "badge-soft-danger";
  let court = point.serviceCourt === 0 ? "Ad" : "Deuce";
  let courtColor = point.serviceCourt === 0 ? "badge-soft-primary" : "badge-soft-success";

  return (
    <div
      ref={pointRef}
      className={
        "list-group-item " +
        (currentlyPlayingPoint?.pointId === point.pointId
          ? "bg-200 text-white"
          : "")
      }
      onMouseEnter={() => setShowBtns(true)}
      onMouseLeave={() => setShowBtns(false)}
    >
      <div className="row justify-content-between">
        <div style={{ width: "90%" }} className="d-flex flex-column align-items-start">
          <div style={{ width: "100%" }} className={"row justify-content-between ml-0"}>
            <label className={`${classes["match-list-item-label-text"]}`} >
              <span
                className="match-list-item-label-text"
                onClick={(e) => {
                  dispatch(setCurrentlyPlayingPoint(point.pointId));
                }}
              >
                {secondsToTime(point.startTime) +
                  " - " +
                  point.matchScore +
                  " - " +
                  point.setScore +
                  " - " +
                  point.gameScore}
              </span>
            </label>
            {actionButton}
          </div>
          {(point.isManuallyAdded &&
            <div className="row mt-2 ml-0">
              <span className="badge badge-soft-danger rounded-capsule match-capsule-indicator ml-1">
                Is Manually Added
              </span>
            </div>
          )}
          <div className="row mt-2 ml-0">
            <span className={"badge rounded-capsule match-capsule-indicator ml-1 " + courtColor}>{court}</span>
            <span className={"badge rounded-capsule match-capsule-indicator ml-1 " + pointserveColor} >{pointserve}</span>
            {(point.isAce || point.isPressure || point.isBreak) && (
              <div>
                {point.isAce ? (
                  <span className="badge badge-soft-warning rounded-capsule match-capsule-indicator ml-1">
                    Ace
                  </span>
                ) : null}
                {point.isBreak ? (
                  <span className="badge badge-soft-warning rounded-capsule match-capsule-indicator ml-1">
                    Break
                  </span>
                ) : null}
                {point.isPressure ? (
                  <span className="badge badge-soft-warning rounded-capsule match-capsule-indicator ml-1">
                    Pressure
                  </span>
                ) : null}
              </div>
            )}
            <span className="badge badge-soft-light rounded-capsule match-capsule-indicator ml-1">Duration: {point.endTime - point.startTime} sec</span>
          </div>
          <div className={`row mt-2 ml-0 align-items-center ${pointSelectionClasses["point-shots-container"]}`} >
            {point.shots.map((shot) => (
              <ShotItem key={shot.shotId} shot={shot} />
            ))}
          </div>
        </div>
        {editMode && showBtns && (
          <div style={{ width: "10%" }} className="d-flex flex-column justify-content-between align-items-center m-0 p-0">
            <div
              className={classes["add-point"]}
              onClick={() => {
                dispatch(addPoint(currentIndex));
              }}
            >
              <i className="fas fa-plus-circle"></i>
            </div>
            <DeleteButton
              extraClasses={`btn-sm ${classes["delete-point"]}`}
              onClick={() => dispatch(removePoint(point.pointId))} />

            <div
              className={classes["add-point"]}
              onClick={() => {
                dispatch(addPoint(currentIndex + 1));
              }}
            >
              <i className="fas fa-plus-circle"></i>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PointItem;
