import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { secondsToTime } from "../utils/app-utils.js";

const TimeInput = ({ initialTime, field, onTimeChanged }) => {
  const [time, setTime] = useState(secondsToTime(initialTime));
  const [timeSec, setTimeSec] = useState(Number(initialTime));

  useEffect(() => {
    setTime(secondsToTime(initialTime));
    setTimeSec(Number(initialTime));
  }, [initialTime]);

  const handleChangeTime = (newTime) => {
    setTime(newTime);

    const [hh, mm, ss] = newTime.split(":").map(Number);
    let updatedSeconds = hh * 3600 + mm * 60 + ss;
    if (!isNaN(updatedSeconds)) {
      setTimeSec(updatedSeconds);

      onTimeChanged(field, updatedSeconds);
    } else {
      setTimeSec(0);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp") handleChangeTime(secondsToTime(Number(timeSec) + 1));
    if (e.key === "ArrowDown") Number(timeSec) > 0 && handleChangeTime(secondsToTime(Number(timeSec) - 1));
  };

  return (
    <InputMask
      className="form-control"
      mask="99:99:99"
      maskChar="_"
      value={time}
      onChange={(e) => handleChangeTime(e.target.value)}
      onKeyDown={handleKeyDown}
      placeholder="hh:mm:ss"
    />
  );
};

export default React.memo(TimeInput);
