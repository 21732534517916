
import { useState } from "react";
import PathConstants from "../../routes/pathConstants";
import { formatShortDetailedUtcDate } from "../../utils/app-utils";
import parseMatchStatistics from "../editor/Summary/matchStatisticsHelper";
import MatchSummaryModal from "../editor/Summary/MatchSummaryModal";
import ViewButton from "../../UI/Buttons/ViewButton";
import EditButton from "../../UI/Buttons/EditButton";
import EllipseDropdownButton from "../../UI/Buttons/EllipseDropdownButton";
import { useDeleteCvSnapshotMutation } from "../api/snapshotsSlice"
import useShowToastr from "../../custom-hooks/useShowToastr";
import DeleteButton from "../../UI/Buttons/DeleteButton";
import { useMoveCvToVideoAppMutation } from "../api/collectedVideosSlice";
import { IconVideo } from "@tabler/icons-react";
import Button from "../../UI/Buttons/Button";
import { useSelector } from "react-redux";
import { selectIsAdmin } from "../auth/authSlice";
import { useCreateSnapshotMutation } from "../api/cvSlice";

const SnapshotsDetails = ({ videoProcessId, snapshots, setSelectedVideoSnapshots }) => {
    const [selectedSnapshot, setSelectedSnapshot] = useState(null);
    const isAdmin = useSelector(selectIsAdmin);

    const { serviceStats, returnStats, pointStats } = parseMatchStatistics(selectedSnapshot?.statistics);

    const [moveToVideoApp, { isSuccess: videoMovedSuccessfully, isError: ErrorMovingVideo }] = useMoveCvToVideoAppMutation();
    const [deleteSnapshot, { isSuccess: deletedSuccessfully, isError: failedToDelete }] = useDeleteCvSnapshotMutation();
    const [createSnapshot, { isSuccess: snapshotCreatedSuccessfully, isError: errorCreatingSnapshot }] = useCreateSnapshotMutation();

    useShowToastr({ isSuccess: snapshotCreatedSuccessfully, isError: errorCreatingSnapshot, title: "Create Snapshot", successMsg: "Snapshot created successfully", errorMsg: "Snapshot creation failed" });
    useShowToastr({ isSuccess: deletedSuccessfully, isError: failedToDelete, title: "Delete Snapshot", successMsg: "Snapshot deleted successfully", errorMsg: "Failed to delete snapshot" })
    useShowToastr({ isSuccess: videoMovedSuccessfully, isError: ErrorMovingVideo, title: "Move To Video App", successMsg: "Video moved to video app successfully", errorMsg: "Failed to move video to video app" })

    const openEditorHandler = (snapshot) => {
        const editorUrl = `${process.env.REACT_APP_PUBLIC_URL}/${PathConstants.EDITOR}?videoProcessId=${videoProcessId}&snapshot=${snapshot}`;
        window.open(editorUrl, '_blank', 'noopener,noreferrer');
    }

    const summaryClickedHandler = (snapshot) => {
        $("#summaryModal").modal("show")
        setSelectedSnapshot(snapshot);
    }

    const deleteSnapshotHandler = async (snapshot) => {
        var response = await deleteSnapshot({ videoProcessId: videoProcessId, snapshot: snapshot.createdAt });
        if (response.data) {
            setSelectedVideoSnapshots(snapshots.filter(snap => snap.createdAt !== snapshot.createdAt));
        }
    }

    const moveToVideoAppHandler = (snapshot) => {
        moveToVideoApp({ videoId: videoProcessId, snapshot: snapshot.createdAt });
    }

    return (
        <div className="mt-2">
            {<MatchSummaryModal serviceStats={serviceStats} returnStats={returnStats} pointStats={pointStats} player1={selectedSnapshot?.player1} player2={selectedSnapshot?.player2} />}
            <div className="row ml-0 align-items-center ">
                <h4>Snapshots</h4>
                {snapshots.length === 0 && <button className="btn btn-sm btn-warning ml-2" onClick={() => createSnapshot(videoProcessId)}>Create Snapshot</button>}
            </div>
            <table className="table table-striped text-center ">
                <thead>
                    <tr>
                        <th scope="col">Created At</th>
                        <th scope="col">Created By</th>
                        <th scope="col">Points Count</th>
                        <th scope="col">Needs Review</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {snapshots.map(snapshot => {
                        return (
                            <tr key={snapshot.createdAt}>
                                <td>{formatShortDetailedUtcDate(snapshot.createdAt)}</td>
                                <td>{snapshot.createdBy}</td>
                                <td>{snapshot.pointsCount}</td>
                                <td>{snapshot.needsReviewCount}</td>
                                <td className="d-flex justify-content-center">
                                    <EllipseDropdownButton extraStyles={{ width: "auto", height: "auto" }}>
                                        <EditButton onClick={() => openEditorHandler(snapshot.createdAt)} extraClasses="btn-sm mb-1" extraStyles={{ width: "fit-content", height: "fit-content" }}>
                                            <span className="ml-2">Open Editor</span>
                                        </EditButton>
                                        <ViewButton onClick={() => summaryClickedHandler(snapshot)} extraClasses="btn-sm mb-1" extraStyles={{ width: "fit-content", height: "fit-content" }}>
                                            <span className="ml-2">Summary</span>
                                        </ViewButton >
                                        {isAdmin && <>  <DeleteButton onClick={() => deleteSnapshotHandler(snapshot)} extraClasses="btn-sm mb-1" extraStyles={{ width: "fit-content", height: "fit-content" }}>
                                            <span className="ml-2">Delete</span>
                                        </DeleteButton>
                                            <Button onClick={() => moveToVideoAppHandler(snapshot)} extraClasses="btn-sm mb-1" extraStyles={{ width: "fit-content", height: "fit-content" }}>
                                                <IconVideo stroke={2} />
                                                <span className="ml-2">Move To Video App</span>

                                            </Button>
                                        </>}
                                    </EllipseDropdownButton>
                                </td>
                            </tr>
                        )
                    }
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default SnapshotsDetails;

