import Button from "./Button";

const DownloadButton = ({ children, onClick, extraClasses = '', extraStyles = {}, isDisabled = false }) => {
    return (
        <Button onClick={onClick} extraClasses={extraClasses} extraStyles={extraStyles} isDisabled={isDisabled}>
            <i className="fas fa-download"></i>
            {children}
        </Button>
    );
}

export default DownloadButton;