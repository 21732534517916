import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import {
  selectEditMode,
  selectGlobalEditScore,
  setEditMode,
  setMatch,
  toggleShowPointsNavigation,
  toggleGlobalEditScore
} from "../store/editorSlice";
import {
  useGetMatchSnapshotsQuery,
  usePostCvMatchSnapshotMutation,
} from "../../api/snapshotsSlice";
import { getMatchProgress } from "../Utils/pointUtils";

import { formatDetailedUtcDate, secondsToTimeSpan, convertTimeStringToSeconds } from "../../../utils/app-utils";
import PathConstants from "../../../routes/pathConstants";
import MatchPointList from "./MatchPointList";
import MatchSummaryModal from "../Summary/MatchSummaryModal";
import ViewButton from "../../../UI/Buttons/ViewButton";
import { useGetMatchStatisticsMutation } from "../../api/apiSlice";
import parseMatchStatistics from "../Summary/matchStatisticsHelper";

const PointsList = ({ match, videoProcessId }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const editMode = useSelector(selectEditMode);
  const isGlobalUpdateScore = useSelector(selectGlobalEditScore);

  const { data: snapshots } = useGetMatchSnapshotsQuery(videoProcessId);

  const points = match.points;
  const progress = getMatchProgress(points, match);

  const [postCvMatchSnapshot, { isSuccess, isError, isLoading }] = usePostCvMatchSnapshotMutation();

  const [getMatchStatistics, { data: statistics, isLoading: loadingStats }] = useGetMatchStatisticsMutation();
  const { serviceStats, returnStats, pointStats } = parseMatchStatistics(statistics);

  const summaryClickHandler = () => {
    $("#summaryModal").modal("show");
    let payload = { ...match };
    payload.points = match.points.map((p) => {
      return {
        ...p,
        startTime: secondsToTimeSpan(p.startTime),
        endTime: secondsToTimeSpan(p.endTime)
      };
    });
    getMatchStatistics(payload);
  }

  useEffect(() => {
    toastr.options.positionClass = "toast-bottom-right";
    if (isSuccess)
      toastr.success("Commit Successfully!", "Create Snapshot");
    else if (isError)
      toastr.error("Failed to commit!", "Create Snapshot");
  }, [isSuccess, isError]);

  const commitHandler = () => {
    const newMatch = { ...match };
    newMatch.points = newMatch.points.map((p) => {
      return {
        ...p,
        startTime: secondsToTimeSpan(p.startTime),
        endTime: secondsToTimeSpan(p.endTime),
      };
    });
    postCvMatchSnapshot({ videoProcessId: videoProcessId, match: newMatch })
      .unwrap()
      .then((res) => {
        const newMatch = { ...res };
        newMatch.points = newMatch.points.map((p) => {
          return {
            ...p,
            startTime: convertTimeStringToSeconds(p.startTime),
            endTime: convertTimeStringToSeconds(p.endTime),
          };
        }
        );
        setSearchParams({ videoProcessId, snapshot: res.createdAt });
        dispatch(setMatch(newMatch));
      });
  }

  const editModeChangedHandler = (checked) => {
    const dirtyPointsExist = points.some((p) => p.isUpdated);
    if (!checked && dirtyPointsExist) {
      $("#exitEditorModal").modal("show");
    } else {
      dispatch(setEditMode(checked));
    }
  }

  return (
    <div className="d-flex flex-column mr-2">
      {editMode &&
        <div className="progress w-100 bg-secondary font-weight-black">
          <div style={{ width: `${progress}%`, backgroundColor: progress == 100 ? "#005D00" : "#47B947" }} className="progress-bar" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      }
      <div style={{ height: "80vh" }} className="card mt-1 mb-2">
        <div className="card-header">
          <div className="d-flex flex-row justify-content-between">
            <div className="">
              <h5>Points ({points?.length || 0})</h5>
            </div>
            {editMode && <button className="btn navbar-toggler-humburger-icon navbar-vertical-toggle ml-auto" type="button"
              onClick={() => dispatch(toggleShowPointsNavigation())}>
              <span className="navbar-toggle-icon">
                <span className="toggle-line">
                </span>
              </span>
            </button>}
          </div>
          <div className="d-flex justify-content-between">
            <ViewButton onClick={summaryClickHandler} extraClasses="btn-sm" extraStyles={{ width: "fit-content", height: "fit-content" }} isDisabled={false}>
              <span className="ml-2">Summary</span>
            </ViewButton >
            <MatchSummaryModal serviceStats={serviceStats} returnStats={returnStats} pointStats={pointStats} isLoading={loadingStats} player1={match.player1} player2={match.player2} />
            <div className="d-flex flex-column col-auto ">
              <div className="custom-control custom-switch ">
                <input
                  className="custom-control-input"
                  id="edit-mode"
                  type="checkbox"
                  checked={editMode}
                  onChange={(e) => editModeChangedHandler(e.target.checked)}
                ></input>
                <label className="custom-control-label" htmlFor="edit-mode">
                  Edit Mode
                </label>
              </div>
              {editMode && <div className="custom-control custom-switch ">
                <input
                  className="custom-control-input"
                  id="global-score-mode"
                  type="checkbox"
                  checked={isGlobalUpdateScore}
                  onChange={(e) => dispatch(toggleGlobalEditScore())}
                />
                <label className="custom-control-label" htmlFor="global-score-mode">
                  Global Score Update
                </label>
              </div>
              }
            </div>
          </div>
          <div className="row ml-0">
            {editMode && (
              <button
                disabled={isLoading}
                className="btn btn-sm btn-primary mb-2 mt-4"
                type="button"
                onClick={commitHandler}
              >
                {isLoading ? "Loading" : "Commit"} <span className="ml-1 fas fa-database"></span>
              </button>
            )}
            {editMode && snapshots && snapshots.length > 0 && (
              <div className="btn-group">
                <button
                  className="btn btn-sm dropdown-toggle mb-2 ml-2 btn-light mt-4 justify-content-center"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Snapshots
                </button>
                <div className="dropdown-menu">
                  {snapshots.map((snapshot) => (
                    <Link
                      key={`${match.blobDir}\\${snapshot}.json`}
                      className="dropdown-item"
                      type="button"
                      to={`/${PathConstants.EDITOR}?videoProcessId=${videoProcessId}&snapshot=${snapshot}`}
                    >
                      {formatDetailedUtcDate(snapshot)}
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
          {editMode && <div className="d-flex flex-column mb-1 mt-1">
            <span className="font-weight-bold fs--1">
              Created By:{" "}
              <span className="font-weight-normal fs--1"> {match.createdBy}</span>
            </span>
            <span className="font-weight-bold fs--1">
              Created At:{" "}
              <span className="font-weight-normal fs--1"> {formatDetailedUtcDate(match.createdAt)}</span>
            </span>
          </div>}
        </div>
        <div className="card-body p-0 scrollbar perfect-scrollbar">
          <MatchPointList />
        </div>
      </div>
    </div>
  );
}

export default PointsList;