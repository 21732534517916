import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMatch, selectSelectedMatch } from "../store/editHESlice";
import classes from "../styles.module.css";

export default function MatchRowItem({ match }) {
  const dispatch = useDispatch();
  const activeMatch = useSelector(selectSelectedMatch);

  return (
    <div
      key={match.matchId}
      className={`list-group-item list-group-item-action flex-column align-items-start 
      ${activeMatch?.matchId === match.matchId ? " bg-200 text-white" : ""}`}
    >
      <label className={classes["match-list-item-label-text"]} onClick={() => dispatch(setSelectedMatch(match))}>
        {match.firstPlayerName} vs {match.secondPlayerName} - {match.eventName} - {match.roundName}
      </label>
    </div >
  );
}
