
import { useSelector, useDispatch } from "react-redux";
import { editActivePoint, selectActivePoint } from "../store/editorSlice";
import { shotTypeOptions, strokeTypeOptions, shotLocationTypeOptions, verticalPlayerPositionOptions, horizontalPlayerPositionOptions, shotMovementTypeOptions } from "../Utils/options";
import ButtonGroup from "../../../components/ButtonGroup";
import * as constants from "../Utils/constants";

const EditLastShots = ({ shot }) => {
    const dispatch = useDispatch();

    const activePoint = useSelector(selectActivePoint);

    const shotOptionChangedHandler = (field, value) => {
        const updatedShot = { ...shot, [field]: value };
        const updatedPoint = { ...activePoint };
        updatedPoint.shots = updatedPoint.shots.map((s) => s.shotOrderId == updatedShot.shotOrderId ? updatedShot : s);
        dispatch(editActivePoint(updatedPoint));
    };

    const title = shot.shotOrderId == constants.RETURN_SHOT_ORDER_ID ? "Return" : shot.shotOrderId == constants.SERVE_PLUS_ONE_SHOT_ORDER_ID ? "Serve+1" : shot.shotOrderId == constants.SECOND_TO_LAST_SHOT_ORDER_ID ? "2nd To Last" : "Last";

    return (
        <>
            <span>{title}</span>
            <div className="mb-1">
                <ButtonGroup field="shotTypeId" value={shot.shotTypeId} options={shotTypeOptions} onOptionChanged={shotOptionChangedHandler} />
            </div>
            <div className="mb-1">
                <ButtonGroup field="strokeTypeId" value={shot.strokeTypeId} options={strokeTypeOptions.slice(0, 4)} onOptionChanged={shotOptionChangedHandler} color="secondary" />
                <ButtonGroup field="strokeTypeId" value={shot.strokeTypeId} options={strokeTypeOptions.slice(4, 7)} onOptionChanged={shotOptionChangedHandler} color="secondary" />
            </div>
            <div className="mb-1">
                <ButtonGroup field="shotLocationId" value={shot.shotLocationId} options={shotLocationTypeOptions} onOptionChanged={shotOptionChangedHandler} />
            </div>
            <div className="mb-1">
                <ButtonGroup field="verticalPlayerPositionId" value={shot.verticalPlayerPositionId} options={verticalPlayerPositionOptions} onOptionChanged={shotOptionChangedHandler} />
            </div>
            <div className="mb-1">
                <ButtonGroup field="horizontalPlayerPositionId" value={shot.horizontalPlayerPositionId} options={horizontalPlayerPositionOptions} onOptionChanged={shotOptionChangedHandler} />
            </div>
            <div className="mb-1">
                <ButtonGroup field="shotMovementTypeId" value={shot.shotMovementTypeId} options={shotMovementTypeOptions} onOptionChanged={shotOptionChangedHandler} />
            </div>
        </>
    )
}

export default EditLastShots;