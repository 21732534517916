import CopyToClipboard from "react-copy-to-clipboard";
import Button from "./Button";

const CopyButton = ({ children, text, extraClasses = '', extraStyles = {}, isDisabled = false }) => {
    return (
        <CopyToClipboard text={text}>
            <Button extraClasses={extraClasses} extraStyles={extraStyles} isDisabled={isDisabled}>
                <i className="fas fa-copy"></i>
                {children}
            </Button>
        </CopyToClipboard>
    );
}

export default CopyButton;