import { formatDetailedUtcDate } from "../../utils/app-utils";

const CVLogsModal = ({ video }) => {
    return (
        <div
            className="modal fade"
            id="logsModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="logsModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog" style={{ width: "fit-content", maxWidth: "60%", height: "80vh" }} role="document">
                <div className="modal-content" style={{ height: "100%" }}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="logsModalLabel">
                            {video?.title} CV Logs
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ overflowY: "auto" }}>
                        <div className="row">
                            <table className="table table-striped" style={{ tableLayout: "fixed", width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: "25%" }}>Status</th>
                                        <th scope="col" style={{ width: "50%" }}>Message</th>
                                        <th scope="col" style={{ width: "25%" }}>Created On</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {video?.statusLogs.map((status) => (
                                        <tr key={status.receivedAt}>
                                            <td>{status.status}</td>
                                            <td style={{ maxWidth: "100%" }}>{status.message?.split(':')[0]}</td>
                                            <td>{formatDetailedUtcDate(status.receivedAt)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CVLogsModal;
