import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const accountSlice = createApi({
  reducerPath: "accountapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/account`,
    mode: "cors",
    prepareHeaders: (headers) => {
      const token = getAuthToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllActiveAccountPlayersInDraws: builder.query({
      query: () => `/active/in/draws`,
    }),
    getActiveAccounts: builder.query({
      query: () => `/active`,
    }),
    getAccountSettings: builder.query({
      query: ({accountId}) => `/${accountId}/settings`,
    }),
    getClientAccount: builder.query({
      query: (clientId) => `/client/${clientId}`,
    }),
    getClientSettings: builder.query({
      query: (accountId) => `/${accountId}/settings`,
    }),
    updateAccountSettings: builder.mutation({
      query: ({ accountId, settings }) => ({
        url: `/${accountId}/settings`,
        method: "PUT",
        contentType: "application/json",
        body: settings,
      }),
    }),
  }),
});

export const {
  useGetAllActiveAccountPlayersInDrawsQuery,
  useGetActiveAccountsQuery,
  useGetAccountSettingsQuery,
  useLazyGetClientAccountQuery,
  useLazyGetClientSettingsQuery,
  useUpdateAccountSettingsMutation,
} = accountSlice;
