import Spinner from "../../UI/Spinner";

const VideosList = ({ videos, onSelectedVideoChange, isLoading, selectedVideoId, count }) => {
    return (
        <div style={{ height: "70vh" }} className={`card mt-1 fs--1 px-0`} >
            <div className="card-header p-1 mb-2">
                <h3 className="m-2 text-center">{`Videos (${count})`}</h3>
            </div>
            <div className="card-body px-0">
                {isLoading && <Spinner />}
                {!isLoading && videos.length === 0 && <h5 className="text-center mt-5">No videos found.</h5>}
                {!isLoading && videos.length > 0 &&
                    <div style={{ maxHeight: "100%" }} className="list-group scrollbar perfect-scrollbar">

                        {videos.map(video => {
                            let classes = "d-flex flex-column justify-content-left list-group-item list-group-item-action";
                            if (video.id == selectedVideoId) {
                                classes += " bg-500 text-white";
                            }
                            let statusColor = "badge-warning";
                            switch (video.status) {
                                case "To Do":
                                    statusColor = "badge-danger";
                                    break;
                                case "In Progress":
                                    statusColor = "badge-primary";
                                    break;
                                case "In Review":
                                    statusColor = "badge-warning";
                                    break;
                                case "Done":
                                    statusColor = "badge-success";
                                    break;
                            }
                            return (
                                <a
                                    style={{ cursor: "pointer" }}
                                    key={video.id}
                                    className={classes}
                                    onClick={() => onSelectedVideoChange(video.id)}
                                >
                                    <strong>{video.title}</strong>
                                    <div className='d-flex row ml-auto'>
                                        <span className="badge badge-pill badge-dark mr-2">{!video.assignee || video.assignee == "" ? "Not Assigned" : video.assignee}</span>
                                        <span className={`badge badge-pill ${statusColor} mr-2`}>{video.status}</span>
                                        <span className={`badge badge-pill badge-secondary`}>{video.typeOfCoding}</span>
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                }
            </div>
        </div>
    )

}
export default VideosList;