
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectUser, selectIsAdmin, selectIsRecordingTeam } from "../auth/authSlice";
import { useGetNeedsReviewPlayersQuery, useGetPlayerLookupByIdQuery } from "../api/lookupsSlice";
import PlayersList from "./PlayersList";
import PlayerDetails from "./PlayerDetails";
import Spinner from "../../UI/Spinner";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";

const PlayerLookupPage = () => {
    const [selectedPlayerLookupId, setSelectedPlayerLookupId] = useState(null);
    const { data: playersList, isLoading: loadingPlayers } = useGetNeedsReviewPlayersQuery();
    const { data: playerDetails, isLoading: loadingDetails, isFetching: fetchingDetails } = useGetPlayerLookupByIdQuery(selectedPlayerLookupId, { skip: !selectedPlayerLookupId });

    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);
    const isRecordingTeam = useSelector(selectIsRecordingTeam);
    if (!user) return <Spinner />;
    else if (!isAdmin && !isRecordingTeam) return <NotAuthorizedPage />;

    return (
        <div className="row">
            {loadingPlayers && <Spinner />}
            {playersList && <PlayersList players={playersList} selectedPlayerLookupId={selectedPlayerLookupId} setSelectedPlayerLookupId={setSelectedPlayerLookupId} />}
            <div className="col-6">
                {(loadingDetails || fetchingDetails) && <Spinner />}
                {!loadingDetails && !fetchingDetails && selectedPlayerLookupId && playerDetails && <PlayerDetails playerDetails={playerDetails} setSelectedPlayerLookupId={setSelectedPlayerLookupId} />}
            </div>
        </div>
    );
}

export default PlayerLookupPage;