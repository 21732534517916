
import Spinner from "../../../UI/Spinner";
import SummaryRowItem from "./SummaryRowItem"

const MatchSummaryModal = ({ player1, player2, serviceStats, returnStats, pointStats, isLoading }) => {
    const renderStats = (stats, title) => (
        <>
            <tr>
                <th colSpan="3">{title}</th>
            </tr>
            {stats.map((stat, index) => (
                <SummaryRowItem
                    key={`${title}-${index}`}
                    p1Val1={stat.p1Val1}
                    p1Val2={stat.p1Val2}
                    p2Val1={stat.p2Val1}
                    p2Val2={stat.p2Val2}
                    title={stat.title}
                />
            ))}
        </>
    );

    return (
        <div
            className="modal fade"
            id="summaryModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="summaryModalLabel"
            aria-hidden="true"
        >
            <div style={{ minWidth: "40%" }} className="modal-dialog" role="document">
                <div className="modal-content ">
                    <div className="modal-header">
                        <h5 className="modal-title" id="summaryModalLabel">
                            Summary
                        </h5>
                    </div>
                    <div style={{ maxHeight: "70vh" }} className="modal-body scrollbar perfect-scrollbar">
                        {isLoading && <Spinner />}
                        {!isLoading && serviceStats &&
                            <table className="table text-center">
                                <thead>
                                    <tr>
                                        <th>{player1}</th>
                                        <th></th>
                                        <th>{player2}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderStats(serviceStats, "Service Stats")}
                                    {renderStats(returnStats, "Return Stats")}
                                    {renderStats(pointStats, "Point Stats")}
                                </tbody>
                            </table>
                        }
                    </div>
                    <div className="modal-footer">
                        <button

                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default MatchSummaryModal;