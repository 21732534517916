import classes from "./buttons.module.css";
import Button from "./Button";

const DeleteButton = ({ children, onClick, extraClasses = '', extraStyles = {}, isDisabled = false }) => {
    return (
        <Button onClick={onClick} extraClasses={`${classes["delete-button"]} ${isDisabled ? '' : classes["delete-button-enabled"]} ${extraClasses}`} extraStyles={extraStyles} isDisabled={isDisabled}>
            <i className="fas fa-trash-alt"></i>
            {children}
        </Button>
    );
}

export default DeleteButton;
