import { useSelector } from "react-redux";
import { selectCurrentPlayerTime } from "../store/editorSlice";
import { secondsToTimeSpan } from "../../../utils/app-utils";

const VideoPlayerTimeButton = ({ field, onClick }) => {
    let time = useSelector(selectCurrentPlayerTime);
    time = field === "startTime" ? Math.floor(time) : Math.ceil(time);

    return (
        <button
            className="btn btn-sn btn-primary text-nowrap"
            onClick={() => onClick(field, time)}
        >
            {secondsToTimeSpan(time)}
        </button>
    );
}

export default VideoPlayerTimeButton;