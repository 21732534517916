import React from "react";

const ClientDetailsCard = ({ selectedClient }) => {

  return (
    <div
      className="collapse show text-nowrap"
      id="collapse-client-details"
      aria-labelledby="collapse-client-details"
    >
      <div className="card" style={{ width: "125vh", marginBottom: "20px" }}>
        <div className="card-body">
          <div className="row">
            <div className="col-10">
              <h4 className="card-title ">
                {selectedClient.clientName ? selectedClient.clientName : "-"}
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col-2">Category : {selectedClient.category}</div>
          </div>
          <div className="row">
            <div className="col-6">Age : {selectedClient.age}</div>
          </div>
          <div className="row">
            <div className="col-6">
              Dominant Hand : {selectedClient.dominantHand}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDetailsCard;
