import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const reportExecutionSlice = createApi({
  reducerPath: "reportexecutionapi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    mode: "cors",
    prepareHeaders: (headers) => {
      const token = getAuthToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["reportExecutionRecord", "optionsList"],
  endpoints: (builder) => ({

    createStandardReport: builder.mutation({
      query: ({
        reportInputsModel,
        reportExecutionId,
        reportId,
      }) => ({
        url: `/reportExecutionRecord/std?reportExecutionId=${reportExecutionId}${reportId ? `&reportId=${reportId}` : ""}`,
        method: "POST",
        body: reportInputsModel,
      }),
      invalidatesTags: (result, error, args) => ["reportExecutionRecord"]
    }),

    createHEReport: builder.mutation({
      query: ({
        reportInputsModel,
        reportExecutionId,
        reportId
      }) => ({
        url: `/reportExecutionRecord/he?reportExecutionId=${reportExecutionId}${reportId ? `&reportId=${reportId}` : ""}`,
        method: "POST",
        body: reportInputsModel,
      }),
      invalidatesTags: (result, error, args) => ["reportExecutionRecord"]
    }),

    createAnalystCardReport: builder.mutation({
      query: ({
        reportInputsModel,
        reportExecutionId,
        reportId
      }) => ({
        url: `/reportExecutionRecord/analyst?reportExecutionId=${reportExecutionId}${reportId ? `&reportId=${reportId}` : ""}`,
        method: "POST",
        body: reportInputsModel,
      }),
      invalidatesTags: (result, error, args) => ["reportExecutionRecord"]
    }),

    deleteReport: builder.mutation({
      query: (payload) => ({
        url: "/reportExecutionRecord/report",
        method: "DELETE",
        body: payload
      }),
      invalidatesTags: (result, error, args) => ["reportExecutionRecord"],
    }),

    restartReport: builder.mutation({
      query: (payload) => ({
        url: "/reportExecutionRecord/restart",
        method: "POST",
        body: payload
      }),
      invalidatesTags: (result, error, args) => ["reportExecutionRecord"],
    }),
    getEmailText: builder.mutation({
      query: (payload) => ({
        url: "/reportExecutionRecord/email",
        method: "POST",
        body: payload,
        responseHandler: (response) => response.text()
      })
    })
  })
});

export const {
  useCreateStandardReportMutation,
  useCreateHEReportMutation,
  useCreateAnalystCardReportMutation,
  useDeleteReportMutation,
  useRestartReportMutation,
  useGetEmailTextMutation
} = reportExecutionSlice;
