import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { secondsToTime } from "../../../utils/app-utils.js";
import { useDispatch } from "react-redux";
import {
  editActivePoint,
} from "../store/editHESlice.js";

const TimeInput = ({ initialTime, field, shot }) => {
  const [time, setTime] = useState(secondsToTime(initialTime));
  const [timeSec, setTimeSec] = useState(Number(initialTime));

  const dispatch = useDispatch();

  const handleChangeTime = (newTime) => {
    setTime(newTime);

    const [hh, mm, ss] = newTime.split(":").map(Number);
    let updatedSeconds = hh * 3600 + mm * 60 + ss;

    if (!isNaN(updatedSeconds)) {
      setTimeSec(updatedSeconds)
      const updatedShot = { ...shot, [field]: updatedSeconds };
      dispatch(
        editActivePoint({ shot: updatedShot, field: field })
      );
    }
    else {
      setTimeSec(0);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp")
      handleChangeTime(secondsToTime(Number(timeSec) + 1));
    if (e.key === "ArrowDown")
      Number(timeSec) > 0 &&
        handleChangeTime(secondsToTime(Number(timeSec) - 1));
  };

  useEffect(() => {
    setTime(secondsToTime(initialTime));
    setTimeSec(Number(initialTime));
  }, [initialTime]);

  return (
    <InputMask
      style={{ maxWidth: "80%" }}
      className="form-control text-center"
      mask="99:99:99"
      maskChar="_"
      id={field}
      value={time}
      onChange={(e) => handleChangeTime(e.target.value)}
      onKeyDown={handleKeyDown}
      placeholder="hh:mm:ss"
    />
  );
};

export default TimeInput;
