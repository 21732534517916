import AnalystCardReportRowItem from "./AnalystCardReportRowItem";

const AnalystCardReportsList = ({ reportExecutionId, analystCardReports, setSelectedReport }) => {

    return (
        <div
            className="scrollbar perfect-scrollbar"
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                height: "65vh"
            }}
        >
            {analystCardReports.filter(report => report.jobStatus !== "Deleted").map((report) => (
                <AnalystCardReportRowItem key={report.reportId} reportExecutionId={reportExecutionId} report={report} setSelectedReport={setSelectedReport} />
            ))}
        </div>
    );
};

export default AnalystCardReportsList;
