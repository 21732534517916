

const SummaryRowItem = ({ p1Val1, p1Val2, title, p2Val1, p2Val2 }) => {
    return (
        <tr>
            <td>
                <span>{p1Val1}</span>
                {p1Val2 &&
                    <>
                        <br />
                        <span>{p1Val2}</span>
                    </>
                }
            </td>
            <td style={{ verticalAlign: "middle" }}>{title}</td>
            <td>
                <span>{p2Val1}</span>
                {p2Val2 &&
                    <>
                        <br />
                        <span>{p2Val2}</span>
                    </>
                }
            </td>
        </tr>
    );
}

export default SummaryRowItem;