import { useState } from "react";
import { useSelector } from "react-redux";
import { selectUser, selectIsAdmin } from "../auth/authSlice";
import {
    useLazyGetEventInstanceClientsQuery,
    useGetExecutionEventsQuery,
} from "../api/drawsSlice";
import Spinner from "../../UI/Spinner";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import OptionsList from "../../components/OptionsList";
import EventDetails from "../drawsReports/details/EventDetails";
import ClientDetails from "../drawsReports/details/ClientDetails";

const EventsPage = () => {
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [clients, setClients] = useState(null);

    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);

    const { data: eventInstances, isLoading: loadingEvents } = useGetExecutionEventsQuery(false);
    const [getEventClients, { isLoading: loadingClients, isFetching: fetchingClients }] = useLazyGetEventInstanceClientsQuery();


    const eventChangedHandler = async (eventId) => {
        const event = eventInstances.find((event) => event.eventInstanceId === eventId);
        setSelectedEvent(event);
        setSelectedClient(null);
        const response = await getEventClients(eventId)
        setClients(response.data);
    };

    const clientChangedHandler = async (clientId) => {
        const client = clients.find((client) => client.clientId === clientId);
        setSelectedClient(client);
    };

    if (!user) return <Spinner />;
    else if (!isAdmin) return <NotAuthorizedPage />;

    return (
        <div className="d-flex flex-column">
            {loadingEvents && <Spinner />}
            {eventInstances && (
                <div className="row mt-2 flex-nowrap" style={{ overflowX: "hidden", overflowY: "hidden" }}>
                    <OptionsList
                        title="Events"
                        options={eventInstances.map((eventInstance) => ({
                            label: eventInstance.eventName,
                            value: eventInstance.eventInstanceId,
                        }))}
                        selectedOption={selectedEvent?.eventInstanceId}
                        setSelectedOption={eventChangedHandler}
                    />
                    {
                        <OptionsList
                            title="Clients"
                            options={clients?.map((client) => ({
                                label: client.clientName,
                                value: client.clientId,
                                clientRound: client.round,
                                isActive: client.isActive
                            }))}
                            selectedOption={selectedClient?.clientId}
                            setSelectedOption={clientChangedHandler}
                            isLoading={loadingClients || fetchingClients}
                        />
                    }
                    {selectedEvent && !selectedClient && (
                        <div className="d-flex flex-column col-9 mt-1">
                            <EventDetails selectedEvent={selectedEvent} />
                        </div>
                    )}
                    {selectedClient && (
                        <div className="d-flex flex-column col-9 mt-1">
                            <ClientDetails selectedEvent={selectedEvent} selectedClient={selectedClient} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
export default EventsPage;