import { useState } from "react";
import NewHEReport from "./NewHEReport";
import HEReportsList from "./HEReportsList";
import DeleteReportModal from "../DeleteReportModal";

const HEReports = ({ heReports, reportExecutionId, target, isUserReport, clientSettings }) => {
    const [selectedReport, setSelectedReport] = useState(null);
    return (
        <div style={{ width: "100%", overflowX: "hidden" }} className="mb-2">
            <div className="d-flex row align-items-center">
                <button
                    className="btn btn-primary fs--1 ml-4 mb-2"
                    type="button"
                    onClick={() => setSelectedReport(null)}
                    data-toggle="modal"
                    data-target="#AddNewHEReportModal">
                    Create New Report
                    <i className="fas fa-plus-circle ml-2"></i>
                </button>
                <NewHEReport reportExecutionId={reportExecutionId} editingReport={selectedReport} target={target} isUserReport={isUserReport} clientSettings={clientSettings} />
                <DeleteReportModal reportExecutionId={reportExecutionId} reportId={selectedReport?.reportId} reportType="he-report" />
            </div>
            <div
                className="collapse show"
                id="collapse-he-reports"
                aria-labelledby="collapse-he-reports"
            >
                <HEReportsList reportExecutionId={reportExecutionId} heReports={heReports} setSelectedReport={setSelectedReport} />
            </div>
        </div>
    );
};
export default HEReports;
