import useShowToastr from "./useShowToastr";
import { useGetEmailTextMutation } from "../features/api/reportExecutionSlice";

const useGenerateEmail = ({ clientName, opponentName, clientId, opponentId, clientLocalId, opponentLocalId, surfaceTypeId, stdReports }) => {

    const [getEmailText, { isSuccess: emailGeneratedSuccessFully, isError: failedToGenerateEmail, isLoading: isGeneratingEmail }] = useGetEmailTextMutation();
    useShowToastr({ isSuccess: emailGeneratedSuccessFully, isError: failedToGenerateEmail, title: "Email Text Generation", successMsg: "Email Text Generated Successfully", errorMsg: "Error Generating Email Text" })


    const generateEmailText = async () => {
        const succeededStdReports = stdReports.filter(r => r.jobStatus === "Succeeded");
        const payload = {
            clientFullName: clientName,
            opponentFullName: opponentName,
            clientId: clientId,
            opponentId: opponentId,
            clientLocalId: clientLocalId,
            opponentLocalId: opponentLocalId,
            surfaceTypeId: surfaceTypeId,
            dropboxDir: succeededStdReports.length == 0 ? "" : succeededStdReports[0].reportInputs.dropboxOutputDir,
            stdReports: succeededStdReports
        }
        var response = await getEmailText(payload);
        return response.data;
    };

    return { generateEmailText, isGeneratingEmail };
}

export default useGenerateEmail;