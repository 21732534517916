
import { useRef, useState, useEffect } from 'react';

import { getRandomString } from "../utils/app-utils";
import Spinner from '../UI/Spinner';

const OptionsList = ({ title, options, selectedOption, setSelectedOption, isLoading, modalId }) => {
    const [filteredOptions, setFilteredOptions] = useState(options);
    const inputRef = useRef();

    const handleFilter = () => {
        const value = inputRef.current.value;
        const result = options.filter((option) => {
            const label = option.label.toLowerCase();
            if (option.tag) {
                return label.includes(value.toLowerCase()) || option.tag.toLowerCase().includes(value.toLowerCase());
            }
            return label.includes(value.toLowerCase());
        });
        setFilteredOptions(result);
    }

    useEffect(() => {
        setFilteredOptions(options);
        inputRef.current.value = "";
    }, [options]);

    return (
        <div style={{ maxWidth: "16%" }} className={`col-2 fs--1 px-1`} >
            <div className="card mt-1 min-vh-75 max-vh-75">
                <div className="card-header p-1 mb-2">
                    <div className="d-flex flex-column">
                        <div className="text-nowrap row flex-nowrap align-items-center justify-content-center mb-1">
                            <h5 className="m-2">{`${title} (${filteredOptions?.length ?? "0"})`}</h5>
                            {modalId && <button
                                style={{ width: "1rem", height: "1rem", padding: "0.7rem" }}
                                data-toggle="modal"
                                data-target={`#${modalId}`}
                                className="btn btn-outline-primary ml-1 d-flex align-items-center justify-content-center"
                                disabled={isLoading || !options}>
                                <i className="fas fa-plus"></i>
                            </button>}
                        </div>
                        <div style={{ width: "100%" }} className="col-12 mt-1">
                            <input type="text" className="form-control" placeholder="Search" ref={inputRef} onChange={handleFilter} disabled={!options || isLoading} />
                        </div>
                    </div>
                </div>
                {isLoading && <Spinner />}
                {!isLoading && filteredOptions && <div className="card-body p-0 scrollbar perfect-scrollbar">
                    <div className="list-group">
                        {filteredOptions.map(option => {
                            let classes = "d-flex flex-column justify-content-left list-group-item list-group-item-action";
                            if (option.value == selectedOption) {
                                classes += " bg-500 text-white";
                            }
                            return (
                                <a
                                    style={{ cursor: "pointer" }}
                                    key={getRandomString()}
                                    className={classes}
                                    onClick={() => setSelectedOption(option.value)}
                                >
                                    <strong>{option.label}</strong>
                                    <div className='d-flex row ml-auto'>
                                        {option.isPlaying && <span className="badge badge-success badge-pill mr-2">Active</span>}
                                        {option.tag && <span className="badge badge-warning badge-pill ">{option.tag}</span>}
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                </div>}
                {!isLoading && !filteredOptions && <p className="text-center">No {title}</p>}
            </div>
        </div >
    );
}

export default OptionsList;