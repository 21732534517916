import { useState, useRef } from "react";
import { getRandomString } from "../../utils/app-utils";
import { useEffect } from "react";
import Spinner from "../../UI/Spinner";
import classes from "./DrawsReportsPage.module.css";

const roundColors = ["#233142", "#bf9c2f", "#480032"];

const OptionsList = ({ title, options, selectedOption, setSelectedOption, isLoading }) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const inputRef = useRef();

  const handleFilter = () => {
    const value = inputRef.current.value;
    const result = options.filter((option) => {
      const label = option.label.toLowerCase();
      return label.includes(value.toLowerCase());
    });
    setFilteredOptions(result);
  }

  useEffect(() => {
    setFilteredOptions(options);
    inputRef.current.value = "";
  }, [options]);

  const uniqueRounds = filteredOptions?.reduce((acc, option) => {
    if (option.opponentRound && !acc.includes(option.opponentRound)) {
      acc.push(option.opponentRound);
    }
    return acc;
  }, []);

  const roundColorMapping = uniqueRounds?.reduce((map, round, index) => {
    map[round] = roundColors[index % roundColors.length];
    return map;
  }, {});

  return (
    <div style={{ maxWidth: "12%" }} className={`col-1 fs--1 ${classes.columnpadding}`} >
      <div className="card mt-1 min-vh-75 max-vh-75">
        <div className="card-header p-1 mb-2">
          <div className="d-flex flex-column">
            <div className="text-nowrap row flex-nowrap align-items-center justify-content-center mb-1">
              <h6 className="mb-0">{`${title} (${filteredOptions?.length ?? "0"})`}</h6>
            </div>
            <div style={{ width: "100%" }} className="col-12 mt-1">
              <input type="text" className="form-control" placeholder="Search" ref={inputRef} onChange={handleFilter} disabled={!options || isLoading} />
            </div>
          </div>
        </div>
        {isLoading && <Spinner />}
        {!isLoading && filteredOptions && <div className="card-body p-0 scrollbar perfect-scrollbar">
          <div className="list-group">
            {filteredOptions.map(option => {
              let classes = "d-flex flex-column justify-content-left list-group-item list-group-item-action";
              let style = { cursor: "pointer" };

              if (option.value == selectedOption) {
                classes += " bg-700 text-white";
              } else if (option.opponentRound && roundColorMapping[option.opponentRound]) {
                style.backgroundColor = roundColorMapping[option.opponentRound];
                classes += " text-white";
              } else if (title == "Clients" && !option.isActive) {
                style.backgroundColor = "#a02c44"
                classes += " text-white";
              }

              return (
                <a
                  style={style}
                  key={getRandomString()}
                  className={classes}
                  onClick={() => setSelectedOption(option.value)}
                >
                  <strong>{option.label}</strong>
                  {option.status != undefined && <i className={`ml-auto fas fa-circle ml-1 ${option.status ? "text-danger" : "text-success"}`}></i>}
                  {option.opponentRound && <span className="badge badge-warning badge-pill ml-auto">{option.opponentRound}</span>}
                  {option.clientRound && <span className="badge badge-warning badge-pill ml-auto">{option.clientRound}</span>}
                </a>
              );
            })}
          </div>
        </div>}
        {!isLoading && !filteredOptions && <p className="text-center">Waiting for selection</p>}
      </div>
    </div >
  );
}

export default OptionsList;