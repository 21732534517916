import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAdmin, selectUser } from "../auth/authSlice";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import Spinner from "../../UI/Spinner";



export default function VideoJobsPage(){
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);


    if(!user) return <Spinner />;
    else if (!isAdmin) return <NotAuthorizedPage />;

    return (
        <div className="row">
            <div className="col-lg-3 mb-3">
                Video Jobs
            </div>
        </div>
    );
}