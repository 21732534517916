import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectUser, selectIsAdmin, selectIsRecordingTeam } from "../../auth/authSlice";
import { useGetAllONCEventsQuery, useMoveEventToDBMutation, useGetEventStatusMutation } from "../../api/oncSlice";
import { ButtonRenderer, dataFilterParams, dateComparator } from "../../collectedVideos/tablesDefinitions";
import Spinner from "../../../UI/Spinner";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import "ag-grid-community/styles/ag-theme-quartz.css";

const columnDefs = [
    { headerName: "Category", field: 'categoryTxt', flex: 1 },
    { headerName: "Full Name", field: 'oncName', flex: 1 },
    { headerName: "Surface", field: 'surface', flex: 1 },
    {
        headerName: "Start Date",
        field: 'startDate',
        filter: "agDateColumnFilter",
        filterParams: dataFilterParams,
        sortable: true,
        flex: 1,
        comparator: dateComparator

    },
    { headerName: "Action", field: "action", flex: 1, cellRenderer: ButtonRenderer }
];

const ONCEventsPage = () => {
    const [rowData, setRowData] = useState([]);
    const currentPageRowsRef = useRef([]);
    const timerHandleRef = useRef(null);

    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);
    const isRecordingTeam = useSelector(selectIsRecordingTeam);

    const { data } = useGetAllONCEventsQuery();
    const [moveEventToDB] = useMoveEventToDBMutation();
    const [getEventStatusMutation] = useGetEventStatusMutation();

    useEffect(() => {
        if (data) {
            setRowData(data);
        }
    }, [data]);

    const moveEventHandler = async (oncEventId) => {
        toastr.options.positionClass = "toast-bottom-right";
        var response = await moveEventToDB(oncEventId);
        if (response.data) {
            setRowData(prevState => prevState.map(event => {
                if (event.oncEventId === oncEventId) {
                    return { ...event, action: null };
                }
                return event;
            }));

            toastr.success("Moved Successfully!", "Move ONC Event to DB");
        } else {
            toastr.error("Failed to move!", "Move ONC Event to DB");
        }
    };

    const onModelUpdated = async (params) => {
        const startRow = params.api.paginationGetPageSize() * params.api.paginationGetCurrentPage();
        const endRow = startRow + params.api.paginationGetPageSize();
        const newCurrentPageRows = [];

        for (let i = startRow; i < endRow; i++) {
            const rowNode = params.api.getDisplayedRowAtIndex(i);
            if (rowNode) {
                newCurrentPageRows.push({ oncId: rowNode.data.oncId, categoryTxt: rowNode.data.categoryTxt });
            }
        }
        if (JSON.stringify(currentPageRowsRef.current) === JSON.stringify(newCurrentPageRows)) {
            return;
        }
        currentPageRowsRef.current = newCurrentPageRows;

        if (timerHandleRef.current) {
            clearTimeout(timerHandleRef.current);
        }

        timerHandleRef.current = setTimeout(() => updateStatusHandler(newCurrentPageRows), 500);
    };

    const updateStatusHandler = async (newCurrentPageRows) => {
        const response = await getEventStatusMutation(newCurrentPageRows);
        if (response.data) {
            var newRowData = data.map(event => {
                const eventStatus = response.data.find(e => e.oncId === event.oncId);
                if (eventStatus) {
                    return {
                        ...event, action: !eventStatus.isImported ?
                            <ButtonRenderer
                                title="Move to DB"
                                clickHandler={(oncEventId) =>
                                    moveEventHandler(oncEventId)
                                }
                                args={event.oncEventId}
                            /> : null
                    };
                }
                return event;
            });
            setRowData(newRowData);
        }
    };

    if (!user || !data) return <Spinner />;
    else if (!isAdmin && !isRecordingTeam) return <NotAuthorizedPage />;

    return (
        <div>
            <div className="d-flex justify-content-center">
                <div className=" ag-theme-quartz-dark" style={{ height: "70vh", width: "70%", marginTop: 10 }}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowData={rowData}
                        pagination={true}
                        paginationPageSize={20}
                        defaultColDef={{ filter: true }}
                        suppressColumnVirtualisation={true}
                        suppressRowVirtualisation={true}
                        onModelUpdated={onModelUpdated}
                        onPaginationChanged={onModelUpdated}
                    />
                </div>
            </div>
        </div>
    );
};

export default ONCEventsPage;