import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    mode: "cors",
    prepareHeaders: (headers) => {
      const token = getAuthToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['CvMatches', 'HeFlaggedMatches', 'HeAllMatches', 'NewVideoJobs', 'FailedVideoJobs', 'DropboxToken'],
  endpoints: (builder) => ({
    getAllHeMatches: builder.query({
      query: () => "/matches/he2/all",
      transformResponse: (response) => {
        for (let i = 0; i < response.length; i++) {
          response[i].isSelected = false;
          response[i].isActive = false;
        }
        return response;
      },
    }),
    getMatchDetails: builder.query({
      query: ({ matchInstanceId }) =>
        `/matches/points?matchInstanceId=${matchInstanceId}`,
      transformResponse: (response) => {
        response.isSelected = false;
        response.isActive = false;
        for (let i = 0; i < response.points.length; i++) {
          response.points[i].isSelected = false;
          response.points[i].isActive = false;
        }
        return response;
      },
    }),
    getMatchStatistics: builder.mutation({
      query: (payload) => ({
        url: `/matches/statistics`,
        method: 'POST',
        body: payload,
      }),
    }),
    getRankedPlayers: builder.query({
      query: ({ category, limit }) =>
        `/players/ranks?category=${category}&limit=${limit}`,
      transformResponse: (response) => {
        response.isSelected = false;
        response.isActive = false;
        for (let i = 0; i < response.length; i++) {
          response[i].isSelected = false;
          response[i].isActive = false;
        }
        return response;
      },
    }),
    getClients: builder.query({
      query: () => "/players/clients",
    }),

    searchVideosDropbox: builder.mutation({
      query: ({ keywords }) => ({
        url: `/dropbox/videos/search?keywords=${keywords}`,
        method: 'POST'
      }),
    }),
    searchDataDropbox: builder.mutation({
      query: ({ keywords }) => ({
        url: `/dropbox/data/search?keywords=${keywords}`,
        method: 'POST'
      }),
    }),
    moveDropboxVideo: builder.mutation({
      query: ({ path }) => ({
        url: `/dropbox/move?path=${encodeURIComponent(path)}`,
        method: 'POST'
      }),
      invalidatesTags: () => ['NewVideoJobs', 'FailedVideoJobs'],
    }),
    getNewVideoJobs: builder.query({
      query: () => `/videojobs/new`,
      providesTags: result => {
        return ['NewVideoJobs'];
      },
    }),
    getFailedVideoJobs: builder.query({
      query: () => `/videojobs/failed`,
      providesTags: result => {
        return ['FailedVideoJobs'];
      },
    }),
    getVideoJobDetails: builder.query({
      query: ({ jobId }) => `/videojobs?jobId=${jobId}`,
    }),
    getConnectToDropbox: builder.mutation({
      query: () => ({
        url: `/dropbox/connect?redirectUri=/settings`,
        method: 'GET'
      })
    }),
    getIsDropboxConnected: builder.query({
      query: () => `/dropbox/connected`,
      providesTags: ['DropboxToken']
    }),
    getDropboxPath: builder.query({
      query: (id) => `/dropbox/path/${id}`
    }),
    updateDropboxToken: builder.mutation({
      query: ({ token }) => ({
        url: `/dropbox/update/token?token=${token}`,
        method: 'POST',
        invalidatesTags: ['DropboxToken']
      })
    }),
    refreshIndex: builder.mutation({
      query: () => ({
        url: `/matches/refresh-index`,
        method: 'POST'
      })
    }),
    getIndexMetaData: builder.query({
      query: () => `/matches/index-metadata`
    })
  })
})


export const {
  useGetAllHeMatchesQuery,
  useGetMatchDetailsQuery,
  useGetRankedPlayersQuery,
  useGetClientsQuery,
  useGetMatchStatisticsMutation,
  useSearchVideosDropboxMutation,
  useSearchDataDropboxMutation,
  useMoveDropboxVideoMutation,
  useGetNewVideoJobsQuery,
  useGetFailedVideoJobsQuery,
  useGetVideoJobDetailsQuery,
  useGetConnectToDropboxMutation,
  useUpdateDropboxTokenMutation,
  useGetIsDropboxConnectedQuery,
  useLazyGetDropboxPathQuery,
  useRefreshIndexMutation,
  useGetIndexMetaDataQuery,
} = apiSlice
