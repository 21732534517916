import React from "react";
import { useGetPlayerMatchesQuery } from "../../api/drawsSlice";
import MatchCard from "../shared/MatchCard";
import ClientDetailsCard from "./ClientDetailsCard";

const ClientDetails = ({ selectedEvent, selectedClient }) => {

  const { data: playerMatches } = useGetPlayerMatchesQuery(
    {
      drawId: selectedEvent.drawId,
      drawPlayerName: selectedClient.drawClientId,
    },
    { skip: !selectedClient.drawClientId }
  );

  return (
    <div style={{ width: "100%" }} className="mb-2">
      <div className="d-flex row align-items-center">
        <div
          className="d-flex ml-2 mr-2 mb-2 btn text-decoration-none text-left"
          data-toggle="collapse"
          data-target="#collapse-client-details"
          aria-expanded="true"
          aria-controls="collapse-client-details"
        >
          <span
            className="fas fa-caret-right accordion-icon mr-3"
            data-fa-transform="shrink-2"
          ></span>
          <span className="font-weight-medium text-sans-serif text-900 mr-2">
            Client Details
          </span>
        </div>
      </div>
      <div style={{ margin: "20px" }}>
        <div style={{ height: "70vh" }} className="d-flex flex-column">
          <ClientDetailsCard selectedClient={selectedClient} />
          <div style={{ flexGrow: 1, overflowX: "hidden" }} className="scrollbar perfect-scrollbar">
            <div className="row">
              {playerMatches &&
                playerMatches.map((playerMatch) => {
                  return (
                    <MatchCard
                      key={playerMatch.id}
                      matchData={playerMatch}
                      parent="client"
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDetails;
