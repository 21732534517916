import React from "react";
import MatchRowItem from "./MatchRowItem";
import Spinner from "../../../UI/Spinner";
export default function MatchesList({ matches }) {
  return (
    <div className="card mt-1 min-vh-75 max-vh-75">
      <div className="card-header">
        <div className="row d-flex">
          <div className="col-auto">
            <h5>Matches ({matches?.length || 0})</h5>
          </div>
        </div>
      </div>
      <div className="card-body p-0 scrollbar perfect-scrollbar">
        <div className="list-group">
          {!matches && <Spinner />}
          {matches &&
            matches.map((match) => (
              <MatchRowItem key={match.matchId} match={match} />
            ))}
        </div>
      </div>
    </div>
  );
}
