import { CopyToClipboard } from 'react-copy-to-clipboard';

const ButtonRenderer = (props) => {
  if (!props.value) return <></>;
  return (
    <button
      className="btn btn-primary mr-1 mb-1"
      type="button"
      key={props.value.props.args}
      onClick={() => props.value.props.clickHandler(props.value.props.args)}
    >
      {props.value.props.title}
    </button>
  );
};


const CopyButtonRenderer = (props) => {
  return (
    <CopyToClipboard text={props.value.props.args}>
      <button className="btn btn-primary mr-1 mb-1 fs--1" type="button">{props.value.props.title}</button>
    </CopyToClipboard>
  );
};

const defaultColDef = {
  filter: true,
};

const parseDateString = (dateString) => {
  const match = dateString.match(/(\w+) (\d+), (\d+) at (\d+):(\d+) (\w+)/);
  if (match) {
    const [, month, day, year, hour, minute, ampm] = match;
    const hour24 = ampm === 'PM' ? parseInt(hour, 10) + 12 : parseInt(hour, 10);
    return new Date(`${month} ${day}, ${year} ${hour24}:${minute}:00`);
  }

  return new Date(dateString);
};

const dateComparator = (date1, date2) => {
  var date1Number = date1 && parseDateString(date1).getTime();
  var date2Number = date2 && parseDateString(date2).getTime();

  if (date1Number == null && date2Number == null) {
    return 0;
  }

  if (date1Number == null) {
    return -1;
  } else if (date2Number == null) {
    return 1;
  }

  return date1Number - date2Number;
}

const dataFilterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    const cellDate = new Date(cellValue);
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
};

const scraperDefaultTableColumns = [
  { headerName: "Player1", field: "p1" },
  { headerName: "Player2", field: "p2" },
  { headerName: "Tournament", field: "tournament" },
  { headerName: "Round", field: "round" },
  {
    headerName: "Date",
    field: "date",
    minWidth: 130,
    filter: "agDateColumnFilter",
    filterParams: dataFilterParams,
    sortable: true,
    comparator: dateComparator
  },
  { headerName: "Status", field: "status" },
];

const downloadTableColumns = [
  ...scraperDefaultTableColumns,
  {
    headerName: "Action",
    field: "action",
    cellRenderer: ButtonRenderer,
  },
];

const collectedVideosDefaultTableColumns = [
  { headerName: "Id", field: "id" },
  { headerName: "Player1", field: "p1" },
  { headerName: "Player2", field: "p2" },
  { headerName: "Tournament", field: "tournament" },
  { headerName: "Round", field: "round" },
  {
    headerName: "Date",
    field: "date",
    minWidth: 130,
    filter: "agDateColumnFilter",
    filterParams: dataFilterParams,
    sortable: true,
    comparator: dateComparator
  },
  {
    headerName: "Download Link",
    field: "downloadLink",
    cellRenderer: CopyButtonRenderer,
  },
  {
    headerName: "Browser Link",
    field: "browserURL",
    cellRenderer: CopyButtonRenderer,
  },
  {
    headerName: "Blob Path URL",
    field: "url",
    cellRenderer: CopyButtonRenderer,
  },
  { headerName: "System Status", field: "systemStatus" },
  { headerName: "System Comment", field: "systemComment" },
  { headerName: "CV Status", field: "cvStatus" },
  { headerName: "CV Comment", field: "cvComment" },
  { headerName: "Processing Time", field: "totalCVProcessingDuration" }
];

const sendTableColumns = [
  ...collectedVideosDefaultTableColumns,
  {
    headerName: "Action",
    field: "action1",
    cellRenderer: ButtonRenderer,
  },
  {
    headerName: "Action",
    field: "action2",
    cellRenderer: ButtonRenderer,
  },
];

const resetTableColumns = [
  ...collectedVideosDefaultTableColumns,
  {
    headerName: "Action",
    field: "action",
    cellRenderer: ButtonRenderer,
  },
];

const completedTableColumns = [
  ...collectedVideosDefaultTableColumns,
  {
    headerName: "Finished At",
    field: "finishedAt",
    minWidth: 130,
    filter: "agDateColumnFilter",
    filterParams: dataFilterParams,
    sortable: true,
    comparator: dateComparator
  },
  {
    headerName: "Action",
    field: "action",
    cellRenderer: ButtonRenderer,
  }
];

export {
  ButtonRenderer,
  CopyButtonRenderer,
  dateComparator,
  defaultColDef,
  scraperDefaultTableColumns,
  downloadTableColumns,
  collectedVideosDefaultTableColumns,
  sendTableColumns,
  resetTableColumns,
  completedTableColumns,
  dataFilterParams,
};
