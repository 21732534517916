import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import ReportsActions from "../ReportsActions";
import ReportEdits from "../ReportEdits";
import ErrorModal from "../ErrorModal";
import { formatShortDetailedUtcDate, customReactDataTableStyles } from '../../../utils/app-utils';

const StandardReportsList = ({ reportExecutionId, stdReports, setSelectedReport }) => {

  const getGSFilesCount = (report) => {
    //select xlsx file only from resources
    let xlsxFiles = report.resources.filter(r => getFileExec(r.path)?.toString() === 'xlsx');
    if (xlsxFiles.length === 0) return 0;
    let fileName = xlsxFiles[0].name;
    const gsMatch = fileName.match(/(\d+)\s*GS/);
    return gsMatch ? parseInt(gsMatch[1], 10) : 0;
  };

  const [selectedFileFormat, setSelectedFileFormat] = useState("pdf");
  const getFileExec = (path) => {
    if (
      path.toLowerCase().includes('xlsx') &&
      path.toLowerCase().includes('compact')
    )
      return 'compact xlsx';
      
    return /[.]/.exec(path) ? /[^.]+$/.exec(path) : undefined;
  };

  const cleanReports = stdReports.filter(report => report.jobStatus !== 'Deleted');

  //select some properties from the reports
  const selectedStdReports = cleanReports.map(report => {
    return {
      reportId: report.reportId,
      reportName: report.resources?.length > 0 ? report.resources.find(r => getFileExec(r.path) == 'pdf').name.split('.')[0] : '',
      gsCount: getGSFilesCount(report),
      jobStatus: report.jobStatus,
      createdBy: report.createdBy,
      createdAt: formatShortDetailedUtcDate(report.createdAt),
      resources: report.resources,
      reportInputs: report.reportInputs,
      errorMessage: report.errorMessage
    }
  });

  const columns = [
    {
      name: 'Report Name',
      selector: report => report.reportName,
      sortable: true,
      width: '35%',
    },
    {
      name: 'GS',
      selector: report => report.gsCount,
      sortable: true
    },
    {
      name: 'Status',
      selector: report => report.jobStatus,
      sortable: true
    },
    {
      name: 'By',
      selector: report => report.createdBy,
      sortable: true
    },
    {
      name: 'At',
      selector: report => report.createdAt,
      sortable: true
    },
    {
      name: 'Dropbox',
      width: '10%',
      selector: report => getDropboxLinkCell(report)
    },
    {
      name: 'Files',
      selector: report => getFilesActions(report),
      width: '18%', // Increased width
      cell: row => <div style={{ overflow: 'visible' }}>{getFilesActions(row)}</div> // Allow overflow
    },
    {
      name: 'Actions',
      selector: report => getActions(report),
      cell: row => <div style={{ overflow: 'visible' }}>{getActions(row)}</div> // Allow overflow
    }
  ];

  const getDropboxLinkCell = (report) => {
    // return link to dropbox if available and return empty cell if not
    if(report.jobStatus === 'Failed') { return ''; }
    if (report.reportInputs.dropboxOutputDir !== null && report.reportInputs.dropboxOutputDir !== undefined && report.reportInputs.dropboxOutputDir !== '') {
      return (
        <a className="btn btn-primary mr-1" href={`https://www.dropbox.com/work${report.reportInputs.dropboxOutputDir}`} target="_blank" rel="noreferrer">
          <i className="fas fa-link"></i>
        </a>
      );
    } else {
      return '';
    }
  };

  const getFilesActions = (report) => {
    let reportPath = report.resources.find(r => getFileExec(r.path) == selectedFileFormat);
    let path = reportPath !== undefined ? reportPath.path : undefined;
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {path !== undefined &&
          <div className='row' style={{ display: 'flex', alignItems: 'center' }}>
            <select value={selectedFileFormat} className="form-control form-control-sm mx-2" style={{ width: "120px" }} onChange={(e) => setSelectedFileFormat(e.target.value)}>
              <option value="json">JSON</option>
              <option value="pdf">PDF</option>
              <option value="xlsx">XLSX</option>
              <option value="compact xlsx">Compact XLSX</option>
            </select>
            <ReportsActions reportBlobPath={path} />
          </div>}
          {path === undefined && <div>No files available</div>}
          {report.jobStatus == "Failed" && <ErrorModal report={report} />}
      </div>
    );
  };
  const getActions = (report) => {
    return (
      <div>
        <ReportEdits reportExecutionId={reportExecutionId} report={report} setSelectedReport={setSelectedReport} reportType="standard-report" />
      </div>
    );
  };

  return (
    <div>
      <DataTable
        columns={columns}
        data={selectedStdReports}
        responsive
        highlightOnHover
        theme='dark'
        customStyles={customReactDataTableStyles}
        noDataComponent='No reports available'
      />
    </div>
  );
};

export default StandardReportsList;
