import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    videos : [],
    filteredVideos: [],
    selectedVideo: null
};

const videosLinkingSlice = createSlice({
    name: "videosLinking",
    initialState,
    reducers: {
        setVideos(state, action) {
            state.videos = action.payload;
            state.filteredVideos = action.payload;
        },
        setSelectedVideo(state, action) {
            state.selectedVideo = action.payload;
        },
        reset(state, action) {
            state.videos = [];
            state.filteredVideos = [];
            state.selectedVideo = null;
        },
        filterVideosByTitle(state, action) {
            let keywords = action.payload.split(" ");
            //search for each keyword in the title
            let result = state.videos.filter(video => {
                let title = video.title.toLowerCase();
                return keywords.every(keyword => title.includes(keyword.toLowerCase()));
            });
            // let result = state.videos.filter(video => video.title.toLowerCase().includes(action.payload.toLowerCase()));
            state.filteredVideos = result;
        }
    },
});


export const { setVideos, setSelectedVideo, reset, filterVideosByTitle } = videosLinkingSlice.actions;
export const selectVideos = (state) => state.videosLinking.videos;
export const selectSelectedVideo = (state) => state.videosLinking.selectedVideo;
export const selectFilteredVideos = (state) => state.videosLinking.filteredVideos;
export default videosLinkingSlice.reducer;