import { useEffect } from "react";
import { useDeleteReportMutation } from "../../features/api/reportExecutionSlice";

const DeleteReportModal = ({ reportExecutionId, reportId, reportType }) => {
    const [deleteReport, { isLoading, isSuccess, isError }] = useDeleteReportMutation();

    useEffect(() => {
        toastr.options.positionClass = "toast-bottom-right";
        if (isSuccess) {
            toastr.success("Deleted Successfully!", "Delete Report");
            $(`#${reportType}-DeleteModal`).modal('hide');
        }
        else if (isError)
            toastr.error("Failed to create!", "Delete Report");
    }, [isSuccess, isError]);

    const onConfirmDelete = () => {
        deleteReport({ reportExecutionId, reportId, reportType });
    };

    return (
        <div
            className="modal fade"
            id={`${reportType}-DeleteModal`}
            role="dialog"
            aria-labelledby={`${reportType}-DeleteModalLabel`}
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id={`${reportType}-DeleteModalLabel`}>
                            Delete Report
                        </h3>
                    </div>
                    <div className="modal-body">
                        <p className="mb-2">
                            Are you sure you want to delete this report?
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary btn-md"
                            type="button"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-warning btn-md"
                            type="button"
                            disabled={isLoading}
                            onClick={onConfirmDelete}
                        >{isLoading ? "Deleting..." : "Delete"}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteReportModal;
