import { useState, useEffect } from "react";
import Spinner from "../../UI/Spinner";
import useShowToastr from "../../custom-hooks/useShowToastr";
import { useGetAccountSettingsQuery, useUpdateAccountSettingsMutation } from "../api/accountSlice";


const ClientDetails = ({ client }) => {
    const { data: clientUserSettings, isLoading, refetch } = useGetAccountSettingsQuery({ accountId: client.accountId });
    const [updateClientUserSettings, { isSuccess, isError }] = useUpdateAccountSettingsMutation();

    const [settings, setSettings] = useState(null);

    useShowToastr({ isSuccess: isSuccess, isError: isError, title: "Settings", successMsg: "Settings updated successfully", errorMsg: "Failed to update settings" });


    useEffect(() => {
        if (clientUserSettings) {
            setSettings(clientUserSettings);
        }
    }, [clientUserSettings]);

    if (isLoading || settings == null) return <Spinner />;
    return (
        <div>
            <div className="row">
                <h1>{client.name}</h1>
            </div>
            <div className="container">
                <div className="row">
                    <div style={{ width: "30%" }} className="d-flex flex-column mt-3">
                        <label htmlFor="defaultSpeedUnit">Speed Unit</label>
                        <select className="form-control" id="defaultSpeedUnit"
                            value={settings.defaultSpeedUnit}
                            onChange={(e) => {
                                setSettings({ ...settings, defaultSpeedUnit: e.target.value });
                            }}>
                            <option value="MPH">MPH</option>
                            <option value="KMH">KMH</option>
                        </select>
                    </div>
                </div>
            </div>
            <button className="btn btn-primary mt-3" onClick={() =>
                updateClientUserSettings({ accountId: client.accountId, settings: settings })
                    .then(() => refetch())
                    .catch((e) => console.log(e))
            }>Save Settings</button>
        </div>
    );
};


export default ClientDetails;