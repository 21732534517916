import { useState } from "react";
import { useEffect } from "react";

const typeOptions = [
    { value: "Single", label: "Single" },
    { value: "Combined", label: "Combined" },
]

const SourceSelection = ({ model, setModel, sources, setSources, stdReports, heReports }) => {

    const [type, setType] = useState("Single");

    useEffect(() => {
        const newType = model.jsonFilePath2 != "" ? "Combined" : "Single";
        if (newType != type)
            setType(newType);
    }, [model.jsonFilePath2])

    const isServeSelected = model.source == 1;
    const isReturnSelected = model.source == sources.returnSource;
    const isGSSelected = model.source == sources.gsSource;

    const AggJsonOptions = stdReports.filter(report => report.jobStatus == "Succeeded" && report.resources.length > 0).map((report) => (
        <option key={report.reportId} value={report.resources.find(resource => resource.type === "application/json").path}>
            {report.resources.find(resource => resource.type === "application/json").name}
        </option>
    ));

    const HEDirOptions = heReports.filter(report => report.jobStatus == "Succeeded").map((report) => (
        <option key={report.reportId} value={report.resources[0].path}>
            {report.resources[0].name}
        </option>
    ));

    const returnSelectionChangedHandler = (e) => {
        const newSources = { ...sources, returnSource: e.target.checked ? model.source : 2 };
        if (sources.gsSource == 2)
            newSources.gsSource = 1;

        setSources(newSources);
    }

    const gsSelectionChangedHandler = (e) => {
        setSources({ ...sources, gsSource: e.target.checked ? model.source : 3 });
    }

    const primaryJsonChangedHandler = (e) => {
        const newModel = { ...model, jsonFilePath: e.target.value };
        newModel.isValid = modelIsValid(newModel);
        setModel(newModel);
    }

    const secondaryJsonChangedHandler = (e) => {
        const newModel = { ...model, jsonFilePath2: e.target.value };
        newModel.isValid = modelIsValid(newModel);
        setModel(newModel);
    }

    const typeChangedHandler = (e) => {
        setType(e.target.value);
        const newModel = { ...model, jsonFilePath2: "" };
        newModel.isValid = e.target.value === "Single" ? model.jsonFilePath != "" : false;
        setModel(newModel);
    }

    const modelIsValid = (newModel) => {
        return newModel.jsonFilePath != "" && (type == "Single" || newModel.jsonFilePath2 != "")
    }

    return (
        <div>
            <label>Type</label>
            <select
                style={{ width: "30%" }}
                className="form-control mb-3"
                value={type}
                onChange={typeChangedHandler}>
                {typeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <label>Aggregate JSON</label>
            <select
                style={{ maxWidth: "70%" }}
                className="form-control mb-3"
                value={model.jsonFilePath}
                onChange={primaryJsonChangedHandler}>
                <option value="">No Selection</option>

                {AggJsonOptions}
            </select>
            {
                type === "Combined" && <div><label >Reference Aggregate JSON</label>
                    <select
                        style={{ maxWidth: "70%" }}
                        className="form-control mb-3"
                        value={model.jsonFilePath2}
                        onChange={secondaryJsonChangedHandler}>
                        <option value="">No Selection</option>

                        {AggJsonOptions}
                    </select>
                </div>
            }
            <label>HE Directory</label>
            <select
                style={{ maxWidth: "70%" }}
                className="form-control mb-3"
                value={model.heBlobDir}
                onChange={(e) => setModel({ ...model, heBlobDir: e.target.value })}>
                <option value="">No Selection</option>
                {HEDirOptions}
            </select>
            <div className="row">
                <input
                    type="checkbox"
                    disabled
                    checked={isServeSelected}
                />
                <label className="ml-2 mr-2 mb-0">Serve</label>

                <input
                    type="checkbox"
                    disabled={model.source >= 2}
                    checked={isReturnSelected}
                    onChange={returnSelectionChangedHandler} />
                <label className="ml-2 mr-2 mb-0">Return</label>
                <input
                    type="checkbox"
                    disabled={model.source == 3}
                    checked={isGSSelected}
                    onChange={gsSelectionChangedHandler} />
                <label className="ml-2 mr-2 mb-0">GS</label>

            </div>

        </div>
    );
}

export default SourceSelection;