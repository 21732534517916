
import { useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../UI/Spinner";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import { useGetNeedsReviewTournamnetsQuery, useGetTournamnetLookupByIdQuery } from "../api/lookupsSlice";
import { selectIsAdmin, selectUser, selectIsRecordingTeam } from "../auth/authSlice";

import TournamentDetails from "./TournamentDetails";
import TournamentsList from "./TournamentsList";

const TournamentLookupPage = () => {
    const [selectedTournamentLookupId, setSelectedTournamentLookupId] = useState(null);
    const { data: tournamentsList, isLoading: loadingTournaments } = useGetNeedsReviewTournamnetsQuery();
    const { data: tournamentDetails, isLoading: loadingDetails, isFetching: fetchingDetails } = useGetTournamnetLookupByIdQuery(selectedTournamentLookupId, { skip: !selectedTournamentLookupId });

    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);
    const isRecordingTeam = useSelector(selectIsRecordingTeam);

    if (!user) return <Spinner />;
    else if (!isAdmin && !isRecordingTeam) return <NotAuthorizedPage />;

    return (
        <div className="row">
            {loadingTournaments && <Spinner />}
            {tournamentsList && <TournamentsList tournaments={tournamentsList} selectedTournamentLookupId={selectedTournamentLookupId} setSelectedTournamentLookupId={setSelectedTournamentLookupId} />}
            <div className="col-6">
                {(loadingDetails || fetchingDetails) && <Spinner />}
                {!loadingDetails && !fetchingDetails && selectedTournamentLookupId && tournamentDetails && <TournamentDetails tournamentDetails={tournamentDetails} setSelectedTournamentLookupId={setSelectedTournamentLookupId} />}
            </div>
        </div>
    );
}

export default TournamentLookupPage;