
const ReportStatus = ({ status }) => {
    return (
        <div style={{ position: "absolute", right: 25 }}>
            {(status === "Enqueued" || status === "Awaiting" || status === "Scheduled") && (
                <span
                    className="d-inline-block"
                    tabIndex="1"
                    data-toggle="tooltip"
                    title="Queued"
                >
                    <i className="fas fa-clipboard-list text-secondary"></i>
                </span>
            )}
            {status === "Processing" && (
                <div
                    className="spinner-border spinner-border-sm text-secondary"
                    role="status"
                    data-toggle="tooltip"
                    title="In Progress"
                />
            )}
            {status === "Failed" && (
                <span
                    className="d-inline-block"
                    tabIndex="1"
                    data-toggle="tooltip"
                    title="Failed"
                >
                    <i className="fas fa-times-circle text-danger"></i>
                </span>
            )}
            {status === "Succeeded" && (
                <span
                    className="d-inline-block"
                    tabIndex="1"
                    data-toggle="tooltip"
                    title="Succeeded"
                >
                    <i className="fas fa-check-circle text-success"></i>
                </span>
            )}
        </div>
    );

}

export default ReportStatus;