import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const playerGroupsSlice = createApi({
  reducerPath: "playergroupsapi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    mode: "cors",
    prepareHeaders: (headers) => {
      const token = getAuthToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["playerGroups"],
  endpoints: (builder) => ({
    getPlayerGroups: builder.query({
      query: () => `/PlayerGroups`,
      providesTags: ["playerGroups"],
    }),
    getPlayerGroupById: builder.query({
      query: (id) => `/PlayerGroups/${id}`,
      providesTags: (result, error, id) => [{ type: "playerGroups", id }]
    }),
    createPlayerGroup: builder.mutation({
      query: (payload) => ({
        url: `/PlayerGroups`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result, error, args) => ["playerGroups"],
    }),
    addPlayers: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/PlayerGroups/${id}/add`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: (result, error, args) => [{ type: "playerGroups", id: args.id }],
    }),
    deletePlayer: builder.mutation({
      query: ({ playerGroupId }) => ({
        url: `/playerGroups/${playerGroupId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, args) => ["playerGroups"],
    }),
  }),
});

export const {
  useGetPlayerGroupsQuery,
  useCreatePlayerGroupMutation,
  useGetPlayerGroupByIdQuery,
  useAddPlayersMutation,
  useDeletePlayerMutation
} = playerGroupsSlice;
