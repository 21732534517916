import { useState, useEffect } from "react";
import { useGetAllPlayersQuery } from "../api/completeMatchesSlice";
import { useUpdatePlayerLookupMutation } from "../api/lookupsSlice";
import Select from "react-select";

const PlayerDetails = ({ playerDetails, setSelectedPlayerLookupId }) => {
    const { data: players } = useGetAllPlayersQuery();
    const [selectedPlayerId, setSelectedPlayerId] = useState(playerDetails.playerId);

    const [updatePlayerLookup, { isSuccess: updatedSuccessfully, isError: failedToUpdate }] = useUpdatePlayerLookupMutation();

    useEffect(() => {
        setSelectedPlayerId(playerDetails.playerId);
    }, [playerDetails.playerId]);

    useEffect(() => {
        toastr.options.positionClass = "toast-bottom-right";
        if (updatedSuccessfully) {
            toastr.success("Saved Successfully!", "Update Player Lookup");
            setSelectedPlayerLookupId(null);
        } else if (failedToUpdate) {
            toastr.error("Failed to update!", "Update Player Lookup");
        }
    }, [updatedSuccessfully, failedToUpdate]);

    const playerOptions = players?.map(player => ({ value: player.playerId, label: player.name }))

    return (
        <div className="card mb-2 p-2" >
            <h3 className="card-title my-2">{playerDetails.sourceName} </h3>
            <div className="card-body d-flex flex-column">
                <span>Source: {playerDetails.source}</span>
                <span className="mt-2">Score: {playerDetails.score}</span>
                <div className="row flex-nowrap align-items-center ml-0 mt-2">
                    <span className="mr-2 text-nowrap">Suggested Player: </span>
                    <div className="col-8">
                        <Select
                            options={playerOptions}
                            value={playerOptions?.find(player => player.value === selectedPlayerId)}
                            onChange={(selectedOption) => setSelectedPlayerId(selectedOption.value)}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-center  mt-4">
                    <button
                        className="btn btn-warning btn-md col-3"
                        data-toggle="modal"
                        data-target="#updatePlayerLookupConfirmationModal"
                        type="button">
                        Save
                    </button>
                    <div className="modal fade" id="updatePlayerLookupConfirmationModal" role="dialog" aria-labelledby="updatePlayerLookupConfirmationModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="modal-title" id="updatePlayerLookupConfirmationModalLabel">
                                        Update Player Linking
                                    </h3>
                                </div>
                                <div className="modal-body">
                                    <p className="mb-2">
                                        Are you sure you want to link <br />
                                        Source: <strong>{playerDetails.sourceName}</strong> <br />
                                        Target: <strong>{playerOptions?.find(player => player.value === selectedPlayerId).label}</strong> ?
                                    </p>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-secondary btn-md" type="button" data-dismiss="modal">
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-warning btn-md"
                                        type="button"
                                        data-dismiss="modal"
                                        onClick={() => updatePlayerLookup({ playerLookupId: playerDetails.playerLookupId, playerId: selectedPlayerId })}>
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    );
}

export default PlayerDetails;