
import classes from "./buttons.module.css";

const Button = ({ children, onClick, extraClasses = '', extraStyles = {}, isDisabled = false }) => {
    return (
        <button
            style={{ width: "0.8rem", height: "1.7rem", ...extraStyles }}
            className={`btn ${classes["action-button"]} ${isDisabled ? '' : classes["action-button-enabled"]} mr-1 ${extraClasses}`}
            onClick={onClick}
            type="button"
            disabled={isDisabled}
        >
            {children}
        </button>
    );
}

export default Button;