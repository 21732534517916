import { useSelector, useDispatch } from "react-redux";
import { selectActivePoint, editActivePoint, selectMatch } from "../store/editorSlice";
import ButtonGroup from "../../../components/ButtonGroup";
import { shotLocationTypeOptions } from "../Utils/options";
import { shotPlacementTypeOptions, shotSpeedOptions } from "../Utils/options";
import { convertKMHtoMPH, convertMPHtoKMH } from "../../../utils/app-utils";
import * as constants from "../Utils/constants";

const EditReturn = ({ shot }) => {
    const dispatch = useDispatch();
    const activePoint = useSelector(selectActivePoint);
    const match = useSelector(selectMatch);

    let shotPlacementValue = (!shot.out && !shot.net) ? constants.IN_SHOT_PLACEMENT : shot.out ? constants.OUT_SHOT_PLACEMENT : constants.NET_SHOT_PLACEMENT;

    const optionChangedHandler = (field, value) => {
        const updatedShot = { ...shot, [field]: value };
        const updatedPoint = { ...activePoint };
        updatedPoint.shots = updatedPoint.shots.map((s, i) => s.shotOrderId == updatedShot.shotOrderId ? updatedShot : s);
        dispatch(editActivePoint(updatedPoint));
    }

    const shotPlacementChangedHandler = (field, value) => {
        const updatedPoint = { ...activePoint };
        const updatedShot = { ...shot };
        switch (value) {
            case constants.IN_SHOT_PLACEMENT:
                updatedShot.out = false;
                updatedShot.net = false;
                break;
            case constants.OUT_SHOT_PLACEMENT:
                updatedShot.out = true;
                updatedShot.net = false;
                break;
            case constants.NET_SHOT_PLACEMENT:
                updatedShot.out = false;
                updatedShot.net = true;
                break;
            default:
                break;

        }
        updatedPoint.shots = updatedPoint.shots.map((s) => s.shotOrderId == updatedShot.shotOrderId ? updatedShot : s);
        dispatch(editActivePoint(updatedPoint));
    }

    const speedChangedHandler = (field, value) => {
        const updatedShot = { ...shot, speed: match.speedUnit == constants.KMH_SPEED_UNIT ? convertMPHtoKMH(value) : value };
        const updatedPoint = { ...activePoint };
        updatedPoint.shots = updatedPoint.shots.map((s) => s.shotOrderId == updatedShot.shotOrderId ? updatedShot : s);
        dispatch(editActivePoint(updatedPoint));
    }

    return (
        <>
            <span>Return</span>
            <div className="mb-1">
                <ButtonGroup value={shotPlacementValue} options={shotPlacementTypeOptions} onOptionChanged={shotPlacementChangedHandler} />
            </div>
            <div className="mb-1">
                <ButtonGroup field="shotLocationId" value={shot.shotLocationId} options={shotLocationTypeOptions} onOptionChanged={optionChangedHandler} />
            </div>
            <ButtonGroup value={match.speedUnit == constants.KMH_SPEED_UNIT ? Math.round(convertKMHtoMPH(shot.speed)) : shot.speed} options={shotSpeedOptions} onOptionChanged={speedChangedHandler} />
        </>
    );
}

export default EditReturn