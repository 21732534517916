import React from "react";
import { useGetDrawMatchesQuery } from "../../api/drawsSlice";
import MatchCard from "../shared/MatchCard";
import EventDetailsCard from "./EventDetailsCard";
import { useGetAllActiveAccountPlayersInDrawsQuery } from "../../api/accountSlice";

const EventDetails = ({ selectedEvent }) => {
  const { data: drawMatches } = useGetDrawMatchesQuery(selectedEvent.drawId, { skip: !selectedEvent.drawId });
  const { data: accountPlayers } = useGetAllActiveAccountPlayersInDrawsQuery();

  return (
    <div style={{ width: "100%" }} className="mb-2">
      <div className="d-flex row align-items-center">
        <div
          className="d-flex ml-2 mr-2 mb-2 btn text-decoration-none text-left"
          data-toggle="collapse"
          data-target="#collapse-event-details"
          aria-expanded="true"
          aria-controls="collapse-event-details"
        >
          <span
            className="fas fa-caret-right accordion-icon mr-3"
            data-fa-transform="shrink-2"
          ></span>
          <span className="font-weight-medium text-sans-serif text-900 mr-2">
            Event Details
          </span>
        </div>
      </div>

      <div style={{ margin: "20px" }}>
        <div style={{ height: "65vh" }} className="d-flex flex-column">
          <EventDetailsCard selectedEvent={selectedEvent} />
          {drawMatches && accountPlayers && (
            <div style={{ flexGrow: 1, overflowX: "hidden" }} className="scrollbar perfect-scrollbar">
              {Object.keys(drawMatches).map((round) => {
                return (
                  <div key={round}>
                    <h3>Round : {round.toUpperCase()}</h3>
                    <div className="row">
                      {drawMatches[round].map((drawMatch) => {
                        // get client 
                        let client = accountPlayers.find(
                          (player) => player.name === drawMatch.player1 || player.name === drawMatch.player2
                        );
                        let isClient = accountPlayers.some(
                          (player) => (player.name === drawMatch.player1) || (player.name === drawMatch.player2)
                        );
                        let parent = isClient ? "client" : "event";
                        let isClientWinner = undefined;

                        if (client) {
                          isClientWinner = client.name === drawMatch.winner;
                        }

                        let isP1Client = accountPlayers.some(
                          (player) => player.name === drawMatch.player1
                        );
                        let isP2Client = accountPlayers.some(
                          (player) => player.name === drawMatch.player2
                        );

                        let isBothClinets = isP1Client && isP2Client;
                        return (
                          <MatchCard matchData={drawMatch} key={drawMatch.id} parent={parent} isClientWinner={isClientWinner} isBothClients={isBothClinets} />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
