import {
  NavLink,
  useParams,
} from "react-router-dom";
import PathConstants from "../../routes/pathConstants";

import CollectedVideosTable from "./collectedVideosTable";
import { useGetAllCollectedVideosQuery } from "../api/collectedVideosSlice";
import classes from "./collectedVideos.module.css";
import { useSelector } from "react-redux";
import { selectIsAdmin, selectIsCV, selectUser } from "../auth/authSlice";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import Spinner from "../../UI/Spinner";
import { useEffect, useState } from "react";

const CollectedVideosPage = () => {
  const category = useParams().category;
  const cvStatus = useParams().status;

  const isAdmin = useSelector(selectIsAdmin);
  const isCV = useSelector(selectIsCV);
  const user = useSelector(selectUser);

  const [isLoading, setIsLoading] = useState(false);

  const {
    data: matches,
    isLoading: loadingMatches,
    isFetching,
    error,
    isSuccess,
    status
  } = useGetAllCollectedVideosQuery({ category, status: cvStatus });

  useEffect(() => {
    if (isFetching || loadingMatches)
      setIsLoading(true);
  }, [category, cvStatus]);

  useEffect(() => {
    if (isFetching === false && loadingMatches === false) setIsLoading(false);
  }, [isFetching, loadingMatches])

  if (!user) return <Spinner />;
  else if (!isAdmin && !isCV) return <NotAuthorizedPage />;

  let content = null;
  if (isLoading) content = <Spinner />;
  else if (error)
    content = (
      <div>
        {status} {error.status} {JSON.stringify(error.error)}
      </div>
    );
  else if (isSuccess) content = <CollectedVideosTable matches={matches} status={cvStatus} category={category} />;

  const activeLinkClass = `nav-link active ${classes["cv-nav-item"]}`;
  const inactiveLinkClass = `nav-link ${classes["cv-nav-item"]}`;
  return (
    <div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <NavLink
            className={({ isActive }) =>
              isActive ? activeLinkClass : inactiveLinkClass
            }
            to={`/${PathConstants.COLLECTEDVIDEOS}/atp/${cvStatus}`}
          >
            Tennis Tv Men
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive }) =>
              isActive ? activeLinkClass : inactiveLinkClass
            }
            to={`/${PathConstants.COLLECTEDVIDEOS}/wta/${cvStatus}`}
          >
            Tennis Tv Women
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive }) =>
              isActive ? activeLinkClass : inactiveLinkClass
            }
            to={`/${PathConstants.COLLECTEDVIDEOS}/tc/downloadprocessing`}
          >
            Tennis Channel
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive }) =>
              isActive ? activeLinkClass : inactiveLinkClass
            }
            to={`/${PathConstants.COLLECTEDVIDEOS}/espn/downloadprocessing`}
          >
            ESPN
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive }) =>
              isActive ? activeLinkClass : inactiveLinkClass
            }
            to={`/${PathConstants.COLLECTEDVIDEOS}/img/downloadprocessing`}
          >
            IMG
          </NavLink>
        </li>
      </ul>
      <ul className="nav nav-tabs">
        {category != "tc" && category != "espn"  && category != "img" && <li className="nav-item">
          <NavLink
            className={({ isActive }) =>
              isActive ? activeLinkClass : inactiveLinkClass
            }
            to={`/${PathConstants.COLLECTEDVIDEOS}/${category}/download`}
          >
            Download
          </NavLink>
        </li>}
        <li className="nav-item">
          <NavLink
            className={({ isActive }) =>
              isActive ? activeLinkClass : inactiveLinkClass
            }
            to={`/${PathConstants.COLLECTEDVIDEOS}/${category}/downloadprocessing`}
          >
            Download In Progress
          </NavLink>
        </li>
        {category != "tc" && category != "espn"  && category != "img" && <><li className="nav-item">
          <NavLink
            className={({ isActive }) =>
              isActive ? activeLinkClass : inactiveLinkClass
            }
            to={`/${PathConstants.COLLECTEDVIDEOS}/${category}/new`}
          >
            New
          </NavLink>
        </li>
          <li className="nav-item">
            <NavLink
              className={({ isActive }) =>
                isActive ? activeLinkClass : inactiveLinkClass
              }
              to={`/${PathConstants.COLLECTEDVIDEOS}/${category}/cvprocessing`}
            >
              CV In Progress
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={({ isActive }) =>
                isActive ? activeLinkClass : inactiveLinkClass
              }
              to={`/${PathConstants.COLLECTEDVIDEOS}/${category}/completed`}
            >
              Completed
            </NavLink>
          </li>
        </>}
      </ul>
      {content}
    </div>
  );
};

export default CollectedVideosPage;
