const categoryOptions = [
    { label: "ATP", value: 1 },
    { label: "WTA", value: 2 }
];

const opponentTypeOptions = [
    { label: "All", value: 0 },
    { label: "R", value: 1 },
    { label: "L", value: 2 },
    { label: "One Player", value: 3 },
];

const backhandTypeOptions = [
    { label: "Any", value: 0 },
    { label: "One Handed", value: 1 },
    { label: "Two Handed", value: 2 }
];

const useHEDataOptions = [
    { label: "No", value: 0 },
    { label: "As Lowest", value: 1 },
    { label: "As Highest", value: 2 }
];

const gameResultSelectionOptions = [
    { label: "Won", value: 0 },
    { label: "Lost", value: 1 },
    { label: "All", value: 2 }
];

const gameServeSelectionOptions = [
    { label: "Server", value: 0 },
    { label: "Returner", value: 1 },
    { label: "All", value: 2 }
];

const stdInitialInputs =
{
    category: categoryOptions[0].value,
    selectedTargetPlayers: [],
    selectedOpponentPlayers: [],
    selectedTournaments: [],
    opponent: 1,
    fromYear: 2024,
    toYear: 2024,
    targetSpeedUnit: "KMH",
    isHardSelected: true,
    isClaySelected: true,
    isGrassSelected: true,
    isCarpetSelected: true,
    withRets: false,
    backhandType: backhandTypeOptions[0].value,
    useHEData: useHEDataOptions[2].value,
    gameResultSelection: gameResultSelectionOptions[2].value,
    gameServeSelection: gameServeSelectionOptions[2].value,
    includeTieBreakGames: true,
    dropboxOutputDir: null,
};

const typeOptions = [
    { value: "Single", label: "Single" },
    { value: "Hybrid", label: "Hybrid" },
]

const yearsOptions = Array.from({ length: 13 }, (_, i) => i + 2012).map((year) => ({ label: year, value: year }));

const heInitialInputs = {
    category: categoryOptions[0].value,
    selectedTargetPlayers: [],
    selectedOpponents: [],
    selectedTournaments: [],
    targetSpeedUnit: "KMH",
    fromYear: 2024,
    toYear: 2024,
    hybridFromYear: null,
    hybridToYear: null,
    isHardSelected: true,
    isClaySelected: true,
    isGrassSelected: true,
    hybridIsHardSelected: false,
    hybridIsClaySelected: false,
    hybridIsGrassSelected: false,
    isLeftHanded: false,
    dropboxOutputDir: null,
}

const initialSourceModel = {
    jsonFilePath: "",
    jsonFilePath2: "",
    heBlobDir: "",
    source: null,
    isValid: false,
};

const analystInitialInputs = {
    targetName: "",
    clientName: "",
    tournament: "",
    isClientLeftHanded: false,
    fileNameSuffix: "",
    dropboxOutputDir: null,
};

export {
    categoryOptions,
    opponentTypeOptions,
    backhandTypeOptions,
    useHEDataOptions,
    gameResultSelectionOptions,
    gameServeSelectionOptions,
    stdInitialInputs,
    yearsOptions,
    typeOptions,
    heInitialInputs,
    initialSourceModel,
    analystInitialInputs
};
